<div class="mx-2">
  <form [formGroup]="quickPollForm" (submit)="onFormSubmit()">
    <h1 class="title-h1 color-secondary mb-2">Quick poll</h1>
    <div class="tags mb-6">
      <h2 class="title-h2 color-secondary mb-0">Question</h2>
      <div class="separator"></div>
      <app-textarea
        class="mb-5"
        placeholder="Add a brief question of your topic"
        formControlName="questionControl"
        [maxLength]="QUESTION_MAX_LENGTH"
        [error]="getQuestionErrorMessage()"
        [rows]="6"
      ></app-textarea>
      <h2 class="title-h2 color-secondary mb-0">Answers</h2>
      <div class="separator"></div>
      <app-answer-selector formControlName="answersControl" [error]="getAnswersErrorMessage()"></app-answer-selector>
      <app-checkbox formControlName="canAddOwnAnswerControl"> Members can add their own options </app-checkbox>
      <app-checkbox formControlName="isMultipleChoiceControl"> Members can choose multiple answers </app-checkbox>
      <h2 class="title-h2 color-secondary mb-0">Poll closes in</h2>
      <div class="separator"></div>
      <app-radio
        *ngFor="let radioOption of closingInOptions; trackBy: trackByIndex; let i = index"
        name="closingInRadio"
        formControlName="closingInControl"
        [value]="i.toString()"
      >
        {{ getRadioText(radioOption) }}
      </app-radio>
      <!-- TODO: validator, although a default value is provided thus there won't be any validation error -->
      <!-- You must give your poll a time limit. -->
    </div>
    <div class="actions d-flex gap-2">
      <button class="primary large flex-grow-1" type="submit">start poll</button>
      <button class="secondary large" (click)="onDialogClose(false)">cancel</button>
    </div>
  </form>
</div>
