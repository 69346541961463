import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, ElementRef, Self, ViewChild, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements ControlValueAccessor {
  @Input() public name = '';
  @Input() public label = '';
  @Input() public value = '';

  @ViewChild('input') public input: ElementRef | undefined;

  public onChange: ((_: unknown) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public constructor(@Self() @Optional() public ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  public get control(): FormControl | undefined {
    if (this.ngControl) {
      return this.ngControl.control as FormControl;
    }

    return undefined;
  }

  public onRadioSelected(eventTarget: EventTarget | null): void {
    const elementTarget = eventTarget as HTMLInputElement;
    const value = elementTarget.value;

    if (this.onChange) {
      this.onChange(value);
    }
  }

  public writeValue(value: string): void {
    if (this.input) {
      this.input.nativeElement.value = value;
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (_: unknown) => unknown): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }
}
