import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogService } from './dialog.service';
import { DialogCloseDirective } from './directives/dialog-close.directive';
import { DialogScrollEndDirective } from './directives/dialog-scroll-end.directive';

@NgModule({
  declarations: [DialogContainerComponent, DialogOverlayComponent, DialogCloseDirective, DialogScrollEndDirective],
  imports: [CommonModule, OverlayModule, PortalModule],
})
export class DialogModule {
  public static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [DialogService],
    };
  }
}
