<div *ngIf="vm" class="discussion-detail-card d-flex flex-column card-shadow py-3 px-4 background-white100 color-secondary">
  <div class="top" [class.collapsed]="isTopCollapsed">
    <div class="title-row d-flex align-items-center gap-4 justify-content-between w-100">
      <div class="title-wrapper flex-grow-1">
        <h2 class="title-h2">{{ vm.title }}</h2>
      </div>
      <app-info-panel *ngIf="!disableInteraction; else demoTemplate" [vm]="vm" [hideButton]="true"></app-info-panel>
      <!-- TODO: review with v1 info card refactors (member images) -->
      <ng-template #demoTemplate>
        <div *ngIf="vm.memberImages" class="member-bubbles d-flex gap-1">
          <div
            *ngFor="let memberImage of vm.memberImages.slice(0, 3); trackBy: trackByValue"
            class="member-image"
            [style.background-image]="'url(' + memberImage + ')'"
          ></div>
          <div class="counter" *ngIf="vm.memberImages.length > 3">+{{ vm.memberImages.length - 3 }}</div>
        </div>
      </ng-template>
    </div>
    <p class="description text-normal mb-3">{{ vm.description }}</p>
    <div class="tags-and-invite d-flex align-items-center justify-content-between mb-2">
      <div class="tags d-flex row w-100 gap-4 align-items-center">
        <div class="col" *ngIf="!vm.isCollaboration && !disableInteraction && hasDiscussionRules()">
          <button class="tiny secondary" (click)="onDiscussionRules()">
            <svg-icon key="discussion-rules" fontSize="24px"></svg-icon>
            <span>discussion rules</span>
          </button>
        </div>
        <div class="col">
          <span *ngFor="let tag of vm.tags?.slice(0, 3); trackBy: trackById" class="text-normal text-uppercase">#{{ tag.name }}</span>
        </div>
        <div *ngIf="!isDetailsSectionOpen && isCollaborationDetailsVisible()" class="col d-flex justify-content-center">
          <button class="tiny secondary" (click)="toggleDetailsVisibility()">
            check details
            <svg-icon key="arrow-down"></svg-icon>
          </button>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <app-collaboration-status *ngIf="vm.collaborationStatus" [status]="vm.collaborationStatus"></app-collaboration-status>
        </div>
      </div>
      <button *ngIf="!vm.isCollaboration && !disableInteraction" class="primary small" [routerLink]="['members', 'invite']">
        <svg-icon key="add-member" fontSize="24px"></svg-icon>
        <span>invite new members</span>
      </button>
    </div>

    <div *ngIf="isDetailsSectionOpen && isCollaborationDetailsVisible()" class="row d-flex my-2">
      <div class="col d-flex justify-content-center mt-2">
        <app-collaboration-field icon="rocket" name="Started" [value]="vm?.fields?.startAt ?? ''"></app-collaboration-field>
      </div>
      <div class="col d-flex justify-content-center mt-2">
        <app-collaboration-field icon="case" name="Deadline" [value]="vm?.fields?.deadlineAt ?? ''"></app-collaboration-field>
      </div>
      <div class="col d-flex justify-content-center mt-2">
        <app-collaboration-field icon="collaborations" name="Contribution" [value]="getContributionHoursText()"></app-collaboration-field>
      </div>
      <div class="col d-flex justify-content-center mt-2">
        <app-collaboration-field icon="goal" name="Collab goal" [value]="vm?.fields?.goal ?? ''"></app-collaboration-field>
      </div>
    </div>
    <div *ngIf="isDetailsSectionOpen && isCollaborationDetailsVisible()" class="row py-2">
      <div class="col d-flex justify-content-center">
        <button class="tiny secondary" (click)="toggleDetailsVisibility()">
          close details
          <svg-icon key="arrow-up"></svg-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div
    class="messages flex-grow-1 overflow-auto"
    appScrollListener
    (scrollDirectionChange)="onScrollChange($event)"
    [filterBottom]="true"
    #messageList
  >
    <!-- TODO: refactor vm type to MessageThreadVM -->
    <app-message-thread
      [vm]="vm"
      [disableInteraction]="disableInteraction"
      (replySend)="onReplySend($event)"
      (messageSend)="onMessageSend($event)"
      (messageReport)="onMessageReport($event)"
      (addCustomAnswer)="onAddCustomAnswer($event)"
      (answerPoll)="onAnswerPoll($event)"
    ></app-message-thread>
  </div>
</div>
