import { Loadable } from '@shared/interfaces/loadable.interface';

export class LoadableStateReducerHelper {
  public static startLoad<T, E = string>(state: Loadable<T, E>): Loadable<T, E> {
    return { ...state, isLoading: true };
  }
  public static loadSuccess<T, E = string>(data: T): Loadable<T, E> {
    return { data, isLoading: false };
  }
  public static loadError<T, E = string>(state: Loadable<T, E>, error?: E): Loadable<T, E> {
    return { ...state, error, isLoading: false };
  }
}
