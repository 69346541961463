import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { CollaborationButtonAction } from '@shared/enums/collaboration-button-action.enum';
import { InfoPanelVM } from './info-panel.vm';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent {
  @Input() public vm!: InfoPanelVM;
  @Input() public hideButton = false;
  @Output() public buttonClick = new EventEmitter<{ action?: CollaborationButtonAction; collaborationId?: string }>();

  public onButtonClick(): void {
    let action = CollaborationButtonAction.View;

    if (this.isRequestAccessButtonVisible()) {
      action = CollaborationButtonAction.RequestAccess;
    }

    this.buttonClick.next({ action, collaborationId: this.vm.id });
  }

  public isButtonVisible(): boolean {
    return !this.hideButton;
  }

  public isRequestedButtonVisible(): boolean {
    return this.isNotCollaborationMemberYet() && Boolean(this.vm.isAccessRequested);
  }

  public isViewButtonVisible(): boolean {
    return this.vm.isMember;
  }

  public isRequestAccessButtonVisible(): boolean {
    return this.isNotCollaborationMemberYet() && !this.vm.isAccessRequested;
  }

  private isNotCollaborationMemberYet(): boolean {
    return Boolean(this.vm.isCollaboration) && !this.vm.isMember;
  }
}
