import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TrackByFunction } from '@angular/core';
import { CollaborationButtonAction } from '@shared/enums/collaboration-button-action.enum';
import { DiscussionListCardVM } from './discussion-list-card.vm';

@Component({
  selector: 'app-discussion-list-card',
  templateUrl: './discussion-list-card.component.html',
  styleUrls: ['./discussion-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionListCardComponent {
  @Input() public vm!: DiscussionListCardVM;
  @Output() public buttonClick = new EventEmitter<{ action?: CollaborationButtonAction; collaborationId?: string }>();

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public onButtonClick(clickData: { action?: CollaborationButtonAction; collaborationId?: string }): void {
    const { action, collaborationId } = clickData;

    this.buttonClick.next({ action, collaborationId });
  }
}
