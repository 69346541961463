<ng-container *ngrxLet="vm$ as vm">
  <app-loading *ngIf="vm.isLoading" [isLoading]="vm.isLoading"></app-loading>
  <div class="fullscreen-wrapper w-100 h-100 overflow-hidden">
    <div class="d-flex h-100 w-100">
      <div class="nav-col" [class.wide]="isWide">
        <app-nav [isLoggedIn]="vm.data?.isLoggedIn ?? false" (logout)="onLogoutClick()"></app-nav>
      </div>
      <div class="content flex-grow-1 overflow-auto pt-4 ps-4 pe-3" [class.half]="isWide">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>
