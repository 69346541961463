import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, shareReplay, combineLatest } from 'rxjs';
import { selectAuthData, selectAuthError, selectAuthIsLoading } from 'src/app/stores/auth/auth.selectors';
import { Auth } from '@shared/interfaces/auth.interface';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public vm$: Observable<Loadable<Auth>> = combineLatest([
    this.store.select(selectAuthData),
    this.store.select(selectAuthIsLoading),
    this.store.select(selectAuthError),
  ]).pipe(
    map(([data, isLoading, errorMessage]) => ({
      data,
      isLoading,
      errorMessage,
    })),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(private readonly store: Store, private readonly authService: AuthService) {}

  public logout(): void {
    this.authService.logout();
  }
}
