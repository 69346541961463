import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IllustrationType } from '@shared/components/illustration/illustration-type.enum';

@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorScreenComponent {
  public readonly illustrationTypes = IllustrationType;
  constructor(public readonly route: ActivatedRoute) {}
}
