<ng-container [ngSwitch]="type">
  <svg-icon *ngSwitchCase="toastMessageTypes.Success" key="success" fontSize="40px" width="80px"></svg-icon>
  <svg-icon *ngSwitchCase="toastMessageTypes.Warning" key="warning" fontSize="40px" width="80px"></svg-icon>
  <svg-icon *ngSwitchCase="toastMessageTypes.Error" key="alert" fontSize="40px" width="80px"></svg-icon>
  <svg-icon *ngSwitchDefault key="info" fontSize="40px" width="80px"></svg-icon>
</ng-container>
<div class="message flex-grow-1 text-normal">
  <ng-content></ng-content>
  <ng-template cdkPortalOutlet></ng-template>
</div>
<ng-container *ngIf="closeable">
  <div class="divider"></div>
  <button
    type="button"
    class="small color-secondary close-button"
    [class.color-success]="type === toastMessageTypes.Success"
    [class.color-warning]="type === toastMessageTypes.Warning"
    [class.color-error]="type === toastMessageTypes.Error"
    (click)="onCloseClick()"
  >
    <svg-icon key="clear"></svg-icon>
  </button>
</ng-container>
