import { Profession } from 'src/domain/profession';
import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';

const feature = '[Signup Personal Info]';

export const checkAvailability = createAction(
  `${feature} checkAvailability`,
  props<{
    email: string;
    linkedinProfile: string;
  }>()
);

export const checkAvailabilitySuccess = createAction(
  `${feature} checkAvailability success`,
  props<{
    isEmailAvailable: boolean;
    isLinkedinProfileAvailable: boolean;
  }>()
);

export const checkAvailabilityError = createAction(`${feature} checkAvailability error`, props<{ error: ApiError }>());

export const setPersonalInfo = createAction(
  `${feature} set personal info`,
  props<{
    email: string;
    firstName: string;
    lastName: string;
    profession?: Profession;
    linkedinProfile: string;
  }>()
);
