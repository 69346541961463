import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, filter, map, shareReplay, startWith } from 'rxjs';

export type Language = 'en' | 'nl';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  private readonly localStorageKey: 'user_lang' = 'user_lang';
  private readonly defaultLang: Language = 'en';

  public readonly languages: Language[] = ['en', 'nl'];

  private _currentLanguage$: BehaviorSubject<Language> = new BehaviorSubject<Language>(this.defaultLang);

  public currentLanguage$: Observable<Language> = this._currentLanguage$.asObservable().pipe(shareReplay());

  constructor(private readonly translateService: TranslateService) {
    translateService.setDefaultLang(this.defaultLang);

    const storedLanguage = this.getStoredLanguage();
    this.useLanguage(storedLanguage);

    this.translateService.onLangChange
      .pipe(
        map((langChangeEvent) => langChangeEvent.lang as Language),
        startWith(this.translateService.currentLang as Language),
        filter((lang) => this.isLanguage(lang))
      )
      .subscribe((lang) => {
        this._currentLanguage$.next(lang);
      });
  }

  public useLanguage(lang: Language) {
    this.translateService.use(lang);
    this.storeLanguage(lang);
  }

  private storeLanguage(language: Language) {
    try {
      localStorage.setItem(this.localStorageKey, language);
    } catch (e) {
      console.warn(e);
    }
  }

  private getStoredLanguage(): Language {
    try {
      const item = localStorage.getItem(this.localStorageKey);
      return this.isLanguage(item) ? item : this.defaultLang;
    } catch (e) {
      console.warn(e);
      return this.defaultLang;
    }
  }

  private isLanguage(item: any): item is Language {
    return item === 'en' || item === 'nl';
  }
}
