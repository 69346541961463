import { createReducer, on } from '@ngrx/store';
import { NewsFeedItem } from 'src/domain/news-feed-item';
import { getNewsFeed, getNewsFeedError, getNewsFeedSuccess } from './news-feed.actions';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';

export const featureKeyNewsFeed = 'news_feed';

export interface FeatureStateNewsFeed {
  newsFeed: Loadable<NewsFeedItem[], ApiError>;
}

export interface AppState {
  [featureKeyNewsFeed]: FeatureStateNewsFeed;
}

export const initialStateNewsFeed: FeatureStateNewsFeed = {
  newsFeed: { isLoading: false },
};

export const reducerNewsFeed = createReducer(
  initialStateNewsFeed,
  on(
    getNewsFeed,
    (state): FeatureStateNewsFeed => ({
      ...state,
      newsFeed: LoadableStateReducerHelper.startLoad(state.newsFeed),
    })
  ),
  on(
    getNewsFeedSuccess,
    (state, { items }): FeatureStateNewsFeed => ({
      ...state,
      newsFeed: LoadableStateReducerHelper.loadSuccess(items),
    })
  ),
  on(
    getNewsFeedError,
    (state, { error }): FeatureStateNewsFeed => ({
      ...state,
      newsFeed: LoadableStateReducerHelper.loadError({ ...state.newsFeed }, error),
    })
  )
);
