import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
  @Input() public isLoggedIn = false;
  @Output() public logout = new EventEmitter<void>();
  public RouteSegment = RouteSegment;
}
