<header class="d-none d-lg-flex align-items-center justify-content-between py-2 px-4">
  <a class="d-block logo-wrapper color-secondary" [routerLink]="['/']">
    <svg-icon key="logo-horizontal" fontSize="50px"></svg-icon>
  </a>
  <nav class="d-flex gap-4 align-items-center">
    <ng-container *ngFor="let lang of languages; let isLast = last">
      <span class="language-selector" (click)="useLanguage(lang)">{{ lang }}</span>
      <span *ngIf="!isLast">|</span>
    </ng-container>
    <!-- TODO: add here once public materials developed
    <a class="color-secondary" [routerLink]="['/', 'platform', 'discussions']">public materials</a>
    <a class="color-secondary" [routerLink]="['/', 'platform', 'events']">upcoming events</a>
    <a class="color-secondary" [routerLink]="['/', 'platform', 'discussions']">discussions</a>-->
    <button class="secondary small" [routerLink]="['/', 'login']">login</button>
    <!-- <button class="secondary small" disabled>coming soon</button> -->
  </nav>
</header>

<header class="d-flex d-lg-none align-items-center justify-content-between mobile-header">
  <a class="d-block logo-wrapper px-2 color-secondary" [routerLink]="['/']">
    <svg-icon key="logo-horizontal" fontSize="32px"></svg-icon>
  </a>
  <nav class="d-flex gap-2 px-2">
    <ng-container *ngFor="let lang of languages; let isLast = last">
      <span class="language-selector" (click)="useLanguage(lang)">{{ lang }}</span>
      <span *ngIf="!isLast">|</span>
    </ng-container>
  </nav>
</header>
