export const appLiteratureIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 4H4.79766C6.90877 4 8.9961 4.44562 10.9232 5.30773L12 5.78947V21L11.1672 20.5836C9.08435 19.5422 6.78766 19 4.45898 19H2V4Z" stroke="#02065B" stroke-width="3" stroke-linejoin="round"/>
  <path d="M5.5 8.5H6.68861C7.22602 8.5 7.75992 8.58664 8.26975 8.75658L9 9M18.5 8.5H17.3114C16.774 8.5 16.2401 8.58664 15.7302 8.75658L15 9" stroke="#02065B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.5 11.5H6.68861C7.22602 11.5 7.75992 11.5866 8.26975 11.7566L9 12M18.5 11.5H17.3114C16.774 11.5 16.2401 11.5866 15.7302 11.7566L15 12" stroke="#02065B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.5 14.5H6.68861C7.22602 14.5 7.75992 14.5866 8.26975 14.7566L9 15M18.5 14.5H17.3114C16.774 14.5 16.2401 14.5866 15.7302 14.7566L15 15" stroke="#02065B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22 4H19.2023C17.0912 4 15.0039 4.44562 13.0768 5.30773L12 5.78947V21L12.8328 20.5836C14.9156 19.5422 17.2123 19 19.541 19H22V4Z" stroke="#02065B" stroke-width="3" stroke-linejoin="round"/>
  </svg>`,
  name: 'literature',
};
