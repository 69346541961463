<div class="d-flex collaboration-field align-items-center justify-content-center">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <svg-icon [key]="icon" size="xxl"></svg-icon>
    </div>
    <div class="col-12 d-flex justify-content-center mt-2">
      <span class="text-small">{{ name }}</span>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <span class="text-normal-bold">{{ value }}</span>
    </div>
  </div>
</div>
