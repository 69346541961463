<div class="date-wrapper">
  <div
    class="month-year text-normal-bold d-flex justify-content-center align-items-center"
    [class.background-white100]="isUpcoming()"
    [class.background-background25]="!isUpcoming()"
    [class.color-secondary40]="!isUpcoming()"
  >
    {{ date | date: 'LLL `yy' }}
  </div>
  <div
    class="day background-secondary color-white100 py-1 title-h2 d-flex justify-content-center align-items-center"
    [class.background-secondary40]="!isUpcoming()"
  >
    {{ date | ordinalDay }}
  </div>
</div>
