import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from '@core/services/platform.service';
import { filter } from 'rxjs';
@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformComponent {
  public vm$ = this.platformService.vm$;
  public isWide = false;

  constructor(public readonly router: Router, private readonly platformService: PlatformService) {
    // TODO: also check on this
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: (event) => {
        const nav = event as NavigationEnd;
        this.isWide =
          (nav.url.includes('login') || nav.url.includes('reset-password')) && !nav.url.includes('login/forgotten-password/success');
      },
    });
  }

  public onLogoutClick(): void {
    this.platformService.logout();
  }
}
