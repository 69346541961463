import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { DialogService } from '@shared/modules/dialog/dialog.service';
import { ToastMessageType } from '@shared/modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';
import { QuickPollDialogComponent } from 'src/app/discussions/components/quick-poll-dialog/quick-poll-dialog.component';
import { CreateQuickPoll } from 'src/domain/create-quick-poll';
import { DiscussionMessage } from './discussion-message.interface';

@Component({
  selector: 'app-discussion-input',
  templateUrl: './discussion-input.component.html',
  styleUrls: ['./discussion-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionInputComponent {
  @ViewChild('fileUpload') public fileUpload!: ElementRef<HTMLInputElement>;
  @Input() public isLight = false;
  @Output() public messageSend = new EventEmitter<DiscussionMessage>();
  @Input() public isPollVisible = false;
  public readonly messageMaxLength = 280;
  public readonly acceptedFileTypes = '.sav,.dta,.rdata,.xls,.xlsx,.csv,.tab,.doc,.docx,.ppt,.pptx,.jpg,.jpeg,.png,.pdf,.txt';
  public readonly maxFileSize = 15_728_640; /* 15 MB */
  public readonly maxFileCount = 1;
  public messageControl = new FormControl('', [Validators.maxLength(this.messageMaxLength)]);
  public selectedFile!: File | null;
  public createQuickPoll!: CreateQuickPoll | null;

  constructor(
    private readonly toastMessageService: ToastMessageService,
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public get selectedFileName(): string {
    if (!this.selectedFile) {
      return '';
    }

    const nameSplit = this.selectedFile.name.split('.');

    return nameSplit.filter((_, index) => index !== nameSplit.length - 1).join('.');
  }

  public get selectedFileExtension(): string {
    if (!this.selectedFile) {
      return '';
    }

    const nameSplit = this.selectedFile.name.split('.');
    return nameSplit.pop() || '';
  }

  public get createQuickPollQuestion(): string {
    return this.createQuickPoll?.question ?? '';
  }

  public onSendClick(): void {
    if (this.messageControl.invalid || (!this.messageControl.value.trim() && !this.createQuickPoll)) {
      return;
    }

    this.messageSend.next({ message: this.messageControl.value, attachment: this.selectedFile, createQuickPoll: this.createQuickPoll });

    this.selectedFile = null;
    this.createQuickPoll = null;
    this.messageControl.reset('');
  }

  public onQuickPollClick(): void {
    this.dialogService
      .open(QuickPollDialogComponent, undefined, { closeable: false, width: '600px' })
      .afterClosed$()
      .subscribe({
        next: (quickPollDialogCloseResult) => {
          if (!quickPollDialogCloseResult) {
            return;
          }

          this.createQuickPoll = quickPollDialogCloseResult as CreateQuickPoll;
          this.cdr.detectChanges();
        },
      });
  }

  public onAttachmentClick(): void {
    this.fileUpload.nativeElement.value = '';
    this.fileUpload.nativeElement.click();
  }

  public onFileSelected(fileChange: Event): void {
    const input = fileChange.target as HTMLInputElement;
    if (input.files?.length === 0) {
      return;
    }
    const files = input.files as FileList;

    if (files.length > this.maxFileCount) {
      this.toastMessageService.open('Please select one file only!', {
        closeable: true,
        type: ToastMessageType.Warning,
      });
      return;
    }

    const file = files.item(0) as File;
    if (file.size > this.maxFileSize) {
      this.toastMessageService.open('Please select a file no larger than 15MB!', {
        closeable: true,
        type: ToastMessageType.Warning,
      });
      return;
    }

    const extension = file.name.split('.').pop() || '';
    const allowedExtensions = this.acceptedFileTypes.split(',');
    if (!allowedExtensions.includes(`.${extension}`)) {
      this.toastMessageService.open(`Unsupported file type, please select from the following types: ${allowedExtensions.join(', ')}.`, {
        closeable: true,
        type: ToastMessageType.Warning,
        duration: 5000,
      });
      return;
    }

    this.selectedFile = file;
  }

  public onClearQuickPollClick(): void {
    this.createQuickPoll = null;
  }

  public onClearAttachmentClick(): void {
    this.selectedFile = null;
  }
}
