import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDay',
})
export class OrdinalDayPipe implements PipeTransform {
  public transform(value: Date | string): string {
    const date = new Date(value);
    if (!value) {
      return '';
    }

    const day = date.getDate();

    return `${day.toString().length === 1 ? '0' : ''}${day}${this.nth(day)}`;
  }

  private nth(day: number) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (day > 3 && day < 21) return 'th';
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    switch (day % 10) {
      case 1:
        return 'st';
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case 2:
        return 'nd';
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
