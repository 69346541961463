import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import { SignupScreenProgressState } from '../enum/signup-screen-progress-state.enum';
import { SignupAvailabilityResult } from '../interfaces/signup-availability-result.interface';
import { SignupSubmitInfoVM } from '../components/signup-terms-screen/signup-terms-screen.vm';
import { SignupSubmitResult } from '../interfaces/signup-submit-result.interface';
import * as SignupPersonalInfoActions from './signup-personal-info/signup-personal-info.actions';
import * as SignupPasswordActions from './signup-password/signup-password.actions';
import * as SignupTermsActions from './signup-terms/signup-terms.actions';
import * as SignupSubmitActions from './signup-submit/signup-submit.actions';
import * as SignupResendEmailValidationActions from './signup-email-validation/signup-email-validation.actions';

export const featureKeySignup = 'signup';

export interface FeatureStateSignup extends SignupSubmitInfoVM {
  availabilityResult: Loadable<SignupAvailabilityResult, ApiError>;
  screenProgressState: SignupScreenProgressState;
  submitResult: Loadable<SignupSubmitResult, ApiError>;
  resendEmailResult: Loadable<void, ApiError>;
}

export interface AppState {
  [featureKeySignup]: FeatureStateSignup;
}

export const initialSignupState: FeatureStateSignup = {
  availabilityResult: { isLoading: false },
  screenProgressState: SignupScreenProgressState.StepPersonalInfo,
  email: '',
  firstName: '',
  lastName: '',
  profession: undefined,
  linkedinProfile: '',
  password: '',
  hasAcceptedTerms: false,
  hasMarketingConsented: false,
  submitResult: { isLoading: false },
  resendEmailResult: { isLoading: false },
};

export const reducerSignup = createReducer(
  initialSignupState,
  on(
    SignupPersonalInfoActions.checkAvailability,
    (state): FeatureStateSignup => ({
      ...initialSignupState,
      availabilityResult: LoadableStateReducerHelper.startLoad(state.availabilityResult),
    })
  ),
  on(
    SignupPersonalInfoActions.checkAvailabilitySuccess,
    (state, { isEmailAvailable, isLinkedinProfileAvailable }): FeatureStateSignup => ({
      ...state,
      availabilityResult: LoadableStateReducerHelper.loadSuccess({
        ...state.availabilityResult.data,
        isEmailAvailable,
        isLinkedinProfileAvailable,
      }),
    })
  ),
  on(
    SignupPersonalInfoActions.checkAvailabilityError,
    (state, { error }): FeatureStateSignup => ({
      ...state,
      availabilityResult: LoadableStateReducerHelper.loadError({ ...state.availabilityResult }, error),
    })
  ),
  on(
    SignupPersonalInfoActions.setPersonalInfo,
    (state, { email, firstName, lastName, profession, linkedinProfile }): FeatureStateSignup => ({
      ...state,
      email,
      firstName,
      lastName,
      profession,
      linkedinProfile,
      screenProgressState: SignupScreenProgressState.StepPassword,
    })
  ),
  on(
    SignupPasswordActions.setPassword,
    (state, { password }): FeatureStateSignup => ({
      ...state,
      password,
      screenProgressState: SignupScreenProgressState.StepTermsAndConditions,
    })
  ),
  on(
    SignupTermsActions.setAcceptTermsAndConsentMarketing,
    (state, { hasAcceptedTerms, hasMarketingConsented }): FeatureStateSignup => ({
      ...state,
      hasAcceptedTerms,
      hasMarketingConsented,
    })
  ),
  on(
    SignupSubmitActions.signupSubmit,
    (state, { hasAcceptedTerms, hasMarketingConsented }): FeatureStateSignup => ({
      ...state,
      hasAcceptedTerms,
      hasMarketingConsented,
      submitResult: LoadableStateReducerHelper.startLoad({ ...state.submitResult }),
    })
  ),
  on(
    SignupSubmitActions.signupSubmitSuccess,
    (state): FeatureStateSignup => ({
      ...state,
      submitResult: LoadableStateReducerHelper.loadSuccess({ hasSucceeded: true }),
      screenProgressState: SignupScreenProgressState.StepValidateEmail,
    })
  ),
  on(
    SignupSubmitActions.signupSubmitError,
    (state, { error }): FeatureStateSignup => ({
      ...state,
      submitResult: LoadableStateReducerHelper.loadError({ ...state.submitResult }, error),
    })
  ),
  on(
    SignupResendEmailValidationActions.signupResendValidationEmail,
    (state): FeatureStateSignup => ({
      ...state,
      resendEmailResult: LoadableStateReducerHelper.startLoad(state.resendEmailResult),
    })
  ),
  on(
    SignupResendEmailValidationActions.signupResendValidationEmailSuccess,
    (state): FeatureStateSignup => ({
      ...state,
      resendEmailResult: { ...state.resendEmailResult, isLoading: false },
    })
  ),
  on(
    SignupResendEmailValidationActions.signupResendValidationEmailError,
    (state, { error }): FeatureStateSignup => ({
      ...state,
      resendEmailResult: LoadableStateReducerHelper.loadError({ ...state.resendEmailResult }, error),
    })
  )
);
