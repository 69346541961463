<div class="d-flex flex-basis-50">
  <div class="profile-image-container position-relative flex-shrink-0 me-3">
    <div *ngIf="vm.image; else defaultImage" class="profile-image w-100 h-100" [style.background-image]="'url(' + vm.image + ')'"></div>
    <ng-template #defaultImage>
      <svg-icon key="member" fontSize="144px"></svg-icon>
    </ng-template>
    <button
      *ngIf="vm.editable"
      class="primary tiny position-absolute top-100 start-50 translate-middle"
      type="button"
      (click)="uploadImageButtonClick.emit($event)"
    >
      <svg-icon class="upload-image-button-icon custom-font-size" key="add" fontSize="24px"></svg-icon>
    </button>
  </div>
  <div class="flex-grow-1">
    <div>
      <div class="title-h1 mb-1 color-secondary">{{ vm.name }}</div>
      <p class="text-small color-secondary mb-1" *ngIf="vm.profession">{{ vm.profession }}</p>
      <div class="d-flex align-items-center">
        <p class="color-secondary40 m-0">{{ vm.followersCount }} followers</p>
        <button
          *ngIf="!vm.editable"
          type="button"
          class="primary tiny ms-2"
          [class.following]="vm.following"
          (click)="followButtonClick.emit()"
        >
          {{ vm.following ? 'following' : 'follow' }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="vm.bio || vm.editable">
      <div class="separator v1 horizontal-separator"></div>
      <ng-container *ngTemplateOutlet="contacts"></ng-container>
    </ng-container>
  </div>
</div>
<div class="separator vertical vertical-separator v1 flex-shrink-0 mx-6"></div>
<div class="d-flex flex-column flex-grow-1 flex-basis-50" *ngIf="vm.bio || vm.editable; else contactInsteadOfBio">
  <div class="d-flex justify-content-between mb-2">
    <div class="title-h1 color-secondary">Bio</div>
    <button *ngIf="vm.editable && !editBioMode && vm.bio" class="primary tiny" (click)="enterEditBioMode()">edit bio</button>
    <div class="edit-bio-action-buttons d-flex" *ngIf="editBioMode">
      <button class="primary tiny" (click)="saveBioChanges()">save changes</button>
      <button class="white tiny with-border cancel-button" (click)="cancelBioChanges()">cancel</button>
    </div>
  </div>
  <div *ngIf="vm.editable && !vm.bio && !editBioMode">
    <div class="text-normal color-secondary40 mb-2">
      Want to tell other HIP members a little about yourself? This is easily done by adding a short biography to your profile.
    </div>
    <button class="primary small" type="button" (click)="enterEditBioMode()">
      <svg-icon class="upload-image-button-icon custom-font-size me-2" key="add" fontSize="24px"></svg-icon>
      add a bio
    </button>
  </div>
  <p *ngIf="vm.bio && !editBioMode" class="m-0 color-secondary">{{ vm.bio }}</p>
  <form *ngIf="editBioMode" [formGroup]="bioFormGroup" (ngSubmit)="saveBioChanges()">
    <app-textarea formControlName="bio" [maxLength]="BIO_MAX_LENGTH" [rows]="9" [error]="getBioErrorMessage()"></app-textarea>
  </form>
</div>
<ng-template #contactInsteadOfBio>
  <ng-container *ngTemplateOutlet="contacts"></ng-container>
</ng-template>

<ng-template #contacts>
  <div class="d-flex flex-column justify-content-center">
    <div class="d-flex align-items-center mb-3">
      <svg-icon key="email" fontSize="24px" class="me-1"></svg-icon>
      <a target="_blank" [href]="'mailto:' + vm.email">{{ vm.email }}</a>
    </div>
    <div class="d-flex align-items-center">
      <svg-icon key="linkedin" fontSize="24px" class="me-1"></svg-icon>
      <a target="_blank" [href]="vm.linkedinProfile">{{ vm.linkedinProfile }}</a>
    </div>
  </div>
</ng-template>
