import { Component, ChangeDetectionStrategy, Inject, TrackByFunction, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { DIALOG_DATA } from '@shared/modules/dialog/dialog.tokens';
import { DialogData } from '@shared/modules/dialog/interfaces/dialog-data.interface';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Tag } from 'src/domain/tag';

@Component({
  selector: 'app-edit-topic-preferences-dialog',
  templateUrl: './edit-topic-preferences-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTopicPreferencesDialogComponent implements OnInit, OnDestroy {
  public tags!: Tag[];
  public displayedTags!: Tag[];
  public searchControl = new FormControl('', { updateOn: 'change' });
  private readonly destroy$ = new Subject<void>();

  constructor(@Inject(DIALOG_DATA) private readonly dialog: DialogData<Tag[], Tag[]>) {
    this.tags = this.dialog.payload;
    this.displayedTags = this.tags;
  }

  public get selectedTagsCount(): number {
    return this.tags.filter((tag) => tag.isSelected).length;
  }

  public ngOnInit(): void {
    this.searchControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value: string) => {
      this.displayedTags = this.tags.filter((tag) => tag.name.toLowerCase().includes(value?.toLowerCase()));
    });
  }

  // @TODO: create a generic component with destoy subject
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public trackById: TrackByFunction<Tag> = (_, item) => item.id;

  public onSelectAllTagClick(): void {
    this.tags = this.tags.map((tag) => ({ ...tag, isSelected: true }));
    this.displayedTags = this.tags;
  }

  public onClearAllTagClick(): void {
    this.tags = this.tags.map((tag) => ({ ...tag, isSelected: false }));
    this.displayedTags = this.tags;
  }

  public onFilterClose(applySettings: boolean): void {
    if (!applySettings) {
      this.dialog.dialogRef.close();
      return;
    }

    this.dialog.dialogRef.close(this.tags.filter((tag) => tag.isSelected));
  }
}
