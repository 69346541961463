import { Component, ChangeDetectionStrategy, Input, TrackByFunction, Output, EventEmitter } from '@angular/core';
import { ProfessionFilter } from 'src/app/members/interfaces/profession-filter';

@Component({
  selector: 'app-profession-select',
  templateUrl: './profession-select.component.html',
  styleUrls: ['./profession-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionSelectComponent {
  @Input() public disabled = false;
  @Input() public professions!: ProfessionFilter[];
  @Input() public showCount?: number;
  @Output() public selectionChange = new EventEmitter<ProfessionFilter[]>();

  public trackByName: TrackByFunction<ProfessionFilter> = (_, item) => item.name;

  public onProfessionClick(profession: ProfessionFilter): void {
    if (this.disabled) {
      return;
    }

    profession.isSelected = !profession.isSelected;
    this.selectionChange.next(this.professions);
  }
}
