<div
  *ngIf="!vm?.isCollaboration && vm?.quickPollList && (getOpenPollsList(vm?.quickPollList ?? []).length ?? 0 > 0)"
  class="d-flex w-100 justify-content-center"
>
  <div class="view-polls w-50 align-items-center my-2 px-2 py-1 gap-2">
    <div class="row w-100">
      <div class="col-8 d-flex align-items-center gap-1 px-2">
        <svg-icon key="poll-40" fontSize="32px"></svg-icon>
        <div>
          <span>There are&nbsp;</span>
          <span class="new-polls-text">new polls</span>
          <span>&nbsp;in the thread!</span>
        </div>
      </div>
      <div class="col d-flex align-items-center justify-content-end gap-2">
        <div class="separator vertical"></div>
        <div class="link d-flex justify-content-center align-items-center color-primary gap-1" (click)="onViewPolls()">
          <span>view polls</span>
          <svg-icon [key]="isPollListVisible ? 'arrow-down' : 'arrow-right'" fontSize="24px"></svg-icon>
        </div>
      </div>
    </div>
    <div *ngIf="isPollListVisible" class="separator my-1"></div>
    <div *ngIf="isPollListVisible" class="row">
      <div class="col">
        <div
          *ngFor="let quickPoll of getOpenPollsList(vm?.quickPollList ?? []); trackBy: trackById"
          class="quick-poll d-flex row w-100 align-items-center justify-content-center my-1 py-1 gap-2"
          (click)="onQuickPollLink(quickPoll)"
        >
          <div class="col-7 d-flex align-items-center px-2 py-1 gap-2">
            <svg-icon key="poll-40" fontSize="32px"></svg-icon>
            <span>{{ quickPoll.question }}</span>
          </div>
          <div class="col d-flex align-items-center justify-content-end gap-1 color-secondary40">
            <svg-icon key="time" fontSize="24px"></svg-icon>
            <span>{{ getClosingInText(quickPoll) }}</span>
            <div class="separator vertical color-background50"></div>
            <svg-icon key="arrow-right" fontSize="24px" class="color-secondary"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="vm?.replies && (vm?.replies ?? []).length > 0; else noMessagesTemplate">
  <app-message
    *ngFor="let message of vm?.replies; trackBy: trackById"
    [vm]="message"
    [disableInteraction]="disableInteraction"
    [isPollVisible]="vm?.isCollaboration ?? false"
    (replySend)="onReplySend($event)"
    (messageReport)="onMessageReport($event)"
    (addCustomAnswer)="onAddCustomAnswer($event)"
    (answerPoll)="onAnswerPoll($event)"
  ></app-message>
</ng-container>
<ng-template #noMessagesTemplate>
  <div class="illustration-wrapper d-flex flex-column justify-content-center align-items-center">
    <app-illustration [type]="illustrationTypes.Message"></app-illustration>
    <p class="text-normal color-secondary40">There are no comments yet</p>
  </div>
</ng-template>
<div class="d-flex flex-column justify-content-end">
  <div *ngIf="!disableInteraction" class="separator"></div>
  <app-discussion-input
    *ngIf="!disableInteraction"
    class="mt-2"
    (messageSend)="onMessageSend($event)"
    [isPollVisible]="vm?.isCollaboration ?? false"
  >
  </app-discussion-input>
</div>
