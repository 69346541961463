<img [src]="vm.imageDesktop" class="desktop" *ngIf="vm.imageDesktop" alt="{{ vm.title }}" />
<img [src]="vm.imageMobile" class="mobile" *ngIf="vm.imageMobile" alt="{{ vm.title }}" />
<div class="py-3 px-2 px-sm-4">
  <h2>{{ vm.title }}</h2>
  <div class="article-meta" *ngIf="vm.article">
    <span class="article-author" [innerHTML]="'NewsFeed_ArticleAuthor' | translate: { name: vm.article.author }"></span
    ><span class="article-date">{{ vm.article.publishDate | date: 'dd MMMM yyyy' }}</span>
  </div>
  <markdown *ngIf="vm.description" [data]="vm.description"> </markdown>
  <a
    class="read-article"
    *ngIf="vm.article"
    [routerLink]="['/articles', vm.article.slug]"
    rel="noopener noreferrer"
    [appPushTagOnClick]="{
      event: TagEvent.ArticleOpened,
      feed_author: vm.article.author,
      feed_name: vm.title
    }"
  >
    <span class="text-nowrap">{{ 'NewsFeed_ReadFullArticle' | translate }}</span>
    <svg-icon key="arrow-right" fontSize="24px"></svg-icon>
  </a>
</div>
