export const appRocketIcon = {
  data: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1959 10.8823C21.5319 8.67773 25.4917 7.61166 29.4836 7.84332L31.5418 7.96275C31.6547 7.96931 31.7447 8.0594 31.7513 8.1723L34.2471 8.02746L31.7513 8.1723L31.8707 10.2304C32.1024 14.2224 31.0363 18.1821 28.8318 21.5182C27.6367 23.3267 26.1335 24.9114 24.3906 26.2003L19.9035 29.5184C18.5102 30.5487 16.5728 30.4045 15.3476 29.1792L10.5349 24.3665C9.3096 23.1412 9.16533 21.2038 10.1956 19.8106L13.5138 15.3235C14.8027 13.5806 16.3874 12.0774 18.1959 10.8823Z" stroke="#02065B" stroke-width="5" stroke-linecap="round"/>
    <path d="M8.17098 13.8653C8.31053 13.7257 8.51911 13.6813 8.7034 13.7519L13.1477 15.4546L8.29046 20.3118L4.3895 18.6126C4.06635 18.4718 3.98638 18.0499 4.23562 17.8006L8.17098 13.8653Z" stroke="#02065B" stroke-width="5" stroke-linecap="round"/>
    <path d="M25.8486 31.5426C25.9881 31.4031 26.0325 31.1945 25.9619 31.0102L24.2593 26.5659L19.4021 31.4232L21.1013 35.3241C21.242 35.6473 21.664 35.7272 21.9132 35.478L25.8486 31.5426Z" stroke="#02065B" stroke-width="5" stroke-linecap="round"/>
    <ellipse cx="22.6399" cy="17.074" rx="3.21429" ry="3.24325" transform="rotate(45 22.6399 17.074)" fill="#02065B"/>
    <path d="M8.88013 30.834L5.82237 33.8917" stroke="#02065B" stroke-width="2" stroke-linecap="round"/>
    <path d="M11.9104 33.8633L10.3815 35.3922" stroke="#02065B" stroke-width="2" stroke-linecap="round"/>
    <path d="M5.84961 27.8027L4.32073 29.3316" stroke="#02065B" stroke-width="2" stroke-linecap="round"/>
  </svg>`,
  name: 'rocket',
};
