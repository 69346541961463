import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TrackByFunction,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { getClosingInText } from '@shared/helpers/closing-time-text.helper';
import { hasUserAnswered } from '@shared/helpers/has-user-answered.helper';
import { isPollClosed } from '@shared/helpers/is-poll-closed.helper';
import { Subject, takeUntil } from 'rxjs';
import { Answer } from 'src/domain/answer';
import { QuickPoll } from 'src/domain/quick-poll';

const DECIMAL_COUNT = 2;

@Component({
  selector: 'app-quick-poll-message',
  templateUrl: './quick-poll-message.component.html',
  styleUrls: ['./quick-poll-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickPollMessageComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public quickPoll?: QuickPoll;
  @Output() public answerPoll = new EventEmitter<{ quickPollId: string; answerIds: Array<string> }>();
  @Output() public addCustomAnswer = new EventEmitter<{ quickPollId: string; text: string }>();

  public isCustomAnswerVisible = false;
  public baseForm!: FormGroup;
  public newCustomAnswers: Array<Answer> = [];

  private readonly destroy$ = new Subject<void>();
  private lastSentAnswerId = '';
  private selectedAnswerIds: Array<string> = [];

  constructor(public readonly formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.initQuickPoll();
  }

  public ngOnChanges({ quickPoll }: SimpleChanges): void {
    const { firstChange } = quickPoll;

    if (!firstChange) {
      this.initQuickPoll();
    }
  }

  // @TODO: create a generic component with destroy subject
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public getClosingInText(): string {
    if (this.isClosed()) {
      return 'Poll closed';
    }

    return this.quickPoll?.closingAt ? getClosingInText(this.quickPoll?.closingAt, new Date()) : '';
  }

  public onCheckBoxChange(answer: Answer, _hasChecked: boolean): void {
    if (answer.id.startsWith('custom-answer-')) {
      const newArray = this.newCustomAnswers.filter(({ id }) => id !== answer.id);

      if (newArray.length === this.newCustomAnswers.length) {
        this.newCustomAnswers.push(answer);
      } else {
        this.newCustomAnswers = [...newArray];
      }
    } else {
      const newArray = this.selectedAnswerIds.filter((id) => id !== answer.id);

      if (newArray.length === this.selectedAnswerIds.length) {
        this.selectedAnswerIds.push(answer.id);
      } else {
        this.selectedAnswerIds = [...newArray];
      }
    }
  }

  public getAnswerOptions(): Array<Answer> {
    return [...(this.quickPoll?.answers ?? []), ...this.newCustomAnswers];
  }

  public onSubmitCustomAnswer(): void {
    const text = this.baseForm.controls.customAnswer.value;

    if (text === '' || !this.quickPoll?.id) {
      // TODO: add validation text
      // currently the API is simply not triggered when empty text
      return;
    }

    if (this.quickPoll?.isMultipleChoice) {
      const newCustomAnswer: Answer = {
        id: `custom-answer-${this.newCustomAnswers.length}`,
        isCustom: true,
        text,
        voteCount: 0,
      };
      this.newCustomAnswers.push(newCustomAnswer);

      this.addAnswerControl(newCustomAnswer, true);
      this.onToggleCustomAnswer();
    } else {
      this.addCustomAnswer.next({ quickPollId: this.quickPoll?.id, text });
    }
  }

  public onToggleCustomAnswer(): void {
    this.isCustomAnswerVisible = !this.isCustomAnswerVisible;
    this.baseForm.controls.customAnswer.setValue('');
  }

  public onSingleChoiceAnswer(answer: Answer): void {
    // TODO: fix radio selection sent twice

    if (this.lastSentAnswerId !== answer.id) {
      this.onSubmitAnswer([answer.id]);

      this.lastSentAnswerId = answer.id;
    }
  }

  public onSubmitMultiChoiceAnswer(): void {
    const quickPollId: string = this.quickPoll?.id ?? '';

    // TODO: add validation on the UI, currently it's not sending the API call
    if (this.selectedAnswerIds.length === 0 && quickPollId) {
      return;
    }

    this.newCustomAnswers.forEach((answer: Answer) => {
      this.addCustomAnswer.next({ quickPollId, text: answer.text });
    });
    this.newCustomAnswers.length = 0;
    this.onSubmitAnswer(this.selectedAnswerIds);
  }

  public onSubmitAnswer(answerIds: Array<string>): void {
    if (this.quickPoll?.id && !this.isClosed() && !this.hasAnswered()) {
      this.answerPoll.next({ quickPollId: this.quickPoll?.id, answerIds });
    }
  }

  // TODO: move out to helpers, cover with test cases
  public calculatePercentage(voteCount: number): string {
    const allVoteCount = this.quickPoll?.answers.reduce((a, answer) => a + answer.voteCount, 0) ?? 0;
    const percentage = allVoteCount === 0 ? 0 : voteCount / allVoteCount;

    return `${(percentage * 100).toFixed(DECIMAL_COUNT)}%`;
  }

  public hasAnswered(): boolean {
    return (this.quickPoll && hasUserAnswered(this.quickPoll)) ?? false;
  }

  public isClosed(): boolean {
    return (this.quickPoll && isPollClosed(this.quickPoll)) ?? false;
  }

  private initQuickPoll(): void {
    if (this.quickPoll?.isMultipleChoice) {
      this.multiChoiceInit();
    } else {
      this.singleChoiceInit();
    }

    if (this.isClosed() || this.hasAnswered()) {
      this.disableAnswering();
    }
  }

  private singleChoiceInit(): void {
    const selectedAnswer = this.quickPoll?.selectedAnswerIds?.[0];

    this.baseForm = this.formBuilder.group({
      answer: [selectedAnswer],
      customAnswer: [''],
    });
  }

  private multiChoiceInit(): void {
    this.baseForm = this.formBuilder.group(
      this.quickPoll?.canAddOwnAnswer
        ? {
            customAnswer: [''],
          }
        : {}
    );

    this.quickPoll?.answers.forEach((answer: Answer) => {
      this.addAnswerControl(answer);
    });
  }

  private addAnswerControl(answer: Answer, forceSelect?: boolean): void {
    const hasSelected = forceSelect || this.quickPoll?.selectedAnswerIds?.includes(answer.id);
    const formControl = new FormControl(hasSelected);

    formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((hasChecked: boolean) => this.onCheckBoxChange(answer, hasChecked));

    this.baseForm.addControl(answer.id, formControl);
  }

  private disableAnswering(): void {
    this.isCustomAnswerVisible = false;
    this.baseForm.controls.customAnswer?.setValue('');
    this.baseForm.disable();
  }
}
