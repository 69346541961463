<div class="discussion-input d-flex gap-3 align-items-center">
  <div *ngIf="!isPollVisible" class="action-button d-flex flex-column align-items-center justify-content-center">
    <svg-icon role="button" key="poll-40" fontSize="40px" (click)="onQuickPollClick()"></svg-icon>
    <span *ngIf="createQuickPoll" class="selected-object d-flex align-items-center text-small">
      <div class="object-name">{{ createQuickPollQuestion }}</div>
      <svg-icon role="button" key="clear" fontSize="16px" (click)="onClearQuickPollClick()"></svg-icon>
    </span>
  </div>

  <div class="action-button d-flex flex-column align-items-center justify-content-center">
    <input type="file" class="file-input" [accept]="acceptedFileTypes" (change)="onFileSelected($event)" #fileUpload />
    <svg-icon role="button" key="attachment" fontSize="40px" (click)="onAttachmentClick()"></svg-icon>
    <span *ngIf="selectedFile" class="selected-object d-flex align-items-center text-small">
      <div class="object-name">{{ selectedFileName }}</div>
      <div class="object-type">.{{ selectedFileExtension }}</div>
      <svg-icon role="button" key="clear" fontSize="16px" (click)="onClearAttachmentClick()"></svg-icon>
    </span>
  </div>

  <app-textarea
    class="flex-grow-1"
    placeholder="Enter your message..."
    [formControl]="messageControl"
    [isLight]="isLight"
    [maxLength]="messageMaxLength"
    [rows]="2"
  ></app-textarea>

  <button class="primary small" (click)="onSendClick()">send</button>
</div>
