import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ChangeDetectionStrategy, Input, Optional, Self, TrackByFunction, ChangeDetectorRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { DropdownOption, DropdownSubOption } from './dropdown-option.interface';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() public label?: string;
  @Input() public placeholder?: string;
  @Input() public options: DropdownOption[] = [];
  @Input() public hint?: string;
  @Input() public error?: string;

  public formControl = new FormControl();

  public isOpen = false;
  public readonly overlayPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 0,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: 0,
    },
  ];

  public onChange: ((_: DropdownSubOption) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public constructor(@Optional() @Self() private readonly ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get value(): DropdownOption | DropdownSubOption {
    return this.ngControl.control?.value;
  }

  public get disabled(): boolean {
    return this.ngControl.control?.disabled || false;
  }

  public get control(): FormControl | undefined {
    if (this.ngControl) {
      return this.ngControl.control as FormControl;
    }

    return undefined;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  // Basic accessibility support - close modal on ESC keyboard event
  @HostListener('keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    if (this.isOpen && event.key === 'Escape') {
      this.isOpen = false;
      this.cdr.markForCheck();

      if (this.onTouched) {
        this.onTouched();
      }
    }
  }

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public onOverlayOutsideClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLElement;
    const isFilterCloseButtonClicked = clickTarget.closest('.dropdown-button');
    if (isFilterCloseButtonClicked) {
      return;
    }
    event.preventDefault();

    this.isOpen = false;

    if (this.onTouched) {
      this.onTouched();
    }
  }

  public onButtonClick(): void {
    this.isOpen = !this.isOpen;

    if (this.onTouched && !this.isOpen) {
      this.onTouched();
    }
  }

  public onOptionClick(option: DropdownSubOption): void {
    this.isOpen = false;

    if (this.value?.id === option.id) {
      return;
    }

    if (this.onChange) {
      this.onChange(option);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public writeValue(value: string): void {
    this.formControl.setValue(value);
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (_: DropdownSubOption) => void): void {
    this.onChange = function_;
  }
  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }

  public onValueChange(value: DropdownOption): void {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  public onClearIconClick(): void {
    this.formControl.reset();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.formControl.setDisable(isDisabled);
  }
}
