export const appLogoHorizontalIcon = {
    data: `<svg width="236" height="48" viewBox="0 0 236 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_10050_12878)">
    <path d="M61.2511 1.30784C58.0011 0.123426 54.6886 1.80654 53.6261 4.73641C53.3761 5.48446 52.6261 5.98317 51.8136 5.98317C46.4386 6.10784 28.1886 6.48187 19.8136 6.60654C17.5636 6.66888 15.6261 5.42213 14.4386 3.552C13.0011 1.30784 10.4386 -0.188263 7.37607 -0.00124971C4.31357 0.123426 1.50107 2.18057 0.501065 5.11044C-1.24893 9.97277 1.93857 14.8974 6.81356 15.5832C7.56356 15.7078 8.25107 15.6455 8.93857 15.5832C10.4386 15.3961 11.9386 16.1442 12.7511 17.391L13.0636 17.8897C14.0636 19.5104 13.8761 21.4429 12.6261 22.752C10.0636 25.4949 8.50106 29.1728 8.56356 33.3494C8.75106 40.0195 13.5011 45.9416 20.0011 47.5624C28.8761 49.7442 37.4386 43.7598 38.6261 34.9702C38.9386 32.7884 38.7511 30.6689 38.1261 28.6741C37.3761 26.1182 38.1886 23.3754 40.3136 21.8169L52.6886 12.6533C54.4386 11.3442 55.3761 11.4065 56.1886 11.8429C56.8136 12.2169 57.5636 12.4663 58.3136 12.591C62.0011 13.0897 65.3136 10.0974 64.9386 6.17018C64.7511 3.98836 63.3136 2.05589 61.2511 1.30784ZM14.3136 16.3936C13.4386 15.0221 13.5636 13.339 14.4386 12.0299C15.7511 9.97277 17.8761 8.53901 20.3136 8.53901L52.4386 7.91563C52.9386 7.91563 53.4386 8.22732 53.6261 8.72602C53.8136 9.22472 53.6261 9.78576 53.1886 10.0974L39.1886 20.3208C37.0636 21.8793 34.1886 21.9416 32.0011 20.4455C30.1261 19.1987 28.0011 18.326 25.6261 18.0143C23.3761 17.7026 21.2511 17.952 19.1886 18.5754C17.4386 19.1364 15.5636 18.4507 14.6261 16.8923L14.3136 16.3936Z" fill="#02065B"/>
  </g>
  <path d="M94.1066 10.7676H92.0694V6.61235H88.2514V10.7676H86.2142V1.13491H88.2514V4.91245H92.0694V1.13491H94.1066V10.7676Z" fill="#02065B"/>
  <path d="M102.075 10.7676H96.5302V1.13491H102.075V2.80782H98.5674V4.92594H101.832V6.59886H98.5674V9.08124H102.075V10.7676Z" fill="#02065B"/>
  <path d="M109.975 10.7676L109.274 8.47414H105.766L105.064 10.7676H102.865L106.265 1.09444H108.761L112.174 10.7676H109.975ZM108.086 4.52121C108.041 4.36831 107.983 4.17493 107.911 3.94109C107.839 3.70724 107.767 3.46889 107.695 3.22605C107.623 2.98321 107.565 2.77185 107.52 2.59196C107.475 2.77185 107.412 2.9967 107.331 3.26652C107.259 3.52736 107.187 3.77919 107.115 4.02203C107.052 4.25588 107.003 4.42227 106.967 4.52121L106.279 6.76075H108.788L108.086 4.52121Z" fill="#02065B"/>
  <path d="M113.394 10.7676V1.13491H115.431V9.08124H119.344V10.7676H113.394Z" fill="#02065B"/>
  <path d="M124.469 10.7676H122.432V2.83481H119.815V1.13491H127.086V2.83481H124.469V10.7676Z" fill="#02065B"/>
  <path d="M136.464 10.7676H134.427V6.61235H130.609V10.7676H128.572V1.13491H130.609V4.91245H134.427V1.13491H136.464V10.7676Z" fill="#02065B"/>
  <path d="M142.976 2.69989C142.589 2.69989 142.243 2.77634 141.937 2.92924C141.64 3.08214 141.388 3.3025 141.181 3.59031C140.975 3.86913 140.817 4.21091 140.709 4.61565C140.61 5.02038 140.561 5.47459 140.561 5.97826C140.561 6.66182 140.642 7.24643 140.804 7.73212C140.975 8.20881 141.24 8.57307 141.6 8.82491C141.959 9.07674 142.418 9.20266 142.976 9.20266C143.371 9.20266 143.763 9.15769 144.149 9.06775C144.545 8.97781 144.972 8.85189 145.431 8.68999V10.4034C145.008 10.5743 144.59 10.7002 144.176 10.7811C143.763 10.8621 143.3 10.9026 142.787 10.9026C141.798 10.9026 140.979 10.7002 140.331 10.2955C139.693 9.88172 139.221 9.30609 138.915 8.56857C138.609 7.82206 138.456 6.95412 138.456 5.96477C138.456 5.23624 138.555 4.57068 138.753 3.96807C138.951 3.35647 139.239 2.83031 139.616 2.3896C140.003 1.94888 140.475 1.60711 141.033 1.36426C141.6 1.12142 142.247 1 142.976 1C143.452 1 143.929 1.06296 144.406 1.18888C144.892 1.3058 145.355 1.4677 145.795 1.67456L145.134 3.33398C144.775 3.16309 144.41 3.01469 144.042 2.88877C143.682 2.76285 143.327 2.69989 142.976 2.69989Z" fill="#02065B"/>
  <path d="M153.374 10.7676L152.672 8.47414H149.164L148.463 10.7676H146.264L149.664 1.09444H152.159L155.573 10.7676H153.374ZM151.485 4.52121C151.44 4.36831 151.381 4.17493 151.31 3.94109C151.238 3.70724 151.166 3.46889 151.094 3.22605C151.022 2.98321 150.963 2.77185 150.918 2.59196C150.873 2.77185 150.81 2.9967 150.729 3.26652C150.657 3.52736 150.586 3.77919 150.514 4.02203C150.451 4.25588 150.401 4.42227 150.365 4.52121L149.677 6.76075H152.186L151.485 4.52121Z" fill="#02065B"/>
  <path d="M159.599 1.13491C160.471 1.13491 161.191 1.24284 161.758 1.4587C162.324 1.66557 162.747 1.98486 163.026 2.41658C163.305 2.8393 163.444 3.37445 163.444 4.02203C163.444 4.46275 163.358 4.84949 163.188 5.18228C163.026 5.50607 162.805 5.78489 162.527 6.01873C162.257 6.24359 161.964 6.42797 161.65 6.57187L164.483 10.7676H162.216L159.923 7.07105H158.83V10.7676H156.793V1.13491H159.599ZM159.451 2.80782H158.83V5.41163H159.491C159.941 5.41163 160.305 5.36216 160.584 5.26323C160.863 5.16429 161.065 5.01589 161.191 4.81802C161.317 4.61115 161.38 4.36381 161.38 4.076C161.38 3.7702 161.308 3.52736 161.164 3.34747C161.029 3.1586 160.818 3.02368 160.53 2.94274C160.251 2.85279 159.891 2.80782 159.451 2.80782Z" fill="#02065B"/>
  <path d="M171.244 10.7676H165.699V1.13491H171.244V2.80782H167.736V4.92594H171.001V6.59886H167.736V9.08124H171.244V10.7676Z" fill="#02065B"/>
  <path d="M89.8163 28.7676H85.4317V27.6074L86.6054 27.0677V20.8348L85.4317 20.2952V19.1349H89.8163V20.2952L88.6426 20.8348V27.0677L89.8163 27.6074V28.7676Z" fill="#02065B"/>
  <path d="M99.9978 28.7676H97.4075L93.2117 21.4824H93.1577C93.1667 21.7072 93.1757 21.9366 93.1847 22.1704C93.1937 22.3953 93.2027 22.6246 93.2117 22.8585C93.2297 23.0833 93.2432 23.3127 93.2522 23.5465C93.2612 23.7714 93.2702 24.0007 93.2792 24.2346V28.7676H91.4578V19.1349H94.0347L98.217 26.3527H98.2574C98.2484 26.1279 98.2394 25.9075 98.2304 25.6916C98.2214 25.4668 98.2125 25.2464 98.2035 25.0306C98.2035 24.8057 98.199 24.5854 98.19 24.3695C98.181 24.1447 98.172 23.9198 98.163 23.6949V19.1349H99.9978V28.7676Z" fill="#02065B"/>
  <path d="M110.973 28.7676H108.382L104.186 21.4824H104.133C104.142 21.7072 104.151 21.9366 104.16 22.1704C104.169 22.3953 104.178 22.6246 104.186 22.8585C104.204 23.0833 104.218 23.3127 104.227 23.5465C104.236 23.7714 104.245 24.0007 104.254 24.2346V28.7676H102.433V19.1349H105.009L109.192 26.3527H109.232C109.223 26.1279 109.214 25.9075 109.205 25.6916C109.196 25.4668 109.187 25.2464 109.178 25.0306C109.178 24.8057 109.174 24.5854 109.165 24.3695C109.156 24.1447 109.147 23.9198 109.138 23.6949V19.1349H110.973V28.7676Z" fill="#02065B"/>
  <path d="M122.15 23.9378C122.15 24.6843 122.055 25.3634 121.866 25.975C121.687 26.5776 121.408 27.0992 121.03 27.5399C120.661 27.9807 120.185 28.3179 119.6 28.5518C119.024 28.7856 118.345 28.9026 117.563 28.9026C116.78 28.9026 116.097 28.7856 115.512 28.5518C114.936 28.3089 114.46 27.9717 114.082 27.5399C113.713 27.0992 113.434 26.5731 113.246 25.9615C113.066 25.3499 112.976 24.6708 112.976 23.9243C112.976 22.9259 113.138 22.058 113.461 21.3205C113.794 20.583 114.298 20.0118 114.972 19.6071C115.656 19.1934 116.524 18.9865 117.576 18.9865C118.62 18.9865 119.478 19.1934 120.153 19.6071C120.828 20.0118 121.327 20.5875 121.651 21.334C121.983 22.0715 122.15 22.9394 122.15 23.9378ZM115.121 23.9378C115.121 24.6123 115.206 25.1925 115.377 25.6782C115.548 26.1638 115.813 26.5371 116.173 26.7979C116.533 27.0588 116.996 27.1892 117.563 27.1892C118.147 27.1892 118.615 27.0588 118.966 26.7979C119.326 26.5371 119.586 26.1638 119.748 25.6782C119.919 25.1925 120.005 24.6123 120.005 23.9378C120.005 22.9214 119.816 22.1255 119.438 21.5498C119.06 20.9742 118.44 20.6864 117.576 20.6864C117.001 20.6864 116.533 20.8168 116.173 21.0776C115.813 21.3385 115.548 21.7117 115.377 22.1974C115.206 22.6831 115.121 23.2632 115.121 23.9378Z" fill="#02065B"/>
  <path d="M131.568 19.1349L128.29 28.7676H126.064L122.799 19.1349H124.863L126.671 24.8687C126.707 24.9676 126.757 25.152 126.819 25.4218C126.891 25.6826 126.963 25.9615 127.035 26.2583C127.107 26.5461 127.157 26.7889 127.184 26.9868C127.211 26.7889 127.256 26.5461 127.319 26.2583C127.391 25.9615 127.463 25.6826 127.535 25.4218C127.606 25.152 127.656 24.9676 127.683 24.8687L129.504 19.1349H131.568Z" fill="#02065B"/>
  <path d="M138.143 28.7676L137.442 26.4741H133.934L133.233 28.7676H131.033L134.433 19.0944H136.929L140.342 28.7676H138.143ZM136.255 22.5212C136.21 22.3683 136.151 22.1749 136.079 21.9411C136.007 21.7072 135.935 21.4689 135.863 21.2261C135.791 20.9832 135.733 20.7718 135.688 20.592C135.643 20.7718 135.58 20.9967 135.499 21.2665C135.427 21.5274 135.355 21.7792 135.283 22.022C135.22 22.2559 135.171 22.4223 135.135 22.5212L134.447 24.7607H136.956L136.255 22.5212Z" fill="#02065B"/>
  <path d="M144.324 28.7676H142.287V20.8348H139.669V19.1349H146.941V20.8348H144.324V28.7676Z" fill="#02065B"/>
  <path d="M152.029 28.7676H147.644V27.6074L148.818 27.0677V20.8348L147.644 20.2952V19.1349H152.029V20.2952L150.855 20.8348V27.0677L152.029 27.6074V28.7676Z" fill="#02065B"/>
  <path d="M162.413 23.9378C162.413 24.6843 162.318 25.3634 162.129 25.975C161.949 26.5776 161.671 27.0992 161.293 27.5399C160.924 27.9807 160.447 28.3179 159.863 28.5518C159.287 28.7856 158.608 28.9026 157.826 28.9026C157.043 28.9026 156.36 28.7856 155.775 28.5518C155.199 28.3089 154.723 27.9717 154.345 27.5399C153.976 27.0992 153.697 26.5731 153.508 25.9615C153.329 25.3499 153.239 24.6708 153.239 23.9243C153.239 22.9259 153.4 22.058 153.724 21.3205C154.057 20.583 154.561 20.0118 155.235 19.6071C155.919 19.1934 156.787 18.9865 157.839 18.9865C158.882 18.9865 159.741 19.1934 160.416 19.6071C161.09 20.0118 161.59 20.5875 161.913 21.334C162.246 22.0715 162.413 22.9394 162.413 23.9378ZM155.384 23.9378C155.384 24.6123 155.469 25.1925 155.64 25.6782C155.811 26.1638 156.076 26.5371 156.436 26.7979C156.796 27.0588 157.259 27.1892 157.826 27.1892C158.41 27.1892 158.878 27.0588 159.229 26.7979C159.588 26.5371 159.849 26.1638 160.011 25.6782C160.182 25.1925 160.267 24.6123 160.267 23.9378C160.267 22.9214 160.079 22.1255 159.701 21.5498C159.323 20.9742 158.703 20.6864 157.839 20.6864C157.263 20.6864 156.796 20.8168 156.436 21.0776C156.076 21.3385 155.811 21.7117 155.64 22.1974C155.469 22.6831 155.384 23.2632 155.384 23.9378Z" fill="#02065B"/>
  <path d="M172.948 28.7676H170.358L166.162 21.4824H166.108C166.117 21.7072 166.126 21.9366 166.135 22.1704C166.144 22.3953 166.153 22.6246 166.162 22.8585C166.18 23.0833 166.193 23.3127 166.202 23.5465C166.211 23.7714 166.22 24.0007 166.229 24.2346V28.7676H164.408V19.1349H166.985L171.167 26.3527H171.207C171.199 26.1279 171.19 25.9075 171.181 25.6916C171.172 25.4668 171.163 25.2464 171.154 25.0306C171.154 24.8057 171.149 24.5854 171.14 24.3695C171.131 24.1447 171.122 23.9198 171.113 23.6949V19.1349H172.948V28.7676Z" fill="#02065B"/>
  <path d="M89.2902 37.1349C90.5314 37.1349 91.4353 37.4047 92.0019 37.9444C92.5776 38.475 92.8654 39.2081 92.8654 40.1435C92.8654 40.5662 92.8024 40.9709 92.6765 41.3577C92.5506 41.7354 92.3392 42.0772 92.0424 42.383C91.7546 42.6798 91.3723 42.9137 90.8956 43.0845C90.419 43.2554 89.8298 43.3409 89.1283 43.3409H88.2514V46.7676H86.2142V37.1349H89.2902ZM89.1823 38.8078H88.2514V41.668H88.9259C89.3127 41.668 89.6455 41.6185 89.9243 41.5196C90.2031 41.4206 90.419 41.2632 90.5719 41.0474C90.7248 40.8315 90.8012 40.5527 90.8012 40.2109C90.8012 39.7342 90.6708 39.3834 90.41 39.1586C90.1491 38.9247 89.7399 38.8078 89.1823 38.8078Z" fill="#02065B"/>
  <path d="M94.6857 46.7676V37.1349H96.7229V45.0812H100.635V46.7676H94.6857Z" fill="#02065B"/>
  <path d="M108.21 46.7676L107.508 44.4741H104L103.299 46.7676H101.1L104.5 37.0944H106.996L110.409 46.7676H108.21ZM106.321 40.5212C106.276 40.3683 106.218 40.1749 106.146 39.9411C106.074 39.7072 106.002 39.4689 105.93 39.2261C105.858 38.9832 105.799 38.7718 105.754 38.592C105.709 38.7718 105.646 38.9967 105.565 39.2665C105.493 39.5274 105.422 39.7792 105.35 40.022C105.287 40.2559 105.237 40.4223 105.201 40.5212L104.513 42.7607H107.023L106.321 40.5212Z" fill="#02065B"/>
  <path d="M114.39 46.7676H112.353V38.8348H109.736V37.1349H117.008V38.8348H114.39V46.7676Z" fill="#02065B"/>
  <path d="M120.503 46.7676H118.493V37.1349H124.011V38.8078H120.503V41.2902H123.768V42.9631H120.503V46.7676Z" fill="#02065B"/>
  <path d="M134.64 41.9378C134.64 42.6843 134.545 43.3634 134.356 43.975C134.176 44.5776 133.898 45.0992 133.52 45.5399C133.151 45.9807 132.674 46.3179 132.09 46.5518C131.514 46.7856 130.835 46.9026 130.053 46.9026C129.27 46.9026 128.587 46.7856 128.002 46.5518C127.426 46.3089 126.95 45.9717 126.572 45.5399C126.203 45.0992 125.924 44.5731 125.735 43.9615C125.556 43.3499 125.466 42.6708 125.466 41.9243C125.466 40.9259 125.628 40.058 125.951 39.3205C126.284 38.583 126.788 38.0118 127.462 37.6071C128.146 37.1934 129.014 36.9865 130.066 36.9865C131.109 36.9865 131.968 37.1934 132.643 37.6071C133.318 38.0118 133.817 38.5875 134.14 39.334C134.473 40.0715 134.64 40.9394 134.64 41.9378ZM127.611 41.9378C127.611 42.6123 127.696 43.1925 127.867 43.6782C128.038 44.1638 128.303 44.5371 128.663 44.7979C129.023 45.0588 129.486 45.1892 130.053 45.1892C130.637 45.1892 131.105 45.0588 131.456 44.7979C131.815 44.5371 132.076 44.1638 132.238 43.6782C132.409 43.1925 132.495 42.6123 132.495 41.9378C132.495 40.9214 132.306 40.1255 131.928 39.5498C131.55 38.9742 130.93 38.6864 130.066 38.6864C129.491 38.6864 129.023 38.8168 128.663 39.0776C128.303 39.3385 128.038 39.7117 127.867 40.1974C127.696 40.6831 127.611 41.2632 127.611 41.9378Z" fill="#02065B"/>
  <path d="M139.441 37.1349C140.314 37.1349 141.033 37.2428 141.6 37.4587C142.166 37.6656 142.589 37.9849 142.868 38.4166C143.147 38.8393 143.286 39.3745 143.286 40.022C143.286 40.4627 143.201 40.8495 143.03 41.1823C142.868 41.5061 142.648 41.7849 142.369 42.0187C142.099 42.2436 141.807 42.428 141.492 42.5719L144.325 46.7676H142.058L139.765 43.071H138.672V46.7676H136.635V37.1349H139.441ZM139.293 38.8078H138.672V41.4116H139.333C139.783 41.4116 140.147 41.3622 140.426 41.2632C140.705 41.1643 140.907 41.0159 141.033 40.818C141.159 40.6111 141.222 40.3638 141.222 40.076C141.222 39.7702 141.15 39.5274 141.006 39.3475C140.871 39.1586 140.66 39.0237 140.372 38.9427C140.093 38.8528 139.733 38.8078 139.293 38.8078Z" fill="#02065B"/>
  <path d="M149.616 46.7676L147.295 39.2126H147.241C147.25 39.3924 147.264 39.6623 147.282 40.022C147.3 40.3818 147.318 40.7685 147.336 41.1823C147.354 41.587 147.363 41.9558 147.363 42.2886V46.7676H145.541V37.1349H148.32L150.601 44.5011H150.641L153.056 37.1349H155.835V46.7676H153.933V42.2076C153.933 41.9018 153.937 41.551 153.946 41.1553C153.964 40.7596 153.978 40.3863 153.987 40.0355C154.005 39.6758 154.018 39.4059 154.027 39.2261H153.973L151.491 46.7676H149.616Z" fill="#02065B"/>
  <defs>
    <clipPath id="clip0_10050_12878">
      <rect width="65" height="48" fill="white"/>
    </clipPath>
  </defs>
</svg>
`,
    name: 'logo-horizontal'
};
