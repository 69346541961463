<form [formGroup]="baseForm">
  <div [id]="'quick-poll-' + quickPoll?.id" class="quick-poll py-2 px-2 background-white100">
    <div class="row my-1">
      <div class="col-8">
        <span class="title-h3">{{ quickPoll?.question }}</span>
      </div>
      <div class="timing col-4 d-flex justify-content-end gap-1 color-secondary40">
        <svg-icon key="time" fontSize="24px"></svg-icon>
        <span>{{ getClosingInText() }}</span>
      </div>
    </div>
    <div class="row">
      <div *ngIf="quickPoll?.isMultipleChoice" class="col color-secondary">
        <app-checkbox
          *ngFor="let answer of getAnswerOptions(); trackBy: trackById"
          [name]="'answer-checkbox-' + answer.id"
          class="my-1"
          [formControlName]="answer.id"
        >
          <div class="row d-flex align-items-center">
            <div class="col d-flex">
              <span>
                {{ answer.text }}
              </span>
            </div>
            <div class="col d-flex justify-content-end text-small-italic mx-1 color-secondary">
              <span>{{ calculatePercentage(answer.voteCount) }}</span>
            </div>
          </div>
        </app-checkbox>
      </div>
      <div *ngIf="!quickPoll?.isMultipleChoice" class="col color-secondary">
        <app-radio
          *ngFor="let answer of quickPoll?.answers; trackBy: trackById"
          name="answersRadio"
          class="my-1"
          (click)="onSingleChoiceAnswer(answer)"
          formControlName="answer"
          [value]="answer.id"
        >
          <div class="row d-flex align-items-center">
            <div class="col d-flex">
              <span>
                {{ answer.text }}
              </span>
            </div>
            <div class="col d-flex justify-content-end text-small-italic color-secondary">
              <span>{{ calculatePercentage(answer.voteCount) }}</span>
            </div>
          </div>
        </app-radio>
      </div>
    </div>
    <div *ngIf="quickPoll?.canAddOwnAnswer || quickPoll?.isMultipleChoice || isCustomAnswerVisible" class="row separator mt-1 mb-2"></div>
    <div *ngIf="isCustomAnswerVisible" class="row">
      <div class="col">
        <app-input type="text" placeholder="Type your new answer here" formControlName="customAnswer" class="mb-1">
          <svg-icon prefix key="cross" fontSize="16px" class="color-primary"></svg-icon>
          <button suffix class="tiny secondary" (click)="onSubmitCustomAnswer()">add</button>
        </app-input>
      </div>
    </div>
    <div *ngIf="quickPoll?.canAddOwnAnswer || quickPoll?.isMultipleChoice" class="row w-100 my-1">
      <div *ngIf="quickPoll?.canAddOwnAnswer" class="col">
        <button class="primary small" [disabled]="isClosed() || hasAnswered()" (click)="onToggleCustomAnswer()">
          <svg-icon key="add" fontSize="16px"></svg-icon>
          add your own
        </button>
      </div>

      <div *ngIf="quickPoll?.isMultipleChoice" class="col d-flex justify-content-end">
        <button class="primary small" [disabled]="isClosed() || hasAnswered()" (click)="onSubmitMultiChoiceAnswer()">submit</button>
      </div>
    </div>
  </div>
</form>
