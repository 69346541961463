<h1 class="title-h1 color-secondary mb-2">Edit interests</h1>
<h3 class="title-h3 color-secondary mb-4">Add topics to follow them on your feed and get notifications of related news</h3>
<div class="tags mb-6">
  <div class="head d-flex justify-content-between align-items-center mb-3">
    <div class="left d-flex gap-2 align-items-center">
      <h2 class="title-h2 color-secondary mb-0">Favourites</h2>
      <span *ngIf="selectedTagsCount > 0" class="text-normal color-secondary">{{ selectedTagsCount }} selected</span>
    </div>
    <div class="right d-flex gap-2">
      <button class="secondary small" (click)="onSelectAllTagClick()">select all</button>
      <button class="secondary small" (click)="onClearAllTagClick()">clear</button>
    </div>
  </div>
  <app-input
    class="my-2"
    placeholder="Type here to search for a discussion"
    [style]="'secondary'"
    [formControl]="searchControl"
    [clearable]="true"
  >
    <svg-icon prefix key="search" fontSize="24px"></svg-icon>
  </app-input>
  <div class="separator"></div>
  <app-tag-select *ngIf="displayedTags.length > 0; else noMatches" class="mt-3" [tags]="displayedTags"></app-tag-select>
  <ng-template #noMatches>
    <p class="color-secondary my-2">Cannot find any topics with the given term.</p>
  </ng-template>
</div>
<div class="actions d-flex gap-2">
  <button class="primary large flex-grow-1" (click)="onFilterClose(true)">save</button>
  <button class="secondary large" (click)="onFilterClose(false)">cancel</button>
</div>
