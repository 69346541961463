import { Component, ChangeDetectionStrategy, Input, TrackByFunction, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ScrollDirection } from '@shared/directives/scroll-direction';
import { getContributionHoursText } from '@shared/helpers/get-contribution-hours-text.helper';
import { DialogService } from '@shared/modules/dialog/dialog.service';
import { FlagToggle } from 'src/app/stores/thread/flag-toggle';
import { DiscussionRule } from 'src/domain/discussion-rule';
import { DiscussionMessage } from '../discussion-input/discussion-message.interface';
import { DiscussionRulesDialogComponent } from '../discussion-rules-dialog/discussion-rules-dialog.component';
import { IllustrationType } from '../illustration/illustration-type.enum';
import { DiscussionDetailCardVM } from './discussion-detail-card.vm';

@Component({
  selector: 'app-discussion-detail-card',
  templateUrl: './discussion-detail-card.component.html',
  styleUrls: ['./discussion-detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionDetailCardComponent {
  @ViewChild('messageList', { static: false }) public messageList!: ElementRef<HTMLDivElement>;
  @Input() public disableInteraction = false;
  @Input() public vm?: DiscussionDetailCardVM;
  @Output() public messageReport = new EventEmitter<{ messageId: string; toggle: FlagToggle }>();
  @Output() public messageSend = new EventEmitter<DiscussionMessage>();
  @Output() public replySend = new EventEmitter<{ message: DiscussionMessage; parentId: string }>();
  @Output() public loadQuickPollList = new EventEmitter<{ quickPollIds: Array<string> }>();
  @Output() public addCustomAnswer = new EventEmitter<{ quickPollId: string; text: string }>();
  @Output() public answerPoll = new EventEmitter<{ quickPollId: string; answerIds: Array<string> }>();

  public readonly illustrationTypes = IllustrationType;
  public readonly collapseHeightBenefit = 140;
  public isTopCollapsed = false;
  public isDetailsSectionOpen = false;

  public constructor(private readonly dialogService: DialogService) {}

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;
  public trackByValue: TrackByFunction<string> = (_, item) => item;

  public getContributionHoursText(): string {
    return getContributionHoursText(this.vm?.fields?.contributionHours ?? 0);
  }

  public isCollaborationDetailsVisible(): boolean {
    return Boolean(this.vm?.isCollaboration) && Boolean(this.vm?.fields);
  }

  public toggleDetailsVisibility(): void {
    this.isDetailsSectionOpen = !this.isDetailsSectionOpen;
  }

  public hasDiscussionRules(): boolean {
    return Boolean(this.vm?.discussionRules) && this.vm?.discussionRules?.length !== 0;
  }

  public onDiscussionRules(): void {
    this.dialogService.open<Array<DiscussionRule>, boolean>(DiscussionRulesDialogComponent, this.vm?.discussionRules, {
      closeable: false,
      width: '496px',
    });
  }

  public onScrollChange(scrollDirection: ScrollDirection): void {
    // scrolling should only trigger collapsing, if the area is still scrollable after collapse
    if (
      scrollDirection === ScrollDirection.Down &&
      this.messageList.nativeElement.clientHeight + this.collapseHeightBenefit >= this.messageList.nativeElement.scrollHeight
    ) {
      return;
    }
    this.isTopCollapsed = scrollDirection === ScrollDirection.Down;
  }

  public onReplySend(event: { message: DiscussionMessage; parentId: string }): void {
    const { message, parentId } = event;

    this.replySend.next({ message, parentId });
  }

  public onMessageSend(message: DiscussionMessage): void {
    this.messageSend.next(message);
  }

  public onMessageReport(event: { messageId: string; toggle: FlagToggle }): void {
    const { messageId, toggle } = event;

    this.messageReport.next({ messageId, toggle });
  }

  public onAddCustomAnswer(event: { quickPollId: string; text: string }): void {
    this.addCustomAnswer.next({ quickPollId: event.quickPollId, text: event.text });
  }

  public onAnswerPoll(event: { quickPollId: string; answerIds: Array<string> }): void {
    this.answerPoll.next({ quickPollId: event.quickPollId, answerIds: event.answerIds });
  }
}
