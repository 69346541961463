import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { ReactiveComponentModule } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ProfileRecentEntryCardComponent } from '@shared/components/profile-recent-entry-card/profile-recent-entry-card.component';
import { ProfileJoinedDiscussionCardComponent } from '@shared/components/profile-joined-discussion-card/profile-joined-discussion-card.component';
import { RadioComponent } from './components/radio/radio.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputComponent } from './components/input/input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ToastMessageModule } from './modules/toast-message/toast-message.module';
import { SelectionComponent } from './components/selection/selection.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { MessageComponent } from './components/message/message.component';
import { NavComponent } from './components/nav/nav.component';
import { ListHeadComponent } from './components/list-head/list-head.component';
import { DiscussionSortDialogComponent } from './components/discussion-sort-dialog/discussion-sort-dialog.component';
import { DiscussionFilterDialogComponent } from './components/discussion-filter-dialog/discussion-filter-dialog.component';
import { DiscussionListCardComponent } from './components/discussion-list-card/discussion-list-card.component';
import { DiscussionDetailCardComponent } from './components/discussion-detail-card/discussion-detail-card.component';
import { DiscussionInputComponent } from './components/discussion-input/discussion-input.component';
import { IllustrationComponent } from './components/illustration/illustration.component';
import { TagSelectComponent } from './components/tag-select/tag-select.component';
import { MemberListComponent } from './components/member-list/member-list.component';
import { EditTopicPreferencesDialogComponent } from './components/edit-topic-preferences-dialog/edit-topic-preferences-dialog.component';
import { ScrollListenerDirective } from './directives/scroll-listener.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { DiscussionRulesDialogComponent } from './components/discussion-rules-dialog/discussion-rules-dialog.component';
import { QuickPollMessageComponent } from './components/quick-poll-message/quick-poll-message.component';
import { CollaborationStatusComponent } from './components/collaboration-status/collaboration-status.component';
import { CollaborationFieldComponent } from './components/collaboration-field/collaboration-field.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { ProfileBioSectionComponent } from './components/profile-bio-section/profile-bio-section.component';
import { EventListCardComponent } from './components/event-list-card/event-list-card.component';
import { EventDateComponent } from './components/event-date/event-date.component';
import { OrdinalDayPipe } from './pipes/ordinal-day.pipe';
import { MessageThreadComponent } from './components/message-thread/message-thread.component';
import { MessageSeparatorComponent } from './components/message-separator/message-separator.component';
import { MessageTitleComponent } from './components/message-title/message-title.component';
import { MemberInfoPanelComponent } from './components/member-info-panel/member-info-panel.component';
import { ProfessionSelectComponent } from './components/profession-select/profession-select.component';
import { PushTagOnClickDirective } from './directives/push-tag-on-click/push-tag-on-click.directive';

export const SharedComponents = [
  CheckboxComponent,
  CollaborationStatusComponent,
  CollaborationFieldComponent,
  RadioComponent,
  DropdownComponent,
  InputComponent,
  TextareaComponent,
  SelectionComponent,
  InfoPanelComponent,
  DateAgoPipe,
  OrdinalDayPipe,
  MessageComponent,
  MessageThreadComponent,
  QuickPollMessageComponent,
  NavComponent,
  ListHeadComponent,
  DiscussionFilterDialogComponent,
  DiscussionSortDialogComponent,
  DiscussionListCardComponent,
  DiscussionDetailCardComponent,
  DiscussionInputComponent,
  DiscussionRulesDialogComponent,
  IllustrationComponent,
  TagSelectComponent,
  MemberListComponent,
  EditTopicPreferencesDialogComponent,
  ScrollListenerDirective,
  LoadingComponent,
  ScrollListenerDirective,
  ListCardComponent,
  ProfileBioSectionComponent,
  ProfileJoinedDiscussionCardComponent,
  ProfileRecentEntryCardComponent,
  EventListCardComponent,
  EventDateComponent,
  MessageSeparatorComponent,
  MessageTitleComponent,
  MemberInfoPanelComponent,
  ProfessionSelectComponent,
];

@NgModule({
  imports: [SvgIconsModule.forChild([]), CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, OverlayModule, RouterModule],
  exports: [CommonModule, ReactiveComponentModule, ToastMessageModule, TranslateModule, ...SharedComponents, PushTagOnClickDirective],
  declarations: [SharedComponents, PushTagOnClickDirective],
})
export class SharedModule {}
