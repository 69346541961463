<app-list-card
  [label]="vm.isCollaboration ? 'Collaboration' : 'Discussion'"
  [isTrending]="vm.isTrending"
  [color]="vm.isCollaboration ? 'background-collaboration' : ''"
>
  <div class="d-flex align-items-center mb-3 gap-4 justify-content-between w-100">
    <div class="title-wrapper flex-grow-1">
      <a
        *ngIf="!vm.isCollaboration; else collaborationTitle"
        class="color-secondary"
        [routerLink]="['/', 'platform', 'discussions', vm.id]"
      >
        <h2 class="title-h2">{{ vm.title }}</h2>
      </a>
      <ng-template #collaborationTitle>
        <a
          *ngIf="vm.isMember; else notClickableTitle"
          class="color-secondary"
          [routerLink]="[vm.id]"
          [routerLink]="['/', 'platform', 'collaborations', vm.id]"
        >
          <h2 class="title-h2">{{ vm.title }}</h2>
        </a>
        <ng-template #notClickableTitle>
          <h2 class="color-secondary title-h2">{{ vm.title }}</h2>
        </ng-template>
      </ng-template>
    </div>
    <app-info-panel [vm]="vm" (buttonClick)="onButtonClick($event)"></app-info-panel>
  </div>
  <p *ngIf="vm.isCollaboration && vm.description" class="description text-normal mb-3">{{ vm.description }}</p>
  <div class="tags d-flex gap-4">
    <div class="row w-100 d-flex align-items-center">
      <div class="col">
        <span *ngFor="let tag of vm.tags.slice(0, 3); trackBy: trackById" class="text-normal text-uppercase">#{{ tag.name }}</span>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <app-collaboration-status *ngIf="vm.collaborationStatus" [status]="vm.collaborationStatus"></app-collaboration-status>
      </div>
    </div>
  </div>
</app-list-card>
