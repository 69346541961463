import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { distinctUntilChanged, filter, fromEvent, map, pairwise, share } from 'rxjs';
import { ScrollDirection } from './scroll-direction';

const THROTTLE_TIME = 1000;

@Directive({
  selector: '[appScrollListener]',
})
export class ScrollListenerDirective {
  /**
   * Skips emission if the bottom of the element has been reached
   */
  @Input() public filterBottom = false;
  /**
   * Emits the scroll direction when it changes.
   */
  @Output() public scrollDirectionChange = new EventEmitter<ScrollDirection>();
  constructor(private readonly el: ElementRef) {
    fromEvent(this.el.nativeElement, 'scroll')
      .pipe(
        filter(() =>
          this.filterBottom
            ? this.el.nativeElement.offsetHeight + this.el.nativeElement.scrollTop < this.el.nativeElement.scrollHeight
            : true
        ),
        map(() => this.el.nativeElement.scrollTop),
        pairwise(),
        map(([y1, y2]): ScrollDirection => (y2 < y1 ? ScrollDirection.Up : ScrollDirection.Down)),
        distinctUntilChanged(),
        share()
      )
      .subscribe((direction) => this.scrollDirectionChange.next(direction));
  }
}
