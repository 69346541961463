export const appPoll40Icon = {
  data: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 13.75C9.58333 13.75 11.25 12.0833 11.25 10C11.25 7.91667 9.58333 6.25 7.5 6.25C5.41667 6.25 3.75 7.83333 3.75 9.91667C3.75 12 5.41667 13.75 7.5 13.75Z" fill="#02065B"/>
    <path d="M10.1517 22.6523L4.84837 17.349" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.1517 17.3477L4.84837 22.651" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.5 33.75C9.58333 33.75 11.25 32.0833 11.25 30C11.25 27.9167 9.58333 26.25 7.5 26.25C5.41667 26.25 3.75 27.8333 3.75 29.9167C3.75 32 5.41667 33.75 7.5 33.75Z" fill="#02065B"/>
    <path d="M17.5 10L26.25 10" stroke="#02065B" stroke-width="6" stroke-linecap="round"/>
    <path d="M17.5 20L30 20" stroke="#02065B" stroke-width="6" stroke-linecap="round"/>
    <path d="M17.5 30L32.5 30" stroke="#02065B" stroke-width="6" stroke-linecap="round"/>
  </svg>`,
  name: 'poll-40',
};
