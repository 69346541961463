<div class="d-flex flex-column justify-content-center align-items-center h-100 w-100 pb-8">
  <div class="illustration-wrapper">
    <app-illustration [type]="illustrationTypes.Error"></app-illustration>
  </div>
  <div>{{ route.snapshot.data.title }}</div>
  <p class="mb-5">{{ route.snapshot.data.text }}</p>
  <button *ngIf="route.snapshot.data.isPublic; else privateNavTemplate" class="primary large" [routerLink]="['/']">
    <svg-icon key="arrow-left"></svg-icon>
    <span>go to homepage</span>
  </button>
  <ng-template #privateNavTemplate>
    <button class="primary large" [routerLink]="['/', 'platform']">
      <svg-icon key="arrow-left"></svg-icon>
      <span>go to dashboard</span>
    </button>
  </ng-template>
</div>
