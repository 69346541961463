import { Profession } from 'src/domain/profession';
import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';

const feature = '[Signup Submit]';

export const signupSubmit = createAction(
  `${feature} signup submit`,
  props<{
    email: string;
    firstName: string;
    lastName: string;
    profession?: Profession;
    bio?: string;
    linkedinProfile: string;
    password: string;
    hasAcceptedTerms: boolean;
    hasMarketingConsented: boolean;
  }>()
);

export const signupSubmitSuccess = createAction(`${feature} signup submit success`);

export const signupSubmitError = createAction(`${feature} signup submit error`, props<{ error: ApiError }>());
