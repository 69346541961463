import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DialogConfig } from './interfaces/dialog-config.interface';

export class DialogRef<Result = undefined> {
  // tslint:disable-next-line: variable-name
  private readonly _afterClosed$ = new Subject<Result | undefined>();
  // tslint:disable-next-line: variable-name
  private readonly _visible$ = new BehaviorSubject(true);
  // tslint:disable-next-line: variable-name
  private readonly _scrollEnd$ = new Subject<void>();

  public constructor(public readonly config: DialogConfig) {}

  public afterClosed$(): Observable<Result | undefined> {
    return this._afterClosed$.asObservable();
  }

  public visible$(): Observable<boolean> {
    return this._visible$.asObservable();
  }

  public scrollEnd$(): Observable<void> {
    return this._scrollEnd$.asObservable();
  }

  public close(result?: Result): void {
    this._afterClosed$.next(result);
    this._afterClosed$.complete();
  }

  public hide(): void {
    this._visible$.next(false);
  }

  public show(): void {
    this._visible$.next(true);
  }

  public triggerScrollEnd(): void {
    this._scrollEnd$.next();
  }
}
