import { Component, ChangeDetectionStrategy, Self, Optional, Input, ChangeDetectorRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionComponent implements ControlValueAccessor {
  @Input() public leftSelect = '';
  @Input() public rightSelect = '';
  @Input() public name = '';
  @Input() public wide = false;

  public onChange?: ((value: unknown) => void) | undefined;
  public onTouched?: (() => unknown) | undefined;

  constructor(@Self() @Optional() public ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get control(): FormControl | undefined {
    if (this.ngControl) {
      return this.ngControl.control as FormControl;
    }

    return undefined;
  }

  public onValueChange(eventTarget: EventTarget | null): void {
    const elementTarget = eventTarget as HTMLInputElement;
    const value = elementTarget.value;

    if (this.onChange) {
      this.onChange(value);
    }
  }

  public writeValue(): void {
    this.cdr.detectChanges();
  }

  public registerOnChange(function_: (_: unknown) => unknown): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }
}
