<h1 class="title-h1 color-secondary mb-6">Discussion rules</h1>
<p *ngFor="let rule of discussionRules; let i = index; trackBy: trackById" class="d-flex align-items-center gap-2">
  <button class="secondary small discussion-rule-number">
    <span>{{ i + 1 }}</span>
  </button>
  <span class="text-large color-secondary">{{ rule.text }}</span>
</p>
<div class="separator"></div>
<div class="actions d-flex mt-3 gap-2">
  <button class="primary large w-100" (click)="onCloseClick()">got it</button>
</div>
