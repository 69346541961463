import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Language, LocalizationService } from '@core/services/localization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public readonly languages = this.localizationService.languages;
  constructor(private readonly localizationService: LocalizationService) {
  }

  public useLanguage(language: Language) {
    this.localizationService.useLanguage(language)
  }
}
