import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class TextareaComponent extends InputComponent implements ControlValueAccessor {
  @Input() public rows?: number;
  @Input() public resize: 'none' | 'vertical' | 'horizontal' | 'both' = 'none';
}
