import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN } from '@core/config.token';
import { ToastMessageType } from '@shared/modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { Config } from '@core/types/config.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    @Inject(CONFIG_TOKEN) public readonly config: Config,
    private readonly toastMessageService: ToastMessageService,
    private readonly authService: AuthService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, sonarjs/cognitive-complexity
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(this.config.backendRootUrl) || request.url.includes('auth')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden)) {
          if (error.error.message === 'Invalid token') {
            this.authService.logout();
            this.showUnauthorizedToast(`Your session has expired, please log in again`);
            return throwError('token expired');
          }

          this.showUnauthorizedToast(`You don't have sufficient permissions to perform this action.`);
        }

        return throwError(error);
      })
    );
  }

  private showUnauthorizedToast(message: string) {
    this.toastMessageService.open(message, {
      closeable: true,
      type: ToastMessageType.Error,
    });
  }
}
