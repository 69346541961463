import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public name = '';
  @Input() public label = '';
  @Input() public hint?: string;
  @Input() public error?: string;
  @Input() public isIntermediate = false;

  @ViewChild('input') public input: ElementRef | undefined;

  public onChange: ((_: unknown) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public constructor(@Optional() @Self() public ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get hasError(): boolean {
    return Boolean(this.control?.touched) && Boolean(this.control?.invalid) && Object.values(this.control?.errors || []).length > 0;
  }

  public get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  @Input() public set intermediate(isIntermediate: boolean) {
    this.isIntermediate = isIntermediate;
  }

  // @TODO: refactor change detection (outside formcontrol markAsTouched doesn't trigger UI update, hence the subscription)
  // same as in input.component.ts
  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public onCheckboxChange(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    const elementTarget = event.target as HTMLInputElement;
    elementTarget.checked = !this.control?.value;
    const isChecked = elementTarget.checked;

    if (this.isIntermediate) {
      this.isIntermediate = false;
    }

    if (this.onChange) {
      this.onChange(isChecked);
    }
  }

  public writeValue(value: boolean): void {
    if (this.input) {
      this.input.nativeElement.value = value;
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (_: unknown) => unknown): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }
}
