import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';
import { NewsFeedItem } from 'src/domain/news-feed-item';

const feature = '[NewsFeed]';

export const getNewsFeed = createAction(`${feature} get news feed`);

export const getNewsFeedSuccess = createAction(`${feature} get news feed success`, props<{ items: NewsFeedItem[] }>());

export const getNewsFeedError = createAction(`${feature} get news feed error`, props<{ error: ApiError }>());
