import { Pipe, PipeTransform } from '@angular/core';

const RECENT_SECONDS_LIMIT = 30;

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  public transform(value: Date | string): string {
    if (value) {
      const seconds = Math.floor((Date.now() - +new Date(value)) / 1000);
      // less than 30 seconds ago will show as 'Just now'
      if (seconds < RECENT_SECONDS_LIMIT) {
        return 'Just now';
      }
      const intervals = {
        year: 31_536_000,
        month: 2_592_000,
        week: 604_800,
        day: 86_400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const interval of Object.entries(intervals)) {
        counter = Math.floor(seconds / interval[1]);
        if (counter > 0) return counter === 1 ? `${counter} ${interval[0]} ago` : `${counter} ${interval[0]}s ago`;
      }
    }
    return 'Invalid Date';
  }
}
