import { Reply } from 'src/domain/reply';

export const getPollIdListFromReplies = (replies: Array<Reply>): Array<string> =>
  replies.reduce(
    (acc: Array<string>, reply: Reply) => [
      ...(reply.quickPollId ? [...acc, reply.quickPollId] : acc),
      ...(reply.replies ? getPollIdListFromReplies(reply.replies) : []),
    ],
    new Array<string>()
  );
