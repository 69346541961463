import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { Observable, of, switchMap } from 'rxjs';
import { loginSuccess } from './stores/auth/auth.actions';
import { selectAuthData } from './stores/auth/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AppAuthRouteGuard implements CanActivate {
  public constructor(private readonly router: Router, private readonly authService: AuthService, private readonly store: Store) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const accessToken = this.authService.getAccessToken();

    if (!accessToken) {
      console.warn('❗ There is no access token, redirecting to login.');

      // TODO: store last url to be able to redirect after login
      /*this.store.dispatch(
        updateLoginRedirectUrl({
          loginRedirectUrl: state.url,
        })
      );*/

      // TODO: call here authService.login() instead where can be navigated to the the page
      this.router.navigate(['/', RouteSegment.Login]);
    } else {
      return this.store.select(selectAuthData).pipe(
        switchMap((authData) => {
          if (!authData?.accessToken) {
            this.store.dispatch(
              loginSuccess({
                auth: {
                  accessToken,
                  isLoggedIn: true,
                },
              })
            );
          }

          return of(true);
        })
      );
    }

    return true;
  }
}
