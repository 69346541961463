import { Component, ChangeDetectionStrategy, OnInit, Inject, TrackByFunction } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup, ValidatorFn } from '@ngneat/reactive-forms';
import { DIALOG_DATA } from '@shared/modules/dialog/dialog.tokens';
import { DialogData } from '@shared/modules/dialog/interfaces/dialog-data.interface';
import { ClosingUnit } from 'src/domain/closing-unit.enum';
import { ClosingOption, CreateQuickPoll } from 'src/domain/create-quick-poll';
import { QuickPoll } from 'src/domain/quick-poll';

@Component({
  selector: 'app-quick-poll-dialog',
  templateUrl: './quick-poll-dialog.component.html',
  styleUrls: ['./quick-poll-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickPollDialogComponent implements OnInit {
  public quickPollForm!: FormGroup;
  public readonly QUESTION_MAX_LENGTH = 280;
  public readonly MINIMUM_ANSWERS_COUNT = 2;
  public closingInOptions: Array<ClosingOption> = [
    { closingIn: 30, unit: ClosingUnit.Minutes },
    { closingIn: 1, unit: ClosingUnit.Hours },
    { closingIn: 5, unit: ClosingUnit.Hours },
    { closingIn: 24, unit: ClosingUnit.Hours },
  ];

  constructor(
    @Inject(DIALOG_DATA) private readonly dialog: DialogData<Omit<QuickPoll, 'quickPoll'>, Omit<CreateQuickPoll, 'createQuickPoll'>>,
    public readonly formBuilder: FormBuilder
  ) {}

  public trackByIndex: TrackByFunction<ClosingOption> = (index, _item) => index;

  public ngOnInit(): void {
    this.quickPollForm = this.formBuilder.group({
      questionControl: ['', [Validators.required, Validators.maxLength(this.QUESTION_MAX_LENGTH)]],
      answersControl: [[], this.answersCountValidator(this.MINIMUM_ANSWERS_COUNT)],
      canAddOwnAnswerControl: [false],
      isMultipleChoiceControl: [false],
      closingInControl: ['0', Validators.required],
    });
  }

  public answersCountValidator(minimumAnswerCount: number): ValidatorFn {
    return (): { hasAnswersCountError: boolean } | null =>
      this.getAnswerValues().length < minimumAnswerCount ? { hasAnswersCountError: true } : null;
  }

  public getRadioText({ closingIn, unit }: ClosingOption): string {
    return `${closingIn} ${unit}`;
  }

  public getQuestionErrorMessage(): string {
    const { questionControl } = this.quickPollForm.controls;

    if (questionControl.hasError('required')) {
      return 'You must give your poll a question or topic to continue';
    }

    if (questionControl.hasError('maxlength')) {
      return 'You must give your poll a question with proper length';
    }

    return '';
  }

  public getAnswersErrorMessage(): string {
    const { answersControl } = this.quickPollForm.controls;

    if (answersControl.hasError('hasAnswersCountError')) {
      return 'You must give at least 2 answers to continue';
    }

    return '';
  }

  public onFormSubmit(): void {
    if (this.quickPollForm.invalid) {
      this.quickPollForm.markAllAsTouched();

      return;
    }

    this.onDialogClose(true);
  }

  public onDialogClose(isQuickPollCreate: boolean): void {
    if (!isQuickPollCreate) {
      this.dialog.dialogRef.close();

      return;
    }

    const { questionControl, isMultipleChoiceControl, canAddOwnAnswerControl, closingInControl } = this.quickPollForm.controls;

    this.dialog.dialogRef.close({
      question: questionControl.value,
      answers: this.getAnswerValues(),
      isMultipleChoice: isMultipleChoiceControl.value,
      canAddOwnAnswer: canAddOwnAnswerControl.value,
      closing: this.closingInOptions[closingInControl.value],
    });
  }

  private getAnswerValues() {
    return (this.quickPollForm?.controls?.answersControl.value ?? []).filter((e: string) => e !== '');
  }
}
