<div *ngIf="!isCollaborationType()" class="d-flex mb-5 justify-content-between align-items-center">
  <div class="col-5">
    <app-input class="flex-grow-1" [placeholder]="getSearchTextPlaceholder()" [style]="'secondary'" [formControl]="searchControl">
      <svg-icon prefix key="search" fontSize="24px"></svg-icon>
    </app-input>
  </div>
  <div class="list d-flex flex-wrap gap-2 align-items-center">
    <span
      *ngIf="isDiscussionType()"
      role="button"
      class="d-flex tag normal"
      [class.pressed]="isTrendingSelected"
      (click)="onTrendingClick()"
    >
      <svg-icon key="fire"></svg-icon>
    </span>
    <app-tag-select
      *ngIf="!isMemberType()"
      [tags]="getTagsClone"
      [showCount]="2"
      (selectionChange)="onTagSelectionChange($event)"
      [hasHashtag]="true"
    ></app-tag-select>
    <app-profession-select
      *ngIf="isMemberType()"
      [professions]="getProfessionsClone"
      [showCount]="3"
      (selectionChange)="onProfessionSelectionChange($event)"
    >
    </app-profession-select>
    <span *ngIf="filterText !== ''" class="d-flex color-secondary text-normal-bold">
      <a class="color-primary" (click)="onFilterClick()">{{ filterText }}</a>
    </span>
  </div>
</div>
<div class="row d-flex justify-content-between align-items-center px-3 py-1-5">
  <div class="col d-flex counter color-secondary text-normal">
    <ng-container *ngIf="isDiscussionType()"> {{ listCount }} ongoing discussions </ng-container>
    <ng-container *ngIf="isCollaborationType()"> {{ listCount }} active collaborations </ng-container>
    <ng-container *ngIf="isMemberType()"> {{ listCount }} members found </ng-container>
    <ng-container *ngIf="isEventType() && listCount > 0" class="d-flex gap-3 justify-content-between">
      <div class="d-flex align-items-center gap-1">
        <svg-icon key="upcoming"></svg-icon>
        <span class="text-normal-bold"> Upcoming </span>
      </div>
      <div class="d-flex mx-1">
        <span> {{ upcomingListCount }} upcoming {{ upcomingListCount > 1 ? 'events' : 'event' }} </span>
      </div>
    </ng-container>
  </div>
  <app-selection
    class="col d-flex"
    [class.justify-content-center]="!isCollaborationType()"
    [class.justify-content-end]="isCollaborationType()"
    [formControl]="toggleControl"
    [leftSelect]="setToggleControlValueByType(type)"
    [rightSelect]="setRightSelect(type)"
    [wide]="isEventType() || isMemberType()"
  ></app-selection>
  <div *ngIf="!isCollaborationType()" class="col icons d-flex justify-content-end align-items-center color-secondary10">
    <svg-icon *ngIf="!isMemberType()" key="sort" fontSize="24px" (click)="onSortClick()"></svg-icon>
  </div>
</div>
