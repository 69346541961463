import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IllustrationType } from './illustration-type.enum';

@Component({
  selector: 'app-illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationComponent {
  @Input() public type!: IllustrationType;
  public readonly illustrationTypes = IllustrationType;
}
