import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { ToastMessageModule } from '@shared/modules/toast-message/toast-message.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DialogModule } from '@shared/modules/dialog/dialog.module';
import { Configuration } from '@hip/frontend-api';
import { CONFIG_TOKEN } from '@core/config.token';
import { Config } from '@core/types/config.interface';
import { AuthService } from '@core/services/auth.service';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { NO_ANALYTICS_TOKEN } from '@core/tokens/no-analytics.token';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { iconComponents } from './icons';
import { MockInterceptor } from './mock/mock-api.interceptor';

declare global {
  interface Window {
    isAnalyticsEnabled: () => boolean;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    ToastMessageModule.forRoot(),
    DialogModule.forRoot(),
    SvgIconsModule.forRoot({
      icons: iconComponents,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: Configuration,
      useFactory: (config: Config, authService: AuthService) =>
        new Configuration({
          basePath: config.backendRootUrl,
          accessToken: authService.getAccessToken(),
        }),
      deps: [CONFIG_TOKEN, AuthService],
    },
    {
      provide: NO_ANALYTICS_TOKEN,
      useFactory: () => typeof window.isAnalyticsEnabled === 'function' && !window.isAnalyticsEnabled(),
    },
    { provide: 'googleTagManagerId', useFactory: (config: Config) => config.googleAnalyticsId, deps: [CONFIG_TOKEN] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    environment.debug
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: MockInterceptor,
          multi: true,
        }
      : [],
  ],
})
export class AppModule {
  public constructor(private readonly translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
  }
}
