<ng-container [ngTemplateOutlet]="messageTemplate" [ngTemplateOutletContext]="{ message: vm, isReply: false }"></ng-container>

<ng-template #messageTemplate let-message="message" let-isReply="isReply">
  <div *ngIf="isReply" class="px-7">
    <app-message-separator></app-message-separator>
  </div>
  <div class="message">
    <div class="message-head d-flex justify-content-between align-items-center mb-2">
      <app-message-title [vm]="{ isMasked: message.isMasked, author: message.author }"></app-message-title>
      <div class="right d-flex align-items-center">
        <div class="info text-small-italic">
          {{ message.sentAt | dateAgo }}
        </div>
        <div class="separator vertical"></div>
        <svg-icon *ngIf="disableInteraction" [class.not-clickable]="disableInteraction" key="like" fontSize="24px"></svg-icon>
        <svg-icon
          [key]="message.isFlagged ? 'flag-on' : 'flag'"
          fontSize="24px"
          [class.flagged]="message.isFlagged"
          [class.not-clickable]="disableInteraction"
          (click)="onReportClick(message)"
        ></svg-icon>
        <button *ngIf="!isReply" class="secondary small" [class.not-clickable]="disableInteraction" (click)="toggleReplyInput()">
          <ng-container *ngIf="!isReplyInputVisible; else cancelReply">
            <svg-icon key="reply"></svg-icon>
            <span>reply</span>
          </ng-container>
          <ng-template #cancelReply>
            <svg-icon key="clear"></svg-icon>
            <span>cancel reply</span>
          </ng-template>
        </button>
      </div>
    </div>
    <div *ngIf="!message.isMasked; else maskedContent" class="message-content text-normal" [innerHtml]="message.content"></div>
    <ng-template #maskedContent>
      <div class="message-content text-normal">
        <p class="text-normal-italic">[ This message has been masked by our community managers. ]</p>
      </div>
    </ng-template>
    <div *ngIf="message.attachments?.length > 0" class="attachments d-flex align-items-center justify-content-start gap-2 flex-wrap mt-2">
      <a
        *ngFor="let attachment of message.attachments; trackBy: trackById"
        class="attachment py-2 px-2 d-inline-flex align-items-center background-white100 color-primary200"
        target="_blank"
        [href]="attachment.url"
      >
        <svg-icon key="attachment" fontSize="32px"></svg-icon>
        <span>
          {{ attachment.fileName }}
        </span>
      </a>
    </div>
    <div
      *ngIf="!message.isMasked && message.pollId"
      class="quick-polls d-flex align-items-center justify-content-start gap-2 flex-wrap mt-2"
    >
      <app-quick-poll-message
        [quickPoll]="message.quickPoll"
        (addCustomAnswer)="onAddCustomAnswer($event)"
        (answerPoll)="onAnswerPoll($event)"
      ></app-quick-poll-message>
    </div>
    <ng-container *ngIf="!isReply && isReplyInputVisible">
      <div class="separator my-2"></div>
      <app-discussion-input
        class="mt-2"
        (messageSend)="onReplySend($event)"
        [isLight]="true"
        [isPollVisible]="isPollVisible"
      ></app-discussion-input>
    </ng-container>
  </div>
  <div *ngIf="message.replies?.length > 0" class="replies">
    <ng-container *ngFor="let reply of message.replies; trackBy: trackById">
      <ng-container [ngTemplateOutlet]="messageTemplate" [ngTemplateOutletContext]="{ message: reply, isReply: true }"></ng-container>
    </ng-container>
  </div>
</ng-template>
