import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { map } from 'rxjs';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { NewsFeedItemVm } from '../news-feed-item/news-feed-item.vm';
import { NewsFeedScreenService } from './news-feed-screen.service';

@Component({
  selector: 'app-news-feed-screen',
  templateUrl: './news-feed-screen.component.html',
  styleUrls: ['./news-feed-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsFeedScreenService],
})
export class NewsFeedScreenComponent implements OnInit {
  @Input() limitedView = false;
  public vm$ = this.service.vm$.pipe(
    map((vm) => {
      if (this.limitedView) {
        return {
          newsFeed: vm?.newsFeed.slice(0, 3),
        };
      }
      return vm;
    })
  );
  constructor(
    private readonly service: NewsFeedScreenService,
    private readonly tagPusherService: TagPusherService
  ) {}

  ngOnInit(): void {}

  public measureItemVisibility(newsFeedItem: NewsFeedItemVm): void {
    this.tagPusherService.pushTag({
      event: TagEvent.FeedItemVisible,
      feed_name: newsFeedItem.title,
      feed_author: newsFeedItem.article?.author || '',
      is_it_extendable: !!newsFeedItem.article ? 1 : 0,
    });
  }

  public measureNewsFeed(newsFeedItem: NewsFeedItemVm): void {
    this.tagPusherService.pushTag({
      event: TagEvent.FeedItemRead,
      feed_name: newsFeedItem.title,
      feed_author: newsFeedItem.article?.author || '',
    });
  }
}
