<div *ngIf="data.config.closeable" class="close-icon">
  <button appDialogClose>
    <!-- Add icon here -->
  </button>
</div>
<div class="dialog-container">
  <div class="dialog-content" (appDialogScrollEnd)="onScrollEnd()">
    <ng-template cdkPortalOutlet></ng-template>
  </div>
</div>
