<label
  *ngIf="label"
  class="text-normal color-secondary mb-1"
  [class.color-textDisabled]="control?.disabled"
  [class.color-error]="hasError"
  >{{ label }}</label
>
<div
  class="input-container d-flex align-items-center px-3"
  tabindex="1"
  (focus)="onInputContainerFocus()"
  [class.disabled]="control?.disabled"
  [class.error]="control?.invalid && control?.touched"
  [class.secondary]="style === 'secondary'"
  [class.light]="isLight"
>
  <ng-content select="[prefix]"></ng-content>
  <input #input class="flex-grow-1" [formControl]="control" [type]="type ? type : 'text'" [placeholder]="placeholder" (blur)="onBlur()" />
  <ng-container *ngIf="clearable || maxLength; else suffixContent">
    <button *ngIf="clearable && control?.value" type="button" class="tiny clear-button" (click)="onClearIconClick()">
      <svg-icon key="clear"></svg-icon>
    </button>
    <span
      *ngIf="maxLength"
      class="value-length text-tiny color-secondary"
      [class.color-textDisabled]="control?.disabled"
      [class.color-error]="hasError"
    >
      {{ control?.value?.length || 0 }}/{{ maxLength }}
    </span>
  </ng-container>
  <ng-template #suffixContent><ng-content select="[suffix]"></ng-content></ng-template>
</div>
<div
  *ngIf="hint || error"
  class="hint text-tiny mt-2 px-3 color-secondary"
  [class.color-error]="hasError"
  [class.color-textDisabled]="control?.disabled"
  [innerHTML]="hasError ? error : hint"
></div>
