import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogModule } from '@shared/modules/dialog/dialog.module';
import { environment } from 'src/environments/environment';
import { SharedModule } from '@shared/shared.module';
import { AuthEffects } from '../stores/auth/auth.effects';
import * as RootStore from '../stores/root.store';
import { PlatformComponent } from './components/platform/platform.component';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';
import { ErrorScreenComponent } from './components/error-screen/error-screen.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PublicLayoutComponent } from './components/public-layout/public-layout.component';
import { PrivacyPolicyScreenComponent } from './components/privacy-policy-screen/privacy-policy-screen.component';
import { GeneralTermsScreenComponent } from './components/general-terms-screen/general-terms-screen.component';
import { NewsFeedModule } from '../news-feed/news-feed.module';

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    PlatformComponent,
    LandingScreenComponent,
    ErrorScreenComponent,
    HeaderComponent,
    FooterComponent,
    PublicLayoutComponent,
    PrivacyPolicyScreenComponent,
    GeneralTermsScreenComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    StoreModule.forRoot(RootStore.reducer, {
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([AuthEffects]),
    DialogModule.forRoot(),
    SvgIconsModule.forChild([]),
    OverlayModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NewsFeedModule,
  ],
  exports: [PlatformComponent, LandingScreenComponent, ErrorScreenComponent],
})
export class CoreModule {}
