export const appResultsPublishedIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="2" width="16" height="21" rx="2" fill="#02065B"/>
    <rect x="9" y="1" width="6" height="6" rx="1" fill="#02065B"/>
    <path d="M8 19H13" stroke="#FCA1C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 15H16" stroke="#FCA1C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 9L11 11C11 11 13.9417 7.33228 15 6" stroke="#FCA1C1" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  name: 'results-published'
};
