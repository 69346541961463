<div class="selection d-flex" [class.wide]="wide">
  <div class="bubble shadow" [class.left]="this.control?.value === leftSelect" [class.right]="this.control?.value === rightSelect"></div>
  <div class="left-select-wrapper d-flex" [class.text-small]="this.control?.value === rightSelect" [class.justify-content-center]="wide">
    <input
      type="radio"
      [id]="leftSelect"
      [name]="name"
      [value]="leftSelect"
      [checked]="this.control?.value === leftSelect"
      (click)="onValueChange($event.target)"
      hidden
    />
    <label class="px-3" #leftLabel [for]="leftSelect" [class.wide]="wide" [class.wide-label]="wide">{{ leftSelect }}</label>
  </div>

  <div class="right-select-wrapper d-flex" [class.text-small]="this.control?.value === leftSelect" [class.justify-content-center]="wide">
    <input
      type="radio"
      [id]="rightSelect"
      [name]="name"
      [value]="rightSelect"
      [checked]="this.control?.value === rightSelect"
      (click)="onValueChange($event.target)"
      hidden
    />
    <label #rightLabel [for]="rightSelect" [class.wide]="wide" [class.wide-label]="wide">{{ rightSelect }}</label>
  </div>
</div>
