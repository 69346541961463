import { appAddIcon } from './icons/add';
import { appAdd32Icon } from './icons/add-32';
import { appAddMemberIcon } from './icons/add-member';
import { appAddMember24Icon } from './icons/add-member-24';
import { appAlertIcon } from './icons/alert';
import { appAlert80Icon } from './icons/alert-80';
import { appAnalyseIcon } from './icons/analyse';
import { appArrowDownIcon } from './icons/arrow-down';
import { appArrowDown24Icon } from './icons/arrow-down-24';
import { appArrowDown32Icon } from './icons/arrow-down-32';
import { appArrowLeftIcon } from './icons/arrow-left';
import { appArrowLeft24Icon } from './icons/arrow-left-24';
import { appArrowLeft32Icon } from './icons/arrow-left-32';
import { appArrowRightIcon } from './icons/arrow-right';
import { appArrowRight24Icon } from './icons/arrow-right-24';
import { appArrowRight32Icon } from './icons/arrow-right-32';
import { appArrowUpIcon } from './icons/arrow-up';
import { appArrowUp24Icon } from './icons/arrow-up-24';
import { appArrowUp32Icon } from './icons/arrow-up-32';
import { appAttachmentIcon } from './icons/attachment';
import { appAttachment40Icon } from './icons/attachment-40';
import { appCalendarIcon } from './icons/calendar';
import { appCameraIcon } from './icons/camera';
import { appCameraAddIcon } from './icons/camera-add';
import { appCaseIcon } from './icons/case';
import { appCheckIcon } from './icons/check';
import { appCheck24Icon } from './icons/check-24';
import { appClearIcon } from './icons/clear';
import { appCollaborationsIcon } from './icons/collaborations';
import { appCollaborations24Icon } from './icons/collaborations-24';
import { appCollaborations32Icon } from './icons/collaborations-32';
import { appCollaborations40Icon } from './icons/collaborations-40';
import { appCollaborations80Icon } from './icons/collaborations-80';
import { appCrossIcon } from './icons/cross';
import { appCross80Icon } from './icons/cross-80';
import { appDiscussionIcon } from './icons/discussion';
import { appDiscussionRulesIcon } from './icons/discussion-rules';
import { appDownloadIcon } from './icons/download';
import { appEmailIcon } from './icons/email';
import { appEventIcon } from './icons/event';
import { appExperimentingIcon } from './icons/experimenting';
import { appFeedIcon } from './icons/feed';
import { appFilterIcon } from './icons/filter';
import { appFireIcon } from './icons/fire';
import { appFlagIcon } from './icons/flag';
import { appFlagOnIcon } from './icons/flag-on';
import { appGoalIcon } from './icons/goal';
import { appHidePasswordIcon } from './icons/hide-password';
import { appIdeatingIcon } from './icons/ideating';
import { appInfoIcon } from './icons/info';
import { appInfo40Icon } from './icons/info-40';
import { appInfo80Icon } from './icons/info-80';
import { appLikeIcon } from './icons/like';
import { appLinkIcon } from './icons/link';
import { appLinkedinIcon } from './icons/linkedin';
import { appLiteratureIcon } from './icons/literature';
import { appLoadingIcon } from './icons/loading';
import { appLoadingFullScreenIcon } from './icons/loading-full-screen';
import { appLocationIcon } from './icons/location';
import { appLogoIcon } from './icons/logo';
import { appLogoHorizontalIcon } from './icons/logo-horizontal';
import { appLogoVerticalIcon } from './icons/logo-vertical';
import { appMemberIcon } from './icons/member';
import { appMember24Icon } from './icons/member-24';
import { appMember40Icon } from './icons/member-40';
import { appMember80Icon } from './icons/member-80';
import { appMembersIcon } from './icons/members';
import { appMenuIcon } from './icons/menu';
import { appMinusIcon } from './icons/minus';
import { appPlaningIcon } from './icons/planing';
import { appPlayIcon } from './icons/play';
import { appPoll40Icon } from './icons/poll-40';
import { appPriceIcon } from './icons/price';
import { appRecordedIcon } from './icons/recorded';
import { appReplyIcon } from './icons/reply';
import { appReply24Icon } from './icons/reply-24';
import { appResetIcon } from './icons/reset';
import { appResultsPublishedIcon } from './icons/results-published';
import { appRocketIcon } from './icons/rocket';
import { appSearchIcon } from './icons/search';
import { appSearch40Icon } from './icons/search-40';
import { appSearch80Icon } from './icons/search-80';
import { appShowPasswordIcon } from './icons/show-password';
import { appSortIcon } from './icons/sort';
import { appSuccessIcon } from './icons/success';
import { appSuccess80Icon } from './icons/success-80';
import { appTimeIcon } from './icons/time';
import { appTime24Icon } from './icons/time-24';
import { appUpcomingIcon } from './icons/upcoming';
import { appWarningIcon } from './icons/warning';
import { appWarning40Icon } from './icons/warning-40';
import { appWarning80Icon } from './icons/warning-80';
import { appWritingIcon } from './icons/writing';
import { appWriting40Icon } from './icons/writing-40';
import { appWriting80Icon } from './icons/writing-80';

export const iconComponents = [
  appAddIcon,
  appAdd32Icon,
  appAddMemberIcon,
  appAddMember24Icon,
  appAnalyseIcon,
  appAlertIcon,
  appAlert80Icon,
  appArrowDownIcon,
  appArrowDown24Icon,
  appArrowDown32Icon,
  appArrowLeftIcon,
  appArrowLeft24Icon,
  appArrowLeft32Icon,
  appArrowRightIcon,
  appArrowRight24Icon,
  appArrowRight32Icon,
  appArrowUpIcon,
  appArrowUp24Icon,
  appArrowUp32Icon,
  appAttachmentIcon,
  appAttachment40Icon,
  appCalendarIcon,
  appCaseIcon,
  appCheckIcon,
  appCheck24Icon,
  appClearIcon,
  appCollaborationsIcon,
  appCollaborations24Icon,
  appCollaborations32Icon,
  appCollaborations40Icon,
  appCollaborations80Icon,
  appCrossIcon,
  appCross80Icon,
  appDiscussionIcon,
  appDiscussionRulesIcon,
  appDownloadIcon,
  appEmailIcon,
  appEventIcon,
  appExperimentingIcon,
  appFeedIcon,
  appFilterIcon,
  appFireIcon,
  appFlagIcon,
  appFlagOnIcon,
  appGoalIcon,
  appHidePasswordIcon,
  appIdeatingIcon,
  appInfoIcon,
  appInfo40Icon,
  appInfo80Icon,
  appLinkIcon,
  appLikeIcon,
  appLinkedinIcon,
  appLiteratureIcon,
  appLoadingIcon,
  appLogoHorizontalIcon,
  appLogoVerticalIcon,
  appLoadingFullScreenIcon,
  appLocationIcon,
  appMemberIcon,
  appMember24Icon,
  appMember40Icon,
  appMember80Icon,
  appMembersIcon,
  appMenuIcon,
  appUpcomingIcon,
  appPlayIcon,
  appPlaningIcon,
  appPriceIcon,
  appPoll40Icon,
  appReplyIcon,
  appReply24Icon,
  appRecordedIcon,
  appResetIcon,
  appResultsPublishedIcon,
  appRocketIcon,
  appSearchIcon,
  appSearch40Icon,
  appSearch80Icon,
  appShowPasswordIcon,
  appSortIcon,
  appSuccessIcon,
  appSuccess80Icon,
  appTimeIcon,
  appTime24Icon,
  appWarningIcon,
  appWarning40Icon,
  appWarning80Icon,
  appWritingIcon,
  appWriting40Icon,
  appWriting80Icon,
  appMinusIcon,
  appCameraIcon,
  appCameraAddIcon,
  appLogoIcon,
];
