import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CollaborationStatus } from 'src/domain/collaboration-status';

@Component({
  selector: 'app-collaboration-status',
  templateUrl: './collaboration-status.component.html',
  styleUrls: ['./collaboration-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaborationStatusComponent {
  @Input() public status = '';

  public cssClassStatusMap: Map<string, string> = new Map();
  public iconStatusMap: Map<string, string> = new Map();

  public constructor() {
    this.cssClassStatusMap.set(CollaborationStatus.Ideating, 'ideating');
    this.cssClassStatusMap.set(CollaborationStatus.Planing, 'planing');
    this.cssClassStatusMap.set(CollaborationStatus.LiteratureReview, 'literature');
    this.cssClassStatusMap.set(CollaborationStatus.Experimenting, 'experimenting');
    this.cssClassStatusMap.set(CollaborationStatus.AnalysingData, 'analysing-data');
    this.cssClassStatusMap.set(CollaborationStatus.ResultsPublished, 'results-published');

    this.iconStatusMap.set(CollaborationStatus.Ideating, 'ideating');
    this.iconStatusMap.set(CollaborationStatus.Planing, 'planing');
    this.iconStatusMap.set(CollaborationStatus.LiteratureReview, 'literature');
    this.iconStatusMap.set(CollaborationStatus.Experimenting, 'experimenting');
    this.iconStatusMap.set(CollaborationStatus.AnalysingData, 'analyse');
    this.iconStatusMap.set(CollaborationStatus.ResultsPublished, 'results-published');
  }

  public getStatusClass(): string {
    return this.cssClassStatusMap.get(this.status) ?? '';
  }

  public getStatusIcon(): string {
    return this.iconStatusMap.get(this.status) ?? '';
  }
}
