<div class="color-secondary">
  <h1 class="title-h1 mb-6">Privacy Policy</h1>

  <div><div><div>
    <div>
      <p class="content"><span>Welcome to Healthcare Innovation Platform’s privacy policy.</span></p>
    </div>
    <div>
      <p >Healthcare Innovation Platform respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
      <p>Last update: Jan 5, 2023</p>
    </div>
    <div>
      <h3 class="subheading">Important information and who we are</h3>
      <ul class="details-list">
        <li>
          <h5 class="heading">Purpose of this privacy policy</h5>
          <div class="description">This privacy policy aims to give you information on how Healthcare Innovation Platform collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up for our newsletter.</div>
          <div class="description">This website is not intended for children and we do not knowingly collect data relating to children.</div>
          <div class="description">It is important that you read this privacy policy together with any other privacy policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.</div>
        </li>
        <li>
          <h5 class="heading">Controller</h5>
          <div class="description">This privacy policy is issued on behalf of the Healthcare Innovation Platform Group so when we mention Healthcare Innovation Platform, “we”, “us” or “our” in this privacy policy, we are referring to the relevant company in the Healthcare Innovation Platform Group responsible for processing your data.</div>
          <div class="description">We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</div>
        </li>
        <li>
          <h5 class="heading">Contact details</h5>
          <div class="description"><strong>Email address:</strong> hello@supercharge.io</div>
          <div class="description"><strong>Postal address:</strong> 1075 Budapest Károly körút 9</div>
          <div class="description">You have the right to make a complaint at any time to the Hungarian Data Protection and Freedom of Information Authority, the Hungarian supervisory authority for data protection issues (www.naih.hu). We would, however, appreciate the chance to deal with your concerns before you approach the Authority so please contact us in the first instance.</div>
        </li>
        <li>
          <h5 class="heading">Changes to the privacy policy and your duty to inform us of changes</h5>
          <div class="description">We keep our privacy policy under regular review.</div>
          <div class="description">It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</div>
        </li>
        <li>
          <h5 class="heading">Third-party links</h5>
          <div class="description">This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you.</div>
          <p>We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
        </li>
      </ul>
      <h3></h3>
      <h3>The data we collect about you</h3>
      <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
      <p class="paragraph">We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
      <ul class="details-list">
        <li><strong>Identity Data: </strong>Includes name, employer data.</li>
        <li><strong>Contact Data:</strong> includes email address.</li>
        <li><strong>Technical Data:</strong> includes internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
        <li><strong>Usage Data:</strong> includes information about how you use our website.</li>
        <li><strong>Aggregated Data: </strong>such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</li>
      </ul>
      <p>&nbsp;</p>
      <p class="paragraph">We do not collect any <strong>Special Categories of Personal Data&nbsp;</strong>about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
      <p>&nbsp;</p>
      <h3>How is your personal data collected?</h3>
      <div class="paragraph">
        <p class="content">We use different methods to collect data from and about you including through:</p>
      </div>
      <ul class="details-list">
        <li>
          <h5 class="heading">Direct interactions:</h5>
          <div class="description">You may give us your Contact Data by corresponding with us by post, phone, email or otherwise. This includes the personal data you provide when you give us feedback or contact us on our website through contact forms.</div>
        </li>
        <li>
          <h5 class="heading">Automated technologies or interactions:</h5>
          <div class="description">As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. Please see our&nbsp;<strong>cookie policy&nbsp;</strong>below for further details.</div>
        </li>
        <li>
          <h5 class="heading">Third parties or publicly available sources</h5>
          <p class="description">We will receive personal data about you from analytics providers such as Google.</p>
        </li>
      </ul>
      <h3 class="description">How we use your personal data?</h3>
      <div class="paragraph">
        <p class="content">We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
      </div>
      <ul class="details-list">
        <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
        <li>Where we need to comply with a legal obligation.</li>
      </ul>
      <p class="content">Generally, we do not rely on consent as a legal basis for processing your personal data.</p>
      <p>&nbsp;</p>
      <h3>Purposes for which we will use your personal data</h3>
      <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
      <p class="content">Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
      <p>&nbsp;</p>
      <table class="privacy-data">
        <tbody>
        <tr class="header">
          <td><strong>Purpose/Activity</strong></td>
          <td><strong>Type of data</strong></td>
          <td><strong>Lawful basis</strong></td>
        </tr>
        <tr class="first-row">
          <td>To keep in touch with you as our customer or jobseeker</td>
          <td>Contact</td>
          <td>Performance of a contract with you</td>
        </tr>
        <tr>
          <td>To manage our relationship with you which will include:
            <p>(a) Notifying you about changes to our terms or privacy policy</p>
            <p>(b) Asking you to leave a review or take a survey</p>
          </td>
          <td>Identity
            <p>Contact</p>
          </td>
          <td>(a) Performance of a contract with you
            <p>(b) Necessary to comply with a legal obligation</p>
            <p>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</p>
          </td>
        </tr>
        <tr>
          <td>To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
          <td>(a) Contact
            <p>(b) Technical</p>
          </td>
          <td>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
            <p>(b) Necessary to comply with a legal obligation</p>
          </td>
        </tr>
        <tr>
          <td>To deliver relevant website content to you and measure or understand the effectiveness of our website</td>
          <td>(a) Contact
            <p>(b) Usage</p>
            <p>(c) Technical</p>
          </td>
          <td>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</td>
        </tr>
        <tr>
          <td>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
          <td>(a) Technical
            <p>(b) Usage</p>
          </td>
          <td>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
        </tr>
        <tr class="last-row">
          <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
          <td>(a) Contact
            <p>(b) Technical</p>
            <p>(c) Usage</p>
          </td>
          <td>Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
        </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <ul class="details-list">
        <li>
          <h5 class="heading">Marketing</h5>
          <div class="content">We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.</div>
          <div class="content">You can ask us to stop sending you marketing messages at any time by contacting us at any time.</div>
        </li>
        <li>
          <h5>HR</h5>
          <p>In case you would not like to be contacted with job opportunities that we believe to be a good fit for you after a closed application process, you can ask us to delete all your data and not to reach out to you.</p>
        </li>
        <li>
          <h5 class="heading">Cookies</h5>
          <div class="content">You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see ourPlease see our&nbsp;<strong>cookie policy&nbsp;</strong>below for further details.</div>
        </li>
        <li>
          <h5 class="heading">Change of purpose</h5>
          <div class="content">We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</div>
          <div class="content">If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</div>
          <div class="content">Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</div>
        </li>
      </ul>
      <div class="subheading">
        <h3 class="content"><strong>Disclosures of your personal data</strong></h3>
        <p class="content">We may share your personal data with the parties set out below for the purposes set out in the table [Purposes for which we will use your personal data] above.</p>
      </div>
      <ul class="details-list">
        <li>
          <div class="headlesslistelement">Internal Third Parties as set out in the Glossary below.</div>
        </li>
        <li>
          <div class="headlesslistelement">External Third Parties as set out in the Glossary below.</div>
        </li>
        <li>
          <div class="headlesslistelement">Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</div>
        </li>
      </ul>
      <div class="paragraph">
        <p class="content">We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
        <p>&nbsp;</p>
        <h3 class="content"><strong>International transfers</strong></h3>
      </div>
      <div class="paragraph">
        <p class="content">We do not transfer your personal data outside the European Economic Area (&nbsp;<strong>EEA&nbsp;</strong>).</p>
        <p>&nbsp;</p>
        <h3 class="content"><strong>Data security</strong></h3>
      </div>
      <div class="paragraph">
        <p class="content">We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
        <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
        <p>&nbsp;</p>
        <h3 class="content"><strong>Data retention</strong></h3>
      </div>
      <ul class="details-list">
        <li>
          <p class="heading"><strong>How long will you use my personal data for?</strong></p>
          <p class="content">We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
          <p class="content">To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
          <p class="content">In some circumstances you can ask us to delete your data: see your legal rights below for further information.</p>
          <p class="content">In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
          <p class="content">If you wish to exercise any of the rights set out above, please contact us.</p>
        </li>
        <li>
          <p class="heading"><strong>No fee usually required</strong></p>
          <p class="content">You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
        </li>
        <li>
          <p class="heading"><strong>What we may need from you</strong></p>
          <p class="content">We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
        </li>
        <li>
          <p class="heading"><strong>Time limit to respond</strong></p>
          <div class="content">We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</div>
        </li>
      </ul>
      <h3>Glossary</h3>
      <div class="subsubheading">
        <p class="content"><strong>Lawful Basis:</strong></p>
        <p class="content"><strong>Legitimate Interest</strong><span> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</span></p>
      </div>
      <div class="paragraph">
        <p class="content"><strong>Performance of Contract</strong>&nbsp;means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
      </div>
      <p class="paragraph"><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>
      <h4 class="paragraph">Third Parties</h4>
      <p class="paragraph"><strong>Internal Third Parties</strong></p>
      <div class="paragraph">
        <p>Other companies in the Healthcare Innovation Platform Group acting as joint controllers.</p>
        <p><strong>External Third Parties</strong></p>
      </div>
      <div class="paragraph">
        <ul class="details-list">
          <li>
            <div class="headlesslistelement">Service providers acting as processors, who provide software development, IT and system administration services.</div>
          </li>
          <li>
            <div class="headlesslistelement">Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors, insurers and recruitment agencies based in Hungary, the UK and the Netherlands, who provide consultancy, banking, legal, insurance, accounting and recruitment services.</div>
          </li>
        </ul>
        <p>On behalf of the Company the above listed cloud-based processors manage personal data:</p>
        <table dir="ltr" border="1" width="841" cellspacing="0" cellpadding="0">
          <colgroup>
            <col width="279">
            <col width="267">
            <col width="293">
          </colgroup>
          <tbody>
          <tr>
            <td><strong>Third parties</strong></td>
            <td><strong>Tasks of processor</strong></td>
            <td><strong>Managed personal data</strong></td>
          </tr>
          <tr>
            <td><a class="in-cell-link" href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">Google</a></td>
            <td>Cookie and analytics services</td>
            <td>
              <div>
                <div>Personal data by Google Analytics <a href="https://policies.google.com/privacy?hl=en-US"><strong>Privacy Policy</strong></a></div>
              </div>
            </td>
          </tr>
          <tr>
            <td><a class="in-cell-link" href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">LinkedIn </a></td>
            <td>Business and employment-oriented online service</td>
            <td>
              <div>
                <div>Personal Curriculum Vitae files, Personal data by Linkedin <strong><a href="https://www.linkedin.com/legal/privacy-policy">Privacy Policy</a></strong></div>
              </div>
            </td>
          </tr>
          <tr>
            <td><a class="in-cell-link" href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">Facebook </a></td>
            <td>Business online services</td>
            <td>Personal data by Facebook <a href="https://www.facebook.com/policy.php"><strong>Privacy Policy</strong></a></td>
          </tr>
          <tr>
            <td><a href="https://www.datocms.com/legal/gdpr">Dato</a></td>
            <td>Website builder platform</td>
            <td>Personal data by Dato <a href="https://www.datocms.com/legal/gdpr" target="_blank" rel="noopener"><strong>Privacy Policy</strong></a></td>
          </tr>
          <tr>
            <td><a href="https://clarity.microsoft.com/terms">Microsoft Clarity</a></td>
            <td>Analytics services</td>
            <td>Personal data by Microsoft Clarity <strong><a href="https://clarity.microsoft.com/terms">Privacy Policy</a></strong></td>
          </tr>
          <tr>
            <td><a class="in-cell-link" href="http://Dreamjo.bs" target="_blank" rel="noopener noreferrer">Dreamjo.bs </a></td>
            <td>Job portal</td>
            <td>Personal Curriculum Vitae files</td>
          </tr>
          <tr>
            <td><a href="https://mailchimp.com/legal/" target="_blank" rel="noopener">Mailchimp</a></td>
            <td>Mailing software</td>
            <td>Personal data by <strong><a href="https://mailchimp.com/legal/">Mailchimp Privacy Policy</a></strong></td>
          </tr>
          <tr>
            <td>HubSpot</td>
            <td>CRM Software</td>
            <td>Personal data by <strong><a href="https://legal.hubspot.com/privacy-policy">HubSpot Privacy Policy</a></strong></td>
          </tr>
          </tbody>
        </table>
        <p class=""></p>
        <p class="">The data controlling’s procedure of processors detailed rules are included in the concerned’s own Privacy Policy or Cookie Policy which are available on its webpage.</p>
        <h3 class="headlesslistelement">Your Legal Rights</h3>
      </div>
      <div class="subsubheading">
        <p class="content">You have the right to:</p>
      </div>
      <ul>
        <li class="content"><strong>Request access</strong> to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
        <li class="content"><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
        <li class="content"><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
        <li class="content"><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
        <li class="content"><strong>Request restriction</strong> of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
          <ul>
            <li class="content">If you want us to establish the data’s accuracy.</li>
            <li class="content">Where our use of the data is unlawful but you do not want us to erase it.</li>
            <li class="content">Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
            <li class="content">You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <ul>
        <li class="content"><strong>Request the transfer</strong>&nbsp;of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
        <li class="paragraph">
          <p class="content"><strong>Withdraw consent</strong>&nbsp;at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
        </li>
      </ul>
    </div>
  </div></div></div>

  <h1>Cookie policy</h1>
  <div class="policy__body"><div><div>
    <div>
      <div>
        <div class="content">
          <div class="first-line">
            <div>
              <div>
                <div>
                  <div class="content">
                    <h2>Information about our use of cookies</h2>
                    <p>Last update Jan 13, 2023</p>
                  </div>
                </div>
                <div class="paragraph">
                  <p class="content">Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
                  <p class="content">A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
                </div>
                <div>
                  <h5 class="subheading">We use the following cookies:</h5>
                  <ul class="cookie-list">
                    <li>
                      <p class="heading"><strong>Strictly necessary cookies</strong></p>
                      <p class="description">These are cookies that are required for the operation of our website.</p>
                    </li>
                    <li>
                      <p class="heading"><strong>Analytical/performance cookies</strong></p>
                      <p class="description">They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                    </li>
                    <li>
                      <p class="heading"><strong>Functionality cookies</strong></p>
                      <p class="description">These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                    </li>
                    <li>
                      <p class="heading"><strong>Targeting cookies</strong></p>
                      <p class="description">These cookies record your visit to our website, the pages you have visited and the links you have followed.</p>
                    </li>
                  </ul>
                  <div class="paragraph">
                    <p class="content">You can find more information about the individual cookies we use and the purposes for which we use them in the table below:</p>
                    <div>
                      <div>
                        <div>
                          <div class="paragraph">
                            <p></p>
                            <p class="content">


                            </p><table cellspacing="0" cellpadding="0" dir="ltr" border="1">
                            <colgroup>
                              <col width="100">
                              <col width="387">
                              <col width="219">
                            </colgroup>
                            <tbody>
                            <tr>
                              <td>Cookie</td>
                              <td>Purpose</td>
                              <td>Expiration time</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#analyticsjs" target="_blank" rel="noopener">_ga</a></td>
                              <td>Google cookie used to distinguish users.</td>
                              <td>2 years</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#analyticsjs" target="_blank" rel="noopener">_gat</a></td>
                              <td>Google cookie used to throttle request rate. If Google Analytics is deployed via Google Tag Manager, this cookie will be named _dc_gtm_.</td>
                              <td>1 minute</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#analyticsjs" target="_blank" rel="noopener">_gid</a></td>
                              <td>Google cookie used to distinguish users.</td>
                              <td>24 hours</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://www.facebook.com/policies/cookies" target="_blank" rel="noopener">_fbp</a></td>
                              <td>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers</td>
                              <td>180 days</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://www.leadfeeder.com/cookies-and-tracking/" target="_blank" rel="noopener">_lfa</a></td>
                              <td>Used by Leadfeeder to to store and track audience reach.</td>
                              <td>2 years</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="noopener">li_at</a></td>
                              <td>Used by Linkedin to authenticate Members and API clients</td>
                              <td>1 year</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">_clck</a></td>
                              <td>This cookie is installed by Microsoft Clarity to store information of how visitors use a website and help in creating an analytics report of how the website is doing. The data collected includes the number of visitors, the source where they have come from, and the pages visited in an anonymous form.</td>
                              <td>1 year</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">_clsk</a></td>
                              <td>This cookie is installed by Microsoft Clarity to store information of how visitors use a website and help in creating an analytics report of how the website is doing.</td>
                              <td>1 day</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">CLID</a></td>
                              <td>This cookie is installed by Microsoft Clarity and stores information about how visitors use the website. The cookie contributes to the creation of an analysis report. The data collection includes numbers of visitors, where they visit the website from and pages visited on the website.</td>
                              <td>1 year</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">ANONCHK</a></td>
                              <td>This cookie is installed by Microsoft Clarity to store information of how visitors use a website and help in creating an analytics report of how the website is doing. The data collected including the number visitors, the source where they have come from, and the pages visited in an anonymous form.</td>
                              <td>10 minutes</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">MR</a></td>
                              <td>Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.</td>
                              <td>1 year</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">MUID</a></td>
                              <td>Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.</td>
                              <td>1 year</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://docs.microsoft.com/en-us/clarity/cookie-list" target="_blank" rel="noopener">SM</a></td>
                              <td>Used by Microsoft’s Clarity analytics service to synchronise the MUID across Microsoft domains.</td>
                              <td>end of session</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hs_opt_out</a></td>
                              <td>This cookie is used by the opt-in privacy policy to remember not to ask the visitor to accept cookies again. This cookie is set when you give visitors the choice to opt out of cookies.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hs_do_not_track</a></td>
                              <td>This cookie can be set to prevent the tracking code from sending any information to HubSpot.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hs_initial_opt_in</a></td>
                              <td>This cookie is used to prevent the banner from always displaying when visitors are browsing in strict mode.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hs_cookie_cat_pref</a></td>
                              <td>This cookie is used to record the categories a visitor consented to.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hs_ab_test </a></td>
                              <td>This cookie is used to consistently serve visitors the same version of an A/B test page they’ve seen before. It contains the id of the A/B test page and the id of the variation that was chosen for the visitor.</td>
                              <td>end of session</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">&lt;id&gt;_key</a></td>
                              <td>When visiting a password-protected page, this cookie is set so future visits to the page from the same browser do not require login again. The cookie name is unique for each password-protected page. It contains an encrypted version of the password so future visits to the page will not require the password again.</td>
                              <td>14 days</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hs-messages-is-open</a></td>
                              <td>This cookie is used to determine and save whether the chat widget is open for future visits. It is set in your visitor's browser when they start a new chat, and resets to re-close the widget after 30 minutes of inactivity.</td>
                              <td>30 mins</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hs-messages-hide-welcome-message</a></td>
                              <td>This cookie is used to prevent the chat widget welcome message from appearing again for one day after it is dismissed.</td>
                              <td>1 day</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hsmem</a></td>
                              <td>This cookie is set when visitors log in to a HubSpot-hosted site. It contains encrypted data that identifies the membership user when they are currently logged in.</td>
                              <td>7 days</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hs-membership-csrf</a></td>
                              <td>This cookie is used to ensure that content membership logins cannot be forged. It contains a random string of letters and numbers used to verify that a membership login is authentic.</td>
                              <td>end of session</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hs_langswitcher_choice</a></td>
                              <td>This cookie is used to save a visitor’s selected language choice when viewing pages in multiple languages. It is set when a visitor selects a language from the language switcher and is used as a language preference to redirect them to sites in their chosen language in the future if they are available.</td>
                              <td>2 years</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__cfruid</a></td>
                              <td>This cookie is set by HubSpot’s CDN provider because of their rate limiting policies. It expires at the end of the session. Learn more about Cloudflare cookies.</td>
                              <td>end of session</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__cf_bm</a></td>
                              <td>This cookie is set by HubSpot's CDN provider and is a necessary cookie for bot protection. It expires in 30 minutes. Learn more about Cloudflare cookies.</td>
                              <td>30 mins</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hstc</a></td>
                              <td>The main cookie for tracking visitors. It contains the domain, utk, initial timestamp (first visit), last timestamp (last visit), current timestamp (this visit), and session number (increments for each subsequent session).</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">hubspotutk</a></td>
                              <td>This cookie keeps track of a visitor's identity. It is passed to HubSpot on form submission and used when deduplicating contacts. It contains an opaque GUID to represent the current visitor.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hssc</a></td>
                              <td>This cookie keeps track of sessions. This is used to determine if HubSpot should increment the session number and timestamps in the __hstc cookie.</td>
                              <td>30 mins</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">__hssrc</a></td>
                              <td>Whenever HubSpot changes the session cookie, this cookie is also set to determine if the visitor has restarted their browser. If this cookie does not exist when HubSpot manages cookies, it is considered a new session.</td>
                              <td>end of session</td>
                            </tr>
                            <tr>
                              <td><a class="in-cell-link" href="https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser?__hstc=66069873.276106e3b93520dce3250ca4e5067187.1679404337883.1679404337883.1679407939024.2&amp;__hssc=66069873.1.1679407939024&amp;__hsfp=2695420997" target="_blank" rel="noopener">messagesUtk</a></td>
                              <td>This cookie is used to recognize visitors who chat with you via the chatflows tool. If the visitor leaves your site before they're added as a contact, they will have this cookie associated with their browser.</td>
                              <td>6 months</td>
                            </tr>
                            <tr>
                              <td>consentAgreed</td>
                              <td>Detect if user accepted the cookie policy.</td>
                              <td>end of session</td>
                            </tr>
                            </tbody>
                          </table>
                            You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <p>&nbsp;</p></div></div>

  <button class="mt-8 primary large" [routerLink]="['/']">back to landing page</button>
</div>
