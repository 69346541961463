<footer [class.dark]="isDark">
  <div class="inner">
    <button class="to-top small white" (click)="onScrollTopClick()">
      <svg-icon key="arrow-up" fontSize="24px"></svg-icon>
      <span>go top</span>
    </button>
    <div class="d-flex position-relative justify-content-between align-items-center color-white100">
      <svg-icon key="logo" fontSize="108px"></svg-icon>
      <div class="text-large position-absolute d-flex flex-column justify-content-center align-items-center w-100 d-none">
        <p class="mb-3">Do you have a question?</p>
        <p class="mb-0"><a class="footer-link text-uppercase" href="mailto:admin@healthcareinnovationplatform.org">Contact us</a></p>
      </div>
    </div>
    <div class="separator mt-10 mb-5 opacity-25"></div>
    <div class="d-flex gap-10 align-items-center">
      <!--<div class="icon-links d-flex gap-5">
        <a class="icon instagram" href="https://instagram.com" target="_blank"></a>
        <a class="icon twitter" href="https://twitter.com" target="_blank"></a>
        <a class="icon linkedin" href="https://linkedin.com" target="_blank"></a>
      </div>-->
      <div class="copyright flex-grow-1 color-secondary40">Copyright © {{ year }} All rights reserved.</div>
      <div class="right-links d-flex gap-8">
        <a class="footer-link d-none" [routerLink]="['/', 'general-terms-and-conditions']">General Terms and Conditions</a>
        <a class="footer-link" [routerLink]="['/', 'privacy-policy']">Privacy Policy</a>
      </div>
    </div>
  </div>
</footer>
