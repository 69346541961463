import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { ProfileBioSectionVM } from './profile-bio-section.vm';

@Component({
  selector: 'app-profile-bio-section',
  templateUrl: './profile-bio-section.component.html',
  styleUrls: ['./profile-bio-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileBioSectionComponent {
  @Input() public vm!: ProfileBioSectionVM;

  @Output() public uploadImageButtonClick = new EventEmitter<MouseEvent>();
  @Output() public followButtonClick = new EventEmitter<MouseEvent>();
  @Output() public newBioSubmit = new EventEmitter<string>();

  // if more than 255 is needed backend also needs to be updated
  public readonly BIO_MAX_LENGTH = 255;

  public editBioMode = false;

  public bioFormGroup!: FormGroup<{ bio: string }>;

  constructor(private readonly formBuilder: FormBuilder) {}

  public getBioErrorMessage(): string {
    return `Maximum ${this.BIO_MAX_LENGTH} characters are allowed`;
  }

  public enterEditBioMode(): void {
    this.editBioMode = true;
    this.initializeBioFormGroup();
  }

  public saveBioChanges(): void {
    if (this.bioFormGroup.valid) {
      if (this.bioFormGroup.touched) {
        this.newBioSubmit.emit(this.bioFormGroup.value.bio);
      }

      this.editBioMode = false;
    } else {
      this.bioFormGroup.markAllAsTouched();
    }
  }

  public cancelBioChanges(): void {
    this.editBioMode = false;
  }

  private initializeBioFormGroup(): void {
    this.bioFormGroup = this.formBuilder.group({
      bio: [this.vm.bio ?? '', [Validators.maxLength(this.BIO_MAX_LENGTH)]],
    });
  }
}
