import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IllustrationType } from '@shared/components/illustration/illustration-type.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { cardDemo } from './discussion-card-demo';

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingScreenComponent {
  public readonly illustrationTypes = IllustrationType;
  public readonly cardDemo = cardDemo;
  public readonly signupSteps = ['Member_Step_Signup', 'Member_Step_Topics', 'Member_Step_Participate'];
  public readonly TagEvent = TagEvent;

  public navigateToWebinar() {
    window.open(`http://eepurl.com/il4x9E`, '_blank');
  }
}
