import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { isBefore } from 'date-fns';

@Component({
  selector: 'app-event-date',
  templateUrl: './event-date.component.html',
  styleUrls: ['./event-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDateComponent {
  @Input() public date!: string;

  public isUpcoming(): boolean {
    return isBefore(new Date(), new Date(this.date));
  }
}
