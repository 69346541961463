<h1 class="title-h1 color-secondary mb-5">Filter {{ getDialogTitle() }}</h1>
<div class="tags mb-6">
  <div class="head d-flex justify-content-between align-items-center mb-3">
    <div class="left d-flex gap-3 align-items-center">
      <h2 *ngIf="!isMemberType" class="title-h2 color-secondary mb-0">Topics</h2>
      <h2 *ngIf="isMemberType" class="title-h2 color-secondary mb-0">Professions</h2>
      <span *ngIf="selectedTagsCount > 0" class="text-normal color-secondary">{{ selectedTagsCount }} selected</span>
    </div>
    <div class="right d-flex gap-2">
      <button class="secondary small" (click)="onSelectAllTagClick()">select all</button>
      <button class="secondary small" (click)="onClearAllTagClick()">clear</button>
    </div>
  </div>
  <div class="separator"></div>
  <app-tag-select *ngIf="!isMemberType" class="mt-3" [tags]="tags"></app-tag-select>
  <app-profession-select *ngIf="isMemberType && professions" class="mt-3" [professions]="professions"></app-profession-select>
</div>
<div class="actions d-flex gap-2">
  <button class="primary large flex-grow-1" (click)="onFilterClose(true)">apply filters</button>
  <button class="secondary large" (click)="onFilterClose(false)">cancel</button>
</div>
