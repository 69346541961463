import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, take } from 'rxjs/operators';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogOverlayComponent } from './components/dialog-overlay/dialog-overlay.component';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from './dialog.tokens';
import { DialogType } from './enums/dialog-type.enum';
import { DialogConfig } from './interfaces/dialog-config.interface';
import { DialogData } from './interfaces/dialog-data.interface';

@Injectable()
export class DialogService {
  private backdropOverlayRef?: OverlayRef;
  private readonly openDialogRefs$ = new BehaviorSubject<DialogRef<unknown>[]>([]);
  private readonly defaultConfig: DialogConfig = {
    width: '1066px',
    closeable: true,
    closeOnEscape: true,
    closeOnNavigation: false,
    dialogType: DialogType.Default,
  };

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly router: Router
  ) {
    this.handleKeyboardEvents();
  }

  public open<Payload = undefined, Result = undefined>(
    templateOrComponentRef: TemplateRef<unknown> | ComponentType<unknown>,
    payload?: Payload,
    config?: DialogConfig
  ): DialogRef<Result> {
    const configWithDefaults: DialogConfig = { ...this.defaultConfig, injector: this.injector, ...config };

    if (!this.backdropOverlayRef) {
      this.backdropOverlayRef = this.createBackdropOverlayRef();
      this.backdropOverlayRef.attach(new ComponentPortal(DialogOverlayComponent));
    }

    if (config?.preventScrollOffsetSaving) {
      this.document.documentElement.classList.add('cdk-global-scrollblock-prevent-offset');
    }

    // Hide currently visible dialog if any.
    const topOpenDialogRef = this.openDialogRefs$.value[this.openDialogRefs$.value.length - 1];
    if (topOpenDialogRef) {
      topOpenDialogRef.hide();
    }

    const dialogRef = new DialogRef<Result>(configWithDefaults);
    const injector = this.createInjector(dialogRef, payload, configWithDefaults);

    const dialogOverlayRef = this.createDialogOverlayRef();
    const dialogContainer = dialogOverlayRef.attach(new ComponentPortal(DialogContainerComponent, undefined, injector));

    if (templateOrComponentRef instanceof TemplateRef) {
      dialogContainer.instance.attachTemplatePortal(new TemplatePortal(templateOrComponentRef, undefined as never, injector));
    } else {
      dialogContainer.instance.attachComponentPortal(new ComponentPortal(templateOrComponentRef, undefined, injector));
    }

    this.openDialogRefs$.next([...this.openDialogRefs$.getValue(), dialogRef as DialogRef<unknown>]);

    if (configWithDefaults.closeOnNavigation) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1),
          takeUntil(dialogRef.afterClosed$())
        )
        .subscribe(() => dialogRef.close());
    }

    dialogRef.afterClosed$().subscribe(() => {
      dialogOverlayRef.detach();
      if (this.backdropOverlayRef) {
        this.backdropOverlayRef.detach();
        this.backdropOverlayRef = undefined;
      }
      this.openDialogRefs$.next(this.openDialogRefs$.getValue().filter((openDialogRef) => openDialogRef !== dialogRef));

      // tslint:disable-next-line: early-exit
      if (this.openDialogRefs$.value.length === 0) {
        this.document.documentElement.classList.remove('cdk-global-scrollblock-prevent-offset');
      } else {
        topOpenDialogRef.show();
      }
    });

    if (configWithDefaults.closeable) {
      dialogOverlayRef
        .backdropClick()
        .pipe(takeUntil(dialogRef.afterClosed$()))
        .subscribe(() => dialogRef.close());
    }

    return dialogRef;
  }

  public hasOpenDialog$(): Observable<boolean> {
    return this.openDialogRefs$.pipe(map((openDialogRefs) => openDialogRefs.length > 0));
  }

  private handleKeyboardEvents(): void {
    this.openDialogRefs$
      .pipe(
        switchMap((openDialogRefs) => {
          if (openDialogRefs.length === 0) {
            return EMPTY;
          }

          const topDialogConfig = openDialogRefs[openDialogRefs.length - 1].config;
          if (!topDialogConfig.closeable || !topDialogConfig.closeOnEscape) {
            return EMPTY;
          }

          return fromEvent<KeyboardEvent>(document, 'keyup').pipe(filter((event) => event.key === 'Escape'));
        })
      )
      .subscribe(() => {
        const topDialogRef = this.openDialogRefs$.getValue()[this.openDialogRefs$.getValue().length - 1];
        topDialogRef.close();
      });
  }

  private createBackdropOverlayRef(): OverlayRef {
    return this.overlay.create({
      // It's handled by BlockScrollService.
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
    });
  }

  private createDialogOverlayRef(): OverlayRef {
    const baseOverlayConfig = {
      // It's handled by BlockScrollService.
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      hasBackdrop: true,
      backdropClass: '',
    };
    return this.overlay.create({
      ...baseOverlayConfig,
      maxHeight: 'calc(100vh - 4rem)',
      positionStrategy: this.getPositionStrategy(),
    });
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay.position().global().centerVertically().centerHorizontally();
  }

  private createInjector<Payload, Result>(dialogRef: DialogRef<Result>, payload: Payload, config: DialogConfig): Injector {
    const data: DialogData<Payload, Result> = { dialogRef, config, payload };

    return Injector.create({
      providers: [{ provide: DIALOG_DATA, useValue: data }],
      parent: config.injector,
    });
  }
}
