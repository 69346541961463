import { Injectable } from '@angular/core';

import { NewsfeedDataService, PublicNewsFeedItem } from '@hip/frontend-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';

import { getNewsFeed, getNewsFeedError, getNewsFeedSuccess } from './news-feed.actions';
import { NewsFeedItem } from 'src/domain/news-feed-item';

@Injectable()
export class NewsFeedEffects {
  public getNewsFeed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNewsFeed),
      switchMap(() =>
        this.newsFeedDataService.getPublishedNewsFeedList().pipe(
          map((response) => getNewsFeedSuccess({ items: this.mapNewsFeedListResponse(response) })),
          catchError((error) => of(getNewsFeedError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  public constructor(private readonly actions$: Actions, private readonly newsFeedDataService: NewsfeedDataService) {}

  private mapNewsFeedListResponse(response: PublicNewsFeedItem[]): NewsFeedItem[] {
    return response.map((item) => ({
      id: item.id,
      titleNl: item.titleNl,
      titleEn: item.titleEn,
      descriptionNl: item.bodyNl,
      descriptionEn: item.bodyEn,
      imageDesktopUrl: item.imageDesktopUrl,
      imageMobileUrl: item.imageMobileUrl,
      article: item.articleSlug
        ? { slug: item.articleSlug, author: item.articleAuthor, publishDate: item.articlePublishedAt, id: item.articleId }
        : undefined,
    }));
  }
}
