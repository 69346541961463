export enum IllustrationType {
  Password = 'password',
  Welcome = 'welcome',
  WelcomeAlt = 'welcome-alt',
  Search = 'search',
  Discussion = 'discussion',
  DiscussionAlt = 'discussion-alt',
  DiscussionNoEntry = 'discussion-no-entry',
  Error = 'error',
  Topics = 'topics',
  Message = 'message',
  MessageAlt = 'message-alt',
  Question = 'question',
  Profile = 'profile',
  Invite = 'invite',
  Camel = 'camel',
}
