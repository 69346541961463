import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKeyAuth, FeatureStateAuth } from './auth.reducer';

const selectFeatureSelector = createFeatureSelector<FeatureStateAuth>(featureKeyAuth);

export const selectAuthData = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.authResult.data);
export const selectAuthIsLoading = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.authResult.isLoading);
export const selectAuthError = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.authResult.error);

export const selectForgotPasswordData = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.forgotPasswordResult.data);
export const selectForgotPasswordIsLoading = createSelector(
  selectFeatureSelector,
  (state: FeatureStateAuth) => state.forgotPasswordResult.isLoading
);
export const selectForgotPasswordError = createSelector(
  selectFeatureSelector,
  (state: FeatureStateAuth) => state.forgotPasswordResult.error
);

export const selectValidateEmailResult = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.validateEmailResult.data);
export const selectValidateEmailIsLoading = createSelector(
  selectFeatureSelector,
  (state: FeatureStateAuth) => state.validateEmailResult.isLoading
);
export const selectValidateEmailError = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.setPasswordResult.error);

export const selectResetPasswordResult = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.setPasswordResult.data);
export const selectResetPasswordIsLoading = createSelector(
  selectFeatureSelector,
  (state: FeatureStateAuth) => state.validateEmailResult.isLoading
);
export const selectResetPasswordError = createSelector(selectFeatureSelector, (state: FeatureStateAuth) => state.setPasswordResult.error);
