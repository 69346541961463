import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TrackByFunction } from '@angular/core';
import { isBefore } from 'date-fns';
import { EventLabel } from 'src/app/events/enums/event-label.enum';
import { getEventLabel } from 'src/app/events/utils/get-event-label';
import { EventListCardVM } from './event-list-card.vm';

@Component({
  selector: 'app-event-list-card',
  templateUrl: './event-list-card.component.html',
  styleUrls: ['./event-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListCardComponent {
  @Input() public vm!: EventListCardVM;
  @Output() public showDetailsClick = new EventEmitter<{ eventId: string }>();
  @Output() public bookEventClick = new EventEmitter<{ eventId: string }>();
  @Output() public unbookEventClick = new EventEmitter<{ eventId: string }>();

  public readonly eventLabel = EventLabel;

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public onBookEvent(eventId: string): void {
    this.bookEventClick.next({ eventId });
  }

  public onUnbookEvent(eventId: string): void {
    this.unbookEventClick.next({ eventId });
  }

  public onShowDetails(eventId: string): void {
    this.showDetailsClick.next({ eventId });
  }

  public isUpcoming(): boolean {
    return isBefore(new Date(), new Date(this.vm.startAt));
  }

  public getEventLabel(): EventLabel {
    if (this.vm) {
      const { startAt, endAt } = this.vm;

      return getEventLabel(new Date(), new Date(startAt), new Date(endAt));
    }

    return EventLabel.Past;
  }
}
