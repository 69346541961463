import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TrackByFunction,
  OnInit,
  ViewChild,
  ElementRef,
  Optional,
  Self,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ControlsValue, ControlValueAccessor, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';

interface AnswerControl {
  id: string;
  placeholder: string;
  value: string;
}
@Component({
  selector: 'app-answer-selector',
  templateUrl: './answer-selector.component.html',
  styleUrls: ['./answer-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerSelectorComponent implements ControlValueAccessor, AfterContentInit, OnInit {
  @Input() public error = '';

  @ViewChild('input') public input: ElementRef | undefined;

  public onChange: ((_: unknown) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public answersFormGroup!: FormGroup;
  public value: Array<string> = [];
  public answers: Array<AnswerControl> = [
    {
      id: 'answer1',
      placeholder: 'Answer 1',
      value: '',
    },
  ];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    public readonly formBuilder: FormBuilder,
    private readonly el: ElementRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get hasError(): boolean {
    return Boolean(this.control?.touched) && Boolean(this.control?.invalid) && Object.values(this.control?.errors || []).length > 0;
  }

  public get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  // @TODO: refactor change detection (outside form control markAsTouched doesn't trigger UI update, hence the subscription)
  // same as in input.component.ts
  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public addAnotherAnswer(): void {
    const length = this.answers.length + 1;
    const id = `answer${length}`;
    const newAnswer = {
      id,
      placeholder: `Answer ${length}`,
      value: '',
    };

    const control = new FormControl(newAnswer.value);
    this.answersFormGroup.addControl(newAnswer.id, control);

    setTimeout(() => {
      const newNativeElement = this.el.nativeElement.querySelector(`.answer_${newAnswer.id} input`);
      newNativeElement?.focus();
    }, 0);

    this.answers.push(newAnswer);
  }

  public ngOnInit(): void {
    this.answersFormGroup = this.formBuilder.group({
      answer1: [''],
      addAnotherAnswer: [''],
    });

    this.answersFormGroup.valueChanges.subscribe((value) => this.onValueChanges(value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onValueChanges(value: ControlsValue<any>): void {
    const answerValues = Object.values(value);

    if (this.onChange) {
      this.onChange(answerValues);
    }
  }

  // TODO: add type for value => e.g.: Answer interface
  public writeValue(value: unknown): void {
    if (this.input) {
      this.input.nativeElement.value = value;
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (_: unknown) => unknown): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => unknown): void {
    this.onTouched = function_;
  }
}
