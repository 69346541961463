import { Injectable } from '@angular/core';
import { NewsFeedScreenVm } from './news-feed-screen.vm';
import { Observable, combineLatest, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { getNewsFeed } from 'src/app/stores/news-feed/news-feed.actions';
import { selectNewsFeed } from 'src/app/stores/news-feed/news-feed.selectors';
import { LocalizationService } from '@core/services/localization.service';

@Injectable()
export class NewsFeedScreenService {
  public vm$: Observable<NewsFeedScreenVm | undefined> = combineLatest([
    this.store.select(selectNewsFeed),
    this.localization.currentLanguage$,
  ]).pipe(
    map(([newsFeed, lang]) => {
      if (newsFeed.isLoading || !newsFeed.data) {
        return undefined;
      }
      return {
        newsFeed: newsFeed.data.map((item) => ({
          title: lang === 'en' ? item.titleEn : item.titleNl,
          description: lang === 'en' ? item.descriptionEn : item.descriptionNl,
          imageMobile: item.imageMobileUrl,
          imageDesktop: item.imageDesktopUrl,
          article: item.article
            ? { slug: item.article.slug!, author: item.article.author!, publishDate: item.article.publishDate! }
            : undefined,
        })),
      };
    })
  );

  constructor(private readonly store: Store, private readonly localization: LocalizationService) {
    this.store.dispatch(getNewsFeed());
  }
}
