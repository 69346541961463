
export const appAnalyseIcon = {
  data:
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2096_16923)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.21031 18.4519C4.11494 18.4519 -0.0156689 14.3213 -0.0156689 9.22597C-0.0156689 4.13061 4.11494 0 9.21031 0C14.3057 0 18.4363 4.13061 18.4363 9.22597C18.4363 11.1524 17.8458 12.941 16.836 14.4205L23.4233 21.0078C24.1091 21.6935 24.1091 22.8156 23.4233 23.5013C22.7376 24.1871 21.6155 24.1871 20.9298 23.5013L14.3304 16.902C12.8655 17.8811 11.1046 18.4519 9.21031 18.4519ZM13.7071 5.29289C13.3166 4.90237 12.6834 4.90237 12.2929 5.29289L9 8.58579L7.70711 7.29289C7.31658 6.90237 6.68342 6.90237 6.29289 7.29289L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071C2.68342 13.0976 3.31658 13.0976 3.70711 12.7071L7 9.41421L8.29289 10.7071C8.68342 11.0976 9.31658 11.0976 9.70711 10.7071L13 7.41421L15.2929 9.70711C15.6834 10.0976 16.3166 10.0976 16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289L13.7071 5.29289Z" fill="#02065B"/>
    </g>
    <defs>
    <clipPath id="clip0_2096_16923">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  name: 'analyse'
};

