<label
  class="checkbox d-flex"
  [class.disabled]="control?.disabled"
  [class.selected]="control?.value"
  [class.error]="hasError"
  (click)="!control?.disabled && onCheckboxChange($event)"
>
  <div class="checkbox-mark-wrapper">
    <input type="checkbox" [name]="name" [disabled]="control?.disabled" [value]="control?.value" hidden />
    <span class="checkmark d-flex justify-content-center align-items-center" [class.intermediate]="isIntermediate">
      <svg-icon *ngIf="control?.value && !isIntermediate" key="check"></svg-icon>
    </span>
  </div>
  <div class="checkbox-text-wrapper w-100">
    <span class="checkbox-label text-small ms-1 w-100" [class.font-weight-bold]="control?.value && !isIntermediate">
      <ng-content></ng-content>
    </span>
    <span class="hint text-small ms-1" [class.error]="hasError" *ngIf="hint || error">{{ hasError ? error : hint }}</span>
  </div>
</label>
