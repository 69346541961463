import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { DiscussionMember } from 'src/domain/discussion-member';
import { MemberListAction } from './member-list-action.enum';
import { MemberListVM } from './member-list.vm';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberListComponent {
  @Input() public action = MemberListAction.None;
  @Input() public vm!: MemberListVM;
  @Output() public membersSelect = new EventEmitter<DiscussionMember[]>();
  @Output() public membersDeselect = new EventEmitter<DiscussionMember[]>();
  @Output() public memberInvite = new EventEmitter<DiscussionMember>();
  @Output() public inviteAll = new EventEmitter<DiscussionMember[]>();
  public readonly actionTypes = MemberListAction;

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public hasInvitableMembers(members: DiscussionMember[]): boolean {
    return members.some((member) => !member.isInvited);
  }

  public onInviteClick(member: DiscussionMember): void {
    this.memberInvite.next(member);
  }

  public onInviteAllClick(): void {
    this.inviteAll.next(this.vm.members);
  }

  public onSelectClick(member: DiscussionMember): void {
    member.isSelected = true;
    this.membersSelect.next([member]);
  }

  public onDeselectClick(member: DiscussionMember): void {
    member.isSelected = false;
    this.membersDeselect.next([member]);
  }

  public onSelectAllClick(): void {
    this.vm.members.forEach((member) => (member.isSelected = true));
    this.membersSelect.next(this.vm.members);
  }

  public onDeselectAllClick(): void {
    this.vm.members.forEach((member) => (member.isSelected = false));
    this.membersDeselect.next(this.vm.members);
  }
}
