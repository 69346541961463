import { Directive, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { INTERSECTION_ROOT_MARGIN, INTERSECTION_THRESHOLD, IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appMeasureNewsfeedItemVisibility]',
  providers: [
    IntersectionObserverService,
    {
      provide: INTERSECTION_THRESHOLD,
      useValue: 0.6,
    },
    {
      provide: INTERSECTION_ROOT_MARGIN,
      useValue: '0px',
    },
  ],
})
export class MeasureNewsfeedItemVisibilityDirective implements OnInit {
  @Output() public itemVisibilityMeasured = new EventEmitter<void>();
  private readonly itemHasBeenMeasured$ = new Subject<void>();

  constructor(@Inject(IntersectionObserverService) public entries$: IntersectionObserverService) {}

  public ngOnInit(): void {
    this.entries$
      .pipe(
        filter((intersections) => {
          return intersections[intersections.length - 1].isIntersecting;
        }),
        untilDestroyed(this),
        takeUntil(this.itemHasBeenMeasured$)
      )
      .subscribe(() => {
        this.itemHasBeenMeasured$.next();
        this.itemVisibilityMeasured.emit();
      });
  }
}
