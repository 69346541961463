import { Component, ChangeDetectionStrategy, Input, TrackByFunction, Output, EventEmitter } from '@angular/core';
import { Tag } from 'src/domain/tag';

@Component({
  selector: 'app-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSelectComponent {
  @Input() public disabled = false;
  @Input() public tags!: Tag[];
  @Input() public showCount?: number;
  @Input() public hasHashtag = false;
  @Output() public selectionChange = new EventEmitter<Tag[]>();

  public trackById: TrackByFunction<Tag> = (_, item) => item.id;

  public onTagClick(tag: Tag): void {
    if (this.disabled) {
      return;
    }

    tag.isSelected = !tag.isSelected;
    this.selectionChange.next(this.tags);
  }
}
