export const appPlaningIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5222 2H3.47782C2.12628 2 1 3.30435 1 4.86957V19.1304C1 20.6957 2.12628 22 3.47782 22C10.2258 22 14.0108 22 20.5222 22C21.8737 22 23 20.6957 23 19.1304V4.86957C23 3.30435 21.9488 2 20.5222 2Z" fill="#02065B"/>
    <rect x="9" y="15" width="4" height="4" rx="1" transform="rotate(90 9 15)" fill="#98E6FF"/>
    <path d="M11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7V9ZM7 18H15.75V16H7V18ZM15.75 11.5H7.25V13.5H15.75V11.5ZM7.25 9H11V7H7.25V9ZM6 10.25C6 9.55964 6.55964 9 7.25 9V7C5.45507 7 4 8.45507 4 10.25H6ZM7.25 11.5C6.55964 11.5 6 10.9404 6 10.25H4C4 12.0449 5.45507 13.5 7.25 13.5V11.5ZM19 14.75C19 12.9551 17.5449 11.5 15.75 11.5V13.5C16.4404 13.5 17 14.0596 17 14.75H19ZM15.75 18C17.5449 18 19 16.5449 19 14.75H17C17 15.4404 16.4404 16 15.75 16V18Z" fill="#98E6FF"/>
    <path d="M18 10L14.0002 6.00025" stroke="#98E6FF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 6L14.0002 9.99975" stroke="#98E6FF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  name: 'planing',
};
