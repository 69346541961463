<div class="d-flex">
  <div
    *ngIf="vm.profilePictureUrl; else defaultImage"
    class="member-image"
    [style.background-image]="'url(' + vm.profilePictureUrl + ')'"
  ></div>
  <ng-template #defaultImage>
    <svg-icon key="member" class="member-image"></svg-icon>
  </ng-template>
  <a class="ms-3 color-secondary" [routerLink]="['/', RouteSegment.Platform, RouteSegment.Member, vm.id]">
    <h2 class="title-h2 mb-0">{{ vm.firstName }} {{ vm.lastName }}</h2>
  </a>
</div>
<div class="d-flex align-items-center">
  <div class="line background-background50"></div>
  <div class="injection-left h-100">
    <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.5968 12.8942C40.4667 5.09469 49.4342 0 60 0V64C49.4342 64 40.4667 58.8897 34.5968 51.0901C26.599 40.5207 13.2807 34 0 34V30C13.3541 30 26.6724 23.4637 34.5968 12.8942Z"
        fill="#EEF0F7"
      />
    </svg>
  </div>
  <div
    class="info-wrapper d-flex align-items-center color-secondary text-small-italic background-background50 py-3"
    [class.ps-4]="!isButtonVisible()"
  >
    <div class="info">{{ vm.email }}</div>
    <div class="info" *ngIf="vm.profession">{{ vm.profession | titlecase }}</div>
    <button *ngIf="isButtonVisible()" class="primary large" [class.following]="vm.isFollowing" (click)="onButtonClick()">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <span class="text-title-h3-bold"> {{ vm.isFollowing ? 'following' : 'follow' }}</span>
        </div>
      </div>
    </button>
  </div>
</div>
