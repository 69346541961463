<div class="list d-flex flex-wrap gap-2">
  <span
    role="button"
    *ngFor="let profession of professions.slice(0, showCount || professions.length); trackBy: trackByName"
    class="d-flex tag text-lowercase normal"
    [class.pressed]="profession.isSelected"
    (click)="onProfessionClick(profession)"
  >
    {{ profession.name }}
  </span>
</div>
