import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { MessageTitleVM } from './message-title.vm';

@Component({
  selector: 'app-message-title',
  templateUrl: './message-title.component.html',
  styleUrls: ['./message-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTitleComponent {
  @Input() public vm!: MessageTitleVM;
  public RouteSegment = RouteSegment;
}
