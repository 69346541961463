import { Injectable } from '@angular/core';
import {
  AuthenticationDataService,
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  SetPasswordRequest,
  ValidateEmailRequest,
} from '@hip/frontend-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Auth } from '@shared/interfaces/auth.interface';
import { catchError, concatMap, map, of } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { LoginInfo } from '../interfaces/login-info.interface';
import { SetPasswordInfo } from '../interfaces/set-password-info.interface';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  //eslint-disable-next-line unicorn/consistent-function-scoping
  public login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap(({ loginInfo }) =>
        this.authDataService.logIn(this.mapPropsToLogin(loginInfo)).pipe(
          map((response) => AuthActions.loginSuccess({ auth: this.mapLoginResponseToAction(response) })),
          catchError((error) => of(AuthActions.loginError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  //eslint-disable-next-line unicorn/consistent-function-scoping
  public logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() =>
        this.authDataService.logOut().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError((error) => of(AuthActions.logoutError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  //eslint-disable-next-line unicorn/consistent-function-scoping
  public forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      concatMap((props) =>
        this.authDataService.forgotPassword(this.mapPropsToForgotPassword(props)).pipe(
          map(() => AuthActions.forgotPasswordSuccess(props)),
          catchError((error) => of(AuthActions.forgotPasswordError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  //eslint-disable-next-line unicorn/consistent-function-scoping
  public validateEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.validateEmail),
      concatMap((props) =>
        this.authDataService.validateEmail(this.mapPropsToValidateEmail(props)).pipe(
          map(() => AuthActions.validateEmailSuccess()),
          catchError((error) => of(AuthActions.validateEmailError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  //eslint-disable-next-line unicorn/consistent-function-scoping
  public setPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setPassword),
      concatMap(({ setPasswordInfo }) =>
        this.authDataService.setPassword(this.mapPropsToSetPassword(setPasswordInfo)).pipe(
          map(() => AuthActions.setPasswordSuccess()),
          catchError((error) => of(AuthActions.setPasswordError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public resetLoginError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginError),
      concatMap((_) => of(AuthActions.resetLoginError()))
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public resetSetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setPasswordSuccess),
      concatMap((_) => of(AuthActions.resetResetPassword()))
    );
  });

  public constructor(private readonly actions$: Actions, private readonly authDataService: AuthenticationDataService) {}

  private mapPropsToLogin({ email, password }: LoginInfo): LoginRequest {
    return {
      email,
      password,
    };
  }

  private mapLoginResponseToAction(response: LoginResponse): Auth {
    return {
      isLoggedIn: true,
      accessToken: response.accessToken,
    };
  }

  private mapPropsToForgotPassword({ email }: { email: string }): ForgotPasswordRequest {
    return { email };
  }

  private mapPropsToValidateEmail({ validateEmailToken }: { validateEmailToken: string }): ValidateEmailRequest {
    return { validateEmailToken };
  }

  private mapPropsToSetPassword({ setPasswordToken, password }: SetPasswordInfo): SetPasswordRequest {
    return {
      setPasswordToken,
      password,
    };
  }
}
