<div class="list-card card-shadow background-white100 color-secondary position-relative w-100">
  <div [class]="'d-flex position-absolute label-sign ' + (isUpcoming() ? 'background-upcoming' : 'background-past')">
    <span class="text-tiny-bold color-secondary mx-1">{{ getEventLabel() }}</span>
  </div>
  <div class="d-flex align-items-center mb-5 gap-4 justify-content-between w-100">
    <div class="flex-grow-1">
      <div
        *ngIf="vm.imageUrl"
        class="event-image px-5"
        [style.background-image]="'url(' + vm.imageUrl + ')'"
        [class.grayscale]="getEventLabel() === eventLabel.Past"
      ></div>
    </div>
  </div>
  <app-event-date class="event-date" [date]="vm.endAt"></app-event-date>
  <div class="px-5 pb-4">
    <p class="title-h3 mb-3">{{ vm.title }}</p>
    <div *ngIf="vm.location && getEventLabel() !== eventLabel.Past" class="mb-1 text-normal d-flex align-items-center gap-1">
      <svg-icon key="location"></svg-icon><span>{{ vm.location }}</span>
    </div>
    <div *ngIf="getEventLabel() !== eventLabel.Past" class="mb-3 text-normal d-flex align-items-center gap-1">
      <svg-icon key="time"></svg-icon><span>{{ vm.startAt | date: 'H:mm' }} - {{ vm.endAt | date: 'H:mm zz' }}</span>
    </div>
    <div *ngIf="getEventLabel() === eventLabel.Past && vm.recording" class="mb-3 text-normal d-flex align-items-center gap-1">
      <svg-icon key="recorded"></svg-icon> <a [href]="vm.recording" target="_blank">recorded event</a>
    </div>
    <div class="tags d-flex row w-100 gap-4 align-items-center">
      <button *ngIf="getEventLabel() === eventLabel.Upcoming && !vm.isBooked" class="primary small col" (click)="onBookEvent(vm.id)">
        book
      </button>
      <button
        *ngIf="getEventLabel() === eventLabel.Upcoming && vm.isBooked"
        class="primary small col background-secondary"
        (click)="onUnbookEvent(vm.id)"
      >
        booked
      </button>
      <button [class]="(getEventLabel() === eventLabel.Upcoming ? 'secondary' : 'primary') + ' small col'" (click)="onShowDetails(vm.id)">
        {{ getEventLabel() === eventLabel.Upcoming ? 'show details' : 'details' }}
      </button>
    </div>
  </div>
</div>
