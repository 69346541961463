import { Directive, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Subject, Subscription, takeUntil, timer } from 'rxjs';
import { INTERSECTION_ROOT_MARGIN, INTERSECTION_THRESHOLD, IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TagPusherService } from '@core/services/tag-pusher.service';

const TARGET_WPM = 300;

@UntilDestroy()
@Directive({
  selector: '[appMeasureNewsfeed]',
  providers: [
    IntersectionObserverService,
    {
      provide: INTERSECTION_THRESHOLD,
      useValue: 0.6,
    },
    {
      provide: INTERSECTION_ROOT_MARGIN,
      useValue: '0px',
    },
  ],
})
export class MeasureNewsfeedDirective implements OnInit {
  @Input() public article?: string;
  @Output() public measureNewsFeed = new EventEmitter<void>();
  private readonly itemHasBeenMeasured$ = new Subject<void>();
  private timeRequiredToRead!: Subscription;

  constructor(
    private readonly tagPusherService: TagPusherService,
    @Inject(IntersectionObserverService) public entries$: IntersectionObserverService
  ) {}

  public ngOnInit(): void {
    this.entries$.pipe(untilDestroyed(this), takeUntil(this.itemHasBeenMeasured$)).subscribe((intersections) => {
      const intersecting = intersections[intersections.length - 1].isIntersecting;
      if (intersecting) {
        this.setupSubscriptions();
      } else if (!intersecting && this.timeRequiredToRead) {
        this.timeRequiredToRead.unsubscribe();
      }
    });
  }

  public setupSubscriptions(): void {
    const wordCount = this.article?.split(' ').length;
    if (wordCount) {
      this.setupTimeRequiredToRead(wordCount);
    }
  }

  public setupTimeRequiredToRead(wordCount: number): void {
    const timeNeededToRead = Math.round((wordCount / TARGET_WPM) * 60);
    this.timeRequiredToRead = timer(timeNeededToRead * 1000).subscribe(() => {
      this.itemHasBeenMeasured$.next();
      this.measureNewsFeed.emit();
      this.timeRequiredToRead.unsubscribe();
    });
  }
}
