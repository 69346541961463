import { Component, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';

// @TODO: temp solution to show isLoading only if API calls take more than 200ms
const FLICKER_DELAY_MS = 300;

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnChanges {
  @Input() public isLoading = true;

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading.currentValue === true) {
      this.isLoading = false;
      this.cdr.markForCheck();
      setTimeout(() => {
        this.isLoading = true;
        this.cdr.markForCheck();
      }, FLICKER_DELAY_MS);
    }
  }
}
