import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Optional,
  Self,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterContentInit,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class InputComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public label?: string;
  @Input() public hint?: string;
  @Input() public type?: string;
  @Input() public style: 'primary' | 'secondary' = 'primary';
  @Input() public isLight = false;
  @Input() public placeholder?: string = '';
  @Input() public clearable = false;
  @Input() public error?: string;
  @Input() public maxLength?: number;

  @ViewChild('input') public inputElement?: ElementRef;

  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public constructor(@Optional() @Self() private readonly ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  public get hasError(): boolean {
    return (this.control?.invalid || Object.values(this.control?.errors || []).length > 0) && !!this.control?.touched;
  }

  // @TODO: refactor change detection (outside formcontrol markAsTouched doesn't trigger UI update, hence the subscription)
  public ngAfterContentInit(): void {
    this.control.touch$.subscribe((_) => this.cdr.markForCheck());
  }

  public detect(): void {
    this.cdr.markForCheck();
  }

  public onValueChange(value: string): void {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  public onClearIconClick(): void {
    this.control.reset();
    this.control.setValue('');
    this.cdr.markForCheck();
  }

  public onInputContainerFocus(): void {
    this.inputElement?.nativeElement.focus();
  }

  public onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  public writeValue(value: string): void {
    if (!this.control || this.control.value === value) {
      return;
    }
    this.control.setValue(value);
    this.cdr.markForCheck();
  }

  public registerOnChange(function_: (value: string) => void): void {
    this.onChange = function_;
  }

  public registerOnTouched(function_: () => void): void {
    this.onTouched = function_;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.control?.setDisable(isDisabled);
  }
}
