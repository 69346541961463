import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { MemberInfoPanelVM } from './member-info-panel.vm';

@Component({
  selector: 'app-member-info-panel',
  templateUrl: './member-info-panel.component.html',
  styleUrls: ['./member-info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInfoPanelComponent {
  @Input() public vm!: MemberInfoPanelVM;
  @Input() public hideButton = false;
  @Output() public buttonClick = new EventEmitter();

  public RouteSegment = RouteSegment;

  public onButtonClick(): void {
    this.buttonClick.emit();
  }

  public isButtonVisible(): boolean {
    return !this.hideButton;
  }
}
