import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MarkdownModule } from 'ngx-markdown';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { NewsFeedEffects } from '../stores/news-feed/news-feed.effects';
import { featureKeyNewsFeed, reducerNewsFeed } from '../stores/news-feed/news-feed.reducer';
import { NewsFeedItemComponent } from './components/news-feed-item/news-feed-item.component';
import { NewsFeedRoutingModule } from './news-feed-routing.module';
import { NewsFeedScreenComponent } from './components/news-feed-screen/news-feed-screen.component';
import { MeasureNewsfeedDirective } from './directives/measure-newsfeed.directive';
import { MeasureNewsfeedItemVisibilityDirective } from './directives/measure-newsfeed-item-visibility.directive';

@NgModule({
  declarations: [NewsFeedScreenComponent, NewsFeedItemComponent, MeasureNewsfeedDirective, MeasureNewsfeedItemVisibilityDirective],
  imports: [
    CommonModule,
    NewsFeedRoutingModule,
    SharedModule,
    IntersectionObserverModule,
    StoreModule.forFeature(featureKeyNewsFeed, reducerNewsFeed),
    EffectsModule.forFeature([NewsFeedEffects]),
    MarkdownModule.forRoot(),
    SvgIconsModule.forChild([]),
  ],
  exports: [NewsFeedScreenComponent],
})
export class NewsFeedModule {}
