import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { FlagToggle } from 'src/app/stores/thread/flag-toggle';
import { Attachment } from 'src/domain/attachment';
import { DiscussionMessage } from '../discussion-input/discussion-message.interface';
import { MessageVM } from './message.vm';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  @Input() public disableInteraction = false;
  @Input() public vm!: MessageVM;
  @Input() public isPollVisible = false;
  @Output() public messageReport = new EventEmitter<{ messageId: string; toggle: FlagToggle }>();
  @Output() public replySend = new EventEmitter<{ message: DiscussionMessage; parentId: string }>();
  @Output() public addCustomAnswer = new EventEmitter<{ quickPollId: string; text: string }>();
  @Output() public answerPoll = new EventEmitter<{ quickPollId: string; answerIds: Array<string> }>();
  public isReplyInputVisible = false;

  public trackById: TrackByFunction<Attachment> = (_, item) => item.id;

  public toggleReplyInput(): void {
    if (this.disableInteraction) {
      return;
    }
    this.isReplyInputVisible = !this.isReplyInputVisible;
  }

  public onAddCustomAnswer(event: { quickPollId: string; text: string }): void {
    this.addCustomAnswer.next({ quickPollId: event.quickPollId, text: event.text });
  }

  public onAnswerPoll(event: { quickPollId: string; answerIds: Array<string> }): void {
    this.answerPoll.next({ quickPollId: event.quickPollId, answerIds: event.answerIds });
  }

  public onReportClick(message: MessageVM): void {
    if (this.disableInteraction) {
      return;
    }
    this.messageReport.next({ messageId: message.id, toggle: message.isFlagged ? FlagToggle.Off : FlagToggle.On });
  }

  public onReplySend(message: DiscussionMessage): void {
    this.isReplyInputVisible = false;

    this.replySend.next({ message, parentId: this.vm.id });
  }

  public onMessageReport(event: { messageId: string; toggle: FlagToggle }): void {
    const { messageId, toggle } = event;

    this.messageReport.next({ messageId, toggle });
  }
}
