import { isBefore } from 'date-fns';
import { EventLabel } from '../enums/event-label.enum';

export const getEventLabel = (checkTime: Date, startAt: Date, endAt: Date): EventLabel => {
  if (isBefore(checkTime, startAt)) {
    return EventLabel.Upcoming;
  } else if (isBefore(startAt, checkTime) && isBefore(checkTime, endAt)) {
    return EventLabel.Ongoing;
  }

  return EventLabel.Past;
};
