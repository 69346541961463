export const appCaseIcon = {
  data: `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.4454 7H6.55461C4.343 7 2.5 9.11957 2.5 11.663V28.337C2.5 30.8804 4.343 33 6.55461 33C17.5968 33 23.7903 33 34.4454 33C36.657 33 38.5 30.8804 38.5 28.337V11.663C38.5 9.11957 36.7799 7 34.4454 7Z" fill="#02065B"/>
    <rect x="9.5" y="4" width="4" height="6" rx="2" fill="#02065B"/>
    <rect x="35.5" y="10" width="4" height="30" rx="2" transform="rotate(90 35.5 10)" fill="#EEF0F7"/>
    <rect x="27.5" y="4" width="4" height="6" rx="2" fill="#02065B"/>
    <path d="M16.5 23.9492C17.0049 24.6886 18.9678 27 18.9678 27C18.9678 27 24.1394 20.7764 25.5 19" stroke="#EEF0F7" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  name: 'case',
};
