import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { NewsFeedItemVm } from './news-feed-item.vm';

@Component({
  selector: 'app-news-feed-item',
  templateUrl: './news-feed-item.component.html',
  styleUrls: ['./news-feed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedItemComponent implements OnInit {
  @HostBinding('class') class = 'd-flex flex-column card-shadow background-white100 color-secondary-feed-item';
  @Input() vm!: NewsFeedItemVm;
  public TagEvent = TagEvent;

  constructor(private readonly tagPusherService: TagPusherService) {}

  ngOnInit(): void {}
}
