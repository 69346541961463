import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-collaboration-field',
  templateUrl: './collaboration-field.component.html',
  styleUrls: ['./collaboration-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaborationFieldComponent {
  @Input() public icon = '';
  @Input() public name = '';
  @Input() public value = '';
}
