import { Component, ChangeDetectionStrategy, Inject, TrackByFunction } from '@angular/core';
import { DIALOG_DATA } from '@shared/modules/dialog/dialog.tokens';
import { DialogData } from '@shared/modules/dialog/interfaces/dialog-data.interface';
import { ProfessionFilter } from 'src/app/members/interfaces/profession-filter';
import { Tag } from 'src/domain/tag';
import { ListFilterOwnership } from '../list-head/list-filter-ownership.enum';
import { ListFilter } from '../list-head/list-filter.interface';

export interface FilterDialogInput {
  filter: Omit<ListFilter, 'searchTerm'>;
  isEventType: boolean;
  isMemberType: boolean;
}

@Component({
  selector: 'app-discussion-filter-dialog',
  templateUrl: './discussion-filter-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionFilterDialogComponent {
  public tags!: Tag[];
  public professions?: ProfessionFilter[];
  public ownership!: ListFilterOwnership;
  public readonly ownershipType = ListFilterOwnership;
  public isEventType = false;
  public isMemberType = false;

  constructor(@Inject(DIALOG_DATA) private readonly dialog: DialogData<FilterDialogInput, Omit<ListFilter, 'searchTerm'>>) {
    this.tags = this.dialog.payload.filter.tags;
    this.professions = this.dialog.payload.filter.professions;
    this.ownership = this.dialog.payload.filter.listFilter as ListFilterOwnership;
    this.isEventType = this.dialog.payload.isEventType;
    this.isMemberType = this.dialog.payload.isMemberType;
  }

  public get selectedTagsCount(): number {
    return this.tags.filter((tag) => tag.isSelected).length;
  }

  public get selectedProfessionsCount(): number {
    return this.professions?.filter((profession) => profession.isSelected).length ?? 0;
  }

  public getDialogTitle(): string {
    if (this.isEventType) {
      return 'events';
    } else if (this.isMemberType) {
      return 'members';
    } else {
      return 'discussions';
    }
  }

  public trackById: TrackByFunction<Tag> = (_, item) => item.id;

  public onSelectAllTagClick(): void {
    if (this.professions) {
      this.professions = this.professions.map((profession) => ({ ...profession, isSelected: true }));
    } else {
      this.tags = this.tags.map((tag) => ({ ...tag, isSelected: true }));
    }
  }

  public onClearAllTagClick(): void {
    if (this.professions) {
      this.professions = this.professions.map((profession) => ({ ...profession, isSelected: false }));
    } else {
      this.tags = this.tags.map((tag) => ({ ...tag, isSelected: false }));
    }
  }

  // TODO: move to sort by dialog based on figma
  /*public onOwnershipClicked(ownership: ListFilterOwnership): void {
    this.ownership = ownership;
  }*/

  public onFilterClose(applySettings: boolean): void {
    if (!applySettings) {
      this.dialog.dialogRef.close();
      return;
    }

    this.dialog.dialogRef.close({
      tags: this.tags,
      professions: this.professions,
      listFilter: this.ownership,
      isOnlyTrending: false, // TODO: use in frontend
    });
  }
}
