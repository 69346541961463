import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { DIALOG_DATA } from '@shared/modules/dialog/dialog.tokens';
import { DialogData } from '@shared/modules/dialog/interfaces/dialog-data.interface';
import { ListHeadType } from '../list-head/list-head-type.enum';
import { ListOrder } from '../list-head/list-order.enum';
import { ListSortType } from '../list-head/list-sort-type.enum';
import { ListSort } from '../list-head/list-sort.interface';

export interface SortDialogInput {
  type: ListHeadType;
  listSort: ListSort;
}

@Component({
  selector: 'app-discussion-sort-dialog',
  templateUrl: './discussion-sort-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionSortDialogComponent {
  public type = ListHeadType.Discussion;
  public readonly sortType = ListSortType;
  public readonly order = ListOrder;
  public sortTypeControl!: FormControl;
  public orderControl!: FormControl;

  constructor(@Inject(DIALOG_DATA) private readonly dialog: DialogData<SortDialogInput, ListSort>) {
    this.sortTypeControl = new FormControl(dialog.payload.listSort.sortBy);
    this.orderControl = new FormControl(dialog.payload.listSort.order);
    this.type = dialog.payload.type;
  }

  public onSortClose(applySettings: boolean): void {
    if (!applySettings) {
      this.dialog.dialogRef.close();
      return;
    }

    this.dialog.dialogRef.close({ sortBy: this.sortTypeControl.value, order: this.orderControl.value });
  }

  public isEventType(): boolean {
    return this.type === ListHeadType.Event;
  }
}
