export const appIdeatingIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 17.5883C15 16.7275 15.4866 15.9585 16.1132 15.3683C17.2748 14.2741 18 12.7217 18 11C18 7.68629 15.3137 5 12 5C8.68629 5 6 7.68629 6 11C6 12.7217 6.72519 14.2741 7.88679 15.3683C8.51344 15.9585 9 16.7275 9 17.5883C9 18.9203 10.0797 20 11.4117 20H12.5883C13.9203 20 15 18.9203 15 17.5883Z" fill="#02065B"/>
    <rect x="14" y="21" width="2" height="4" rx="1" transform="rotate(90 14 21)" fill="#02065B"/>
    <path d="M12 3V1" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 4L19 3" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 10H22" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 10H3" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 4L5 3" stroke="#02065B" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  name: 'ideating',
};
