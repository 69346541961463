import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CheckAvailabilityRequest,
  CheckAvailabilityResponse,
  CollaborationStatus,
  CreateDiscussionRequest,
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  Profession,
  RecommendTagsRequest,
  ReplyRequest,
  SetPasswordRequest,
  UpdateTagsRequest,
  ValidateEmailRequest,
} from '@hip/frontend-api';

@Injectable({
  providedIn: 'root',
})
export class ApiMockService {
  // eslint-disable-next-line max-len
  private readonly accessToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRmFrZSBBY2Nlc3MgVG9rZW4gRm9yIFBmaXplciIsImV4cCI6MTg0MTE4NDAwMH0.s0pIlp_CFqKlvudUzRPDsHBd2DhZPtja1Lp4lmXUo70`;

  public getCheckAvailability(_request: HttpRequest<CheckAvailabilityRequest>): HttpResponse<CheckAvailabilityResponse> {
    return new HttpResponse({
      status: 200,
      body: {
        emailAvailable: true,
        linkedinProfileAvailable: true,
      },
    });
  }

  public logout(_request: HttpRequest<CheckAvailabilityRequest>): HttpResponse<CheckAvailabilityResponse> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public signup(_request: HttpRequest<CheckAvailabilityRequest>): HttpResponse<CheckAvailabilityResponse> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public resendValidationEmail(_request: HttpRequest<CheckAvailabilityRequest>): HttpResponse<CheckAvailabilityResponse> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public login(_request: HttpRequest<LoginRequest>): HttpResponse<LoginResponse> {
    return new HttpResponse({
      status: 200,
      body: {
        accessToken: this.accessToken,
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public forgotPassword(_request: HttpRequest<ForgotPasswordRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public validateEmail(_request: HttpRequest<ValidateEmailRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public setPassword(_request: HttpRequest<SetPasswordRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  public getDiscussions(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        totalCount: 1,
        items: [
          {
            id: 'abc123',
            joined: 'JOINED',
            title: 'Test discussion from mock API',
            trending: true,
            description:
              // eslint-disable-next-line max-len
              'This discussion is fake, mock API response, nothing to see here. A discussion text is a text that presents both sides of an issue or argument. The title of the text often outlines the issue to be discussed in the form of a question.',
            tags: [
              {
                id: 'tag-123-id',
                name: 'Oncology',
              },
            ],
            memberCount: 0,
            replyCount: 0,
            createdAt: '2021-01-28T09:12:28Z',
            latestActivity: '2021-01-28T09:12:28Z',
          },
          {
            id: 'abc124',
            joined: 'JOINED',
            title: 'Other test discussion from API',
            trending: false,
            description:
              // eslint-disable-next-line max-len
              'This discussion is fake, mock API response, nothing to see here. A discussion text is a text that presents both sides of an issue or argument. The title of the text often outlines the issue to be discussed in the form of a question.',
            tags: [
              {
                id: 'tag-123-id',
                name: 'Oncology',
              },
            ],
            memberCount: 0,
            replyCount: 0,
            createdAt: '2021-01-28T09:12:28Z',
            latestActivity: '2021-01-28T09:12:28Z',
          },
        ],
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public createDiscussion(_request: HttpRequest<CreateDiscussionRequest>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 201,
      body: {
        id: 'abc123',
        joined: 'NOT_JOINED',
        title: 'string',
        description: 'string',
        tags: [
          {
            id: 'abc123',
            name: 'string',
          },
        ],
        memberCount: 0,
        replyCount: 0,
        createdAt: '2021-01-28T09:12:28Z',
        latestActivity: '2021-01-28T09:12:28Z',
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public recommendTags(_request: HttpRequest<RecommendTagsRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  public getDiscussionById(_request: HttpRequest<{ id: string }>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        id: 'abc123',
        joined: 'NOT_JOINED',
        title: 'Discussion from mock API',
        description:
          // eslint-disable-next-line max-len
          'This discussion is fake, mock API response, nothing to see here. A discussion text is a text that presents both sides of an issue or argument. The title of the text often outlines the issue to be discussed in the form of a question.',
        tags: [
          {
            id: 'tag-123-id',
            name: 'Oncology',
          },
          {
            id: 'tag-124-id',
            name: 'Covid-19',
          },
        ],
        memberCount: 0,
        replyCount: 0,
        createdAt: '2021-01-28T09:12:28Z',
        latestActivity: '2021-01-28T09:12:28Z',
        pollIds: ['abc123', 'abc124'],
      },
    });
  }

  public getTags(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: [
        { id: '12344', name: 'covid' },
        { id: '12345', name: 'cardiology' },
        { id: '12346', name: 'research' },
        { id: '12347', name: 'mental health' },
        { id: '12348', name: 'medical data science' },
      ],
    });
  }

  public getProfile(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        firstName: 'Peter',
        lastName: 'the Doctor',
        profession: Profession.PROFESSIONAL,
        email: 'peter.doc@emailaddress.com',
        linkedinProfile: 'https://linkedin.com/in/peter-de-doctor',
        // image: 'https://jooinn.com/images/specialist-doctor.jpg',
        tags: [],
        notificationPreferences: {
          newsletter: true,
          events: false,
          conferenceOutputs: false,
          mentionsReplies: true,
        },
        recentReplies: [
          {
            parentReplyId: '23',
            discussion: {
              id: 'db53e3a5-9ed0-480f-aa41-b7fe08a222f9',
              title: 'Test',
            },
            id: '23',
            postedAt: new Date().toISOString(),
            text: 'Oh, yes, I agree',
          },
          {
            parentReplyId: '23',
            discussion: {
              id: 'db53e3a5-9ed0-480f-aa41-b7fe08a222f9',
              title: 'Test',
            },
            id: '23',
            postedAt: new Date().toISOString(),
            text: 'Oh, yes, I agree',
          },
          {
            parentReplyId: '23',
            discussion: {
              id: 'db53e3a5-9ed0-480f-aa41-b7fe08a222f9',
              title: 'Test',
            },
            id: '23',
            postedAt: new Date().toISOString(),
            text: 'Oh, yes, I agree',
          },
          {
            parentReplyId: '23',
            discussion: {
              id: 'db53e3a5-9ed0-480f-aa41-b7fe08a222f9',
              title: 'Test',
            },
            id: '23',
            postedAt: new Date().toISOString(),
            text: 'Oh, yes, I agree',
          },
        ],
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public updateProfileTags(_request: HttpRequest<UpdateTagsRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public updateProfileNotifications(_request: HttpRequest<UpdateTagsRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  public getReplies(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        items: [
          {
            id: 'abc111',
            masked: false,
            postedAt: '2022-01-28T09:12:28Z',
            member: {
              id: 'abc1234',
              firstName: 'Prof.',
              lastName: 'Controversial',
              profession: 'PROFESSIONAL',
              image: 'https://jooinn.com/images/specialist-doctor.jpg',
            },
            // eslint-disable-next-line max-len
            text: 'This message comes from MOCK API. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo',
            pollId: 'abc123',
            flagged: false,
            attachments: [
              {
                id: 'abc12345',
                fileName: 'attachment.xls',
                fileSize: 2338,
                mimeType: 'application/vnd.ms-excel',
                url: 'https://google.com',
              },
            ],
            replies: [
              {
                id: 'abc222',
                masked: false,
                postedAt: '2022-01-28T09:12:28Z',
                member: {
                  id: 'abc1232',
                  firstName: 'Ralph',
                  lastName: 'the Replier',
                  profession: 'PROFESSIONAL',
                },
                text: 'This reply is also coming from mock API.',
                flagged: false,
                attachments: [],
              },
            ],
          },
        ],
        totalCount: 0,
      },
    });
  }

  public getMembers(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        totalCount: 4,
        items: [
          {
            id: 'abc123',
            firstName: 'David',
            lastName: 'Doctor',
            profession: 'PROFESSIONAL',
          },
          {
            id: 'abc124',
            firstName: 'Tina',
            lastName: 'Technician',
            profession: 'PROFESSIONAL',
          },
          {
            id: 'abc125',
            firstName: 'Angela',
            lastName: 'Analyst',
            profession: 'PROFESSIONAL',
          },
          {
            id: 'abc126',
            firstName: 'Robert',
            lastName: 'Researcher',
            profession: 'PROFESSIONAL',
          },
        ],
      },
    });
  }

  public postReply(request: HttpRequest<ReplyRequest>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        id: `abc${Date.now().toString()}`,
        masked: false,
        postedAt: new Date().toString(),
        member: {
          id: 'abc12354',
          firstName: 'Current',
          lastName: 'User',
          profession: 'PROFESSIONAL',
        },
        // eslint-disable-next-line max-len
        text: request.body?.text,
        flagged: false,
        attachments:
          request.body?.attachmentIds?.map((id) => ({
            id,
            fileName: 'MOCKED UPLOAD.fileExt',
            fileSize: 2338,
            mimeType: 'application/vnd.ms-excel',
            url: 'https://google.com',
          })) || [],
        replies: [],
      },
    });
  }

  public getInvitableMembers(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        totalCount: 2,
        items: [
          {
            id: 'abc123',
            firstName: 'Uninvited',
            lastName: 'User',
            profession: 'PROFESSIONAL',
          },
          {
            id: 'abc124',
            firstName: 'Test',
            lastName: 'Mock API USER',
            profession: 'PROFESSIONAL',
          },
        ],
      },
    });
  }

  public postAttachment(request: HttpRequest<FormData>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        id: 'abc123322',
        fileName: request.body?.get('fileName'),
        fileSize: 2338,
        mimeType: 'application/vnd.ms-excel',
        url: 'https://google.com',
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public flagReply(_request: HttpRequest<RecommendTagsRequest>): HttpResponse<void> {
    return new HttpResponse({
      status: 204,
    });
  }

  public inviteMembersBulk(request: HttpRequest<{ memberIds: string[] }>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        successMemberIds: request.body?.memberIds,
        failedMemberIds: [],
      },
      // failing request 1
      // status: 200,
      // body: {
      //   successMemberIds: [],
      //   failedMemberIds: [request.body?.memberIds],
      // },
      // failing request 2
      // status: 500,
      // body: {
      //   error: 'mock internal fail',
      // },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public inviteMembersAll(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
    });
  }

  public getCollaborations(request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: request.params?.get('onlyJoined')
        ? {
            totalCount: 1,
            items: [
              {
                id: 'abc123',
                joined: 'JOINED',
                title: 'Lorem ipsum from mock API (joined)',
                description: 'Collab description...',
                tags: [
                  {
                    id: 'tag-123-id',
                    name: 'Oncology',
                  },
                ],
                memberCount: 0,
                replyCount: 0,
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
                status: CollaborationStatus.ANALYSING_DATA,
              },
            ],
          }
        : {
            totalCount: 2,
            items: [
              {
                id: 'abc123',
                joined: 'NOT_JOINED',
                title: 'Collaboration from mock API (not joined 1)',
                description: 'Lorem ipsum dolor site...',
                tags: [
                  {
                    id: 'tag-123-id',
                    name: 'Covid-19',
                  },
                ],
                memberCount: 0,
                replyCount: 0,
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
                status: CollaborationStatus.ANALYSING_DATA,
              },
              {
                id: 'abc123',
                joined: 'NOT_JOINED',
                title: 'Collaboration from mock API (not joined 2)',
                description: 'Collab description',
                tags: [
                  {
                    id: 'tag-123-id',
                    name: 'Research',
                  },
                ],
                memberCount: 0,
                replyCount: 0,
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
                status: CollaborationStatus.IDEATING,
              },
            ],
          },
    });
  }

  public getCollaborationById(_request: HttpRequest<{ id: string }>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        id: 'abc123',
        joined: 'JOINED',
        title: 'Collaboration from mock API',
        description:
          // eslint-disable-next-line max-len
          'This collaboration is fake, mock API response, nothing to see here. A collaboration text is a text that presents both sides of an issue or argument.',
        tags: [
          {
            id: 'tag-124-id',
            name: 'Research',
          },
        ],
        memberCount: 1,
        replyCount: 2,
        createdAt: '2022-01-28T09:12:28Z',
        latestActivity: '2022-01-28T09:12:28Z',
        params: {
          startAt: '2021-01-28',
          deadlineAt: '2021-05-26',
          contributionHours: 4,
          goal: 'publish article',
        },
        status: CollaborationStatus.EXPERIMENTING,
      },
    });
  }

  public getFeed(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        discussions: [
          {
            id: 'abc123',
            joined: 'JOINED',
            title: 'Discussion from mock API',
            description:
              // eslint-disable-next-line max-len
              'This discussion is fake, mock API response, nothing to see here. A discussion text is a text that presents both sides of an issue or argument. The title of the text often outlines the issue to be discussed in the form of a question.',
            tags: [
              {
                id: 'tag-123-id',
                name: 'Oncology',
              },
            ],
            memberCount: 11,
            replyCount: 14,
            createdAt: '2021-01-28T09:12:28Z',
            latestActivity: '2021-01-28T09:12:28Z',
          },
        ],
        collaborations: [
          {
            id: 'abc123',
            joined: 'JOINED',
            title: 'Collaboration from mock API (not joined 1)',
            description: 'Lorem ipsum dolor site...',
            tags: [
              {
                id: 'tag-123-id',
                name: 'Covid-19',
              },
            ],
            memberCount: 2,
            replyCount: 3,
            createdAt: '2021-01-28T09:12:28Z',
            latestActivity: '2021-01-28T09:12:28Z',
          },
          {
            id: 'abc123',
            joined: 'JOINED',
            title: 'Collaboration from mock API (not joined 2)',
            description: 'Collab description',
            tags: [
              {
                id: 'tag-123-id',
                name: 'Research',
              },
            ],
            memberCount: 12,
            replyCount: 6,
            createdAt: '2021-01-28T09:12:28Z',
            latestActivity: '2021-01-28T09:12:28Z',
          },
        ],
      },
    });
  }

  public postPoll(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
      body: {
        id: 'abc123',
        name: 'created poll name',
        answers: [
          {
            id: 'abc123',
            text: 'option 1',
            isCustom: true,
            voteCount: 0,
          },
        ],
        isMultipleChoice: true,
        canAddOwnAnswer: true,
        createdAt: '2021-01-28T09:12:28Z',
        closingAt: '2021-01-28T09:12:28Z',
        closing: {
          closingIn: 0,
          unit: 'MINUTE',
        },
        selectedAnswerIds: ['abc123'],
      },
    });
  }

  public getPoll(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        id: 'abc123',
        name: `random poll name ${Math.floor(Math.random() * 1000)}`,
        answers: [
          {
            id: 'abc123',
            text: 'option 1',
            isCustom: false,
            voteCount: 4,
          },
          {
            id: 'abc124',
            text: 'option 2',
            isCustom: false,
            voteCount: 4,
          },
        ],
        isMultipleChoice: true,
        canAddOwnAnswer: true,
        createdAt: '2021-01-28T09:12:28Z',
        closingAt: '2021-01-28T09:12:28Z',
        closing: {
          closingIn: 5,
          unit: 'MINUTE',
        },
        selectedAnswerIds: ['abc123'],
      },
    });
  }

  public postAnswerPoll(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
      body: {
        answerId: 'abc123',
        custom: 'custom answer',
      },
    });
  }

  public getDiscussionRules(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 200,
      body: {
        items: [
          {
            id: 'abc123',
            text: 'first mocked rule',
          },
          {
            id: 'abc124',
            text: 'second mocked rule',
          },
          {
            id: 'abc125',
            text: 'third mocked rule',
          },
        ],
        totalCount: 5,
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public requestAccess(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public getEvents(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    const hasItems = true;

    return new HttpResponse({
      status: 200,
      body: {
        totalCount: 2,
        items: hasItems
          ? [
              {
                id: 'abc-123',
                booked: 'BOOKED',
                title: `Ethics in healthcare; what's changed and how much is too much?`,
                description: 'Short mocked description for this event',
                imageUrl: '/assets/images/event-right.jpeg',
                startAt: '2022-05-23 19:00',
                endAt: '2022-05-23 20:00',
                location: 'XZY streaming location',
                recording: undefined,
                streamingLink: 'https://streaming.com/?id=123',
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
              },
              {
                id: 'abc-123',
                booked: 'NOT_BOOKED',
                title: `Something different`,
                description: 'Short mocked description for this event',
                imageUrl: '/assets/images/pills.png',
                startAt: '2022-05-23 19:00',
                endAt: '2022-05-23 20:00',
                location: 'XZY streaming location',
                recording: undefined,
                streamingLink: undefined,
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
              },
              {
                id: 'abc-124',
                booked: 'NOT_BOOKED',
                title: `Cancer treatment' where are we now?`,
                description: 'Feel free to book this event from mocked data',
                imageUrl: '/assets/images/robot-ai.png',
                startAt: '2022-05-23 19:00',
                endAt: '2022-05-23 20:00',
                location: 'XZY streaming location',
                recording: 'https://example.com/document.mp4',
                createdAt: '2021-01-28T09:12:28Z',
                latestActivity: '2021-01-28T09:12:28Z',
              },
            ]
          : [],
      },
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public bookEvent(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
      body: undefined,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public unbookEvent(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
      body: undefined,
    });
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  public getEventById(_request: HttpRequest<unknown>): HttpResponse<unknown> {
    return new HttpResponse({
      status: 204,
      body: {
        id: 'abc123',
        booked: 'NOT_BOOKED',
        title: `Ethics in healthcare; what's changed and how much is too much?`,
        // eslint-disable-next-line max-len
        description: `This is placeholder information about the event. It contains information about the speakers, topic details etc Recorded event Recorded event Recorded event Recorded event Recorded event information about the event. It contains information about the speakers, topic details etc Recorded event Recorded event Recorded event Recorded event Recorded event information about the event. It's about the speakers, topic details etc Recorded event Recorded event Recorded event Recorded event Recorded event information about the event. It contains information about the speakers, topic details etc Recorded event Recorded event Recorded event Recorded event Recorded event information about the event. It contains information about the speakers, topic details etc Recorded event Recorded event Recorded event Recorded event Recorded event.`,
        imageUrl: '/assets/images/event-right.jpeg',
        startAt: '2021-01-28T09:12:28Z',
        endAt: '2021-01-28T10:12:28Z',
        location: 'XZY streaming location',
        tags: [
          {
            id: 'abc-123',
            name: 'ethics',
          },
          {
            id: 'abc-124',
            name: 'patientcare',
          },
        ],
        attachments: [
          {
            id: 'abc123',
            fileName: 'attachment.xls',
            fileSize: 2338,
            mimeType: 'application/vnd.ms-excel',
            url: 'https://example.com/document.xls',
          },
        ],
        recording: 'https://example.com/document.mp4',
        memberCount: 100,
        createdAt: '2021-01-28T09:12:28Z',
        latestActivity: '2021-01-28T09:12:28Z',
        pollIds: ['abc123'],
      },
    });
  }
}
