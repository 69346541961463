import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDialogScrollEnd]',
})
export class DialogScrollEndDirective {
  @Output('appDialogScrollEnd') public scrollEnd: EventEmitter<void> = new EventEmitter();
  private readonly thresholdMultiplier = 0.001;
  private lastOffset = 0;

  @HostListener('scroll', ['$event'])
  public handleScroll(event: Event): void {
    const clientHeight: number | undefined = (event.target as HTMLElement).clientHeight;
    const scrollHeight: number | undefined = (event.target as HTMLElement).scrollHeight;
    const scrollTop: number | undefined = (event.target as HTMLElement).scrollTop;
    if (
      clientHeight === null ||
      clientHeight === undefined ||
      scrollHeight === null ||
      scrollHeight === undefined ||
      scrollTop === null ||
      scrollTop === undefined
    ) {
      return;
    }
    const offset = scrollHeight - scrollTop;
    const isScrollingDown = offset <= this.lastOffset;
    const thresholdHeight = clientHeight + clientHeight * this.thresholdMultiplier;
    this.lastOffset = offset;
    if (!isScrollingDown) {
      return;
    }
    if (offset < thresholdHeight) {
      this.scrollEnd.emit();
    }
  }
}
