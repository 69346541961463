<div #dropDownContainer class="dropdown-container" [class.disabled]="disabled">
  <label
    *ngIf="label"
    class="text-normal color-secondary mb-1"
    [class.color-textDisabled]="formControl?.disabled"
    [class.color-error]="hasError"
    >{{ label }}</label
  >
  <button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    type="button"
    class="dropdown-button text-normal"
    [class.focused]="isOpen"
    [disabled]="formControl?.disabled"
    [class.error]="hasError"
    (click)="onButtonClick()"
  >
    <span class="value" [class.placeholder]="!value" [class.text-normal-semibold]="value && !disabled">{{
      value?.text || placeholder || ''
    }}</span>
    <svg-icon
      key="arrow-down"
      class="color-secondary"
      [class.color-error]="hasError"
      [class.color-textDisabled]="disabled"
      [class.rotated]="isOpen"
    ></svg-icon>
  </button>
  <div
    *ngIf="hint || error"
    class="hint text-tiny mt-2 px-3 color-secondary"
    [class.color-error]="hasError"
    [class.color-textDisabled]="formControl.disabled"
    [innerHTML]="hasError ? error : hint"
  ></div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetY]="0"
  [cdkConnectedOverlayPositions]="overlayPositionStrategy"
  [cdkConnectedOverlayWidth]="dropDownContainer.offsetWidth"
  (overlayOutsideClick)="onOverlayOutsideClick($event)"
>
  <div class="overlay-menu menu-shadow d-flex flex-column">
    <div *ngFor="let option of options; trackBy: trackById" class="option-container">
      <!-- GROUPED OPTIONS -->
      <ng-container *ngIf="option.items && option.items.length > 0; else simpleOptions">
        <div class="option-title text-tiny color-primary40 d-inline-flex align-items-end px-2 pb-0-5">{{ option.text }}</div>
        <ng-container
          *ngFor="let subOption of option.items; trackBy: trackById"
          [ngTemplateOutlet]="simpleOption"
          [ngTemplateOutletContext]="{ $implicit: subOption }"
        ></ng-container>
      </ng-container>

      <!-- SIMPLE OPTIONS -->
      <ng-template #simpleOptions>
        <ng-container [ngTemplateOutlet]="simpleOption" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #simpleOption let-option>
  <button class="text-normal option p-2 my-1 color-secondary" (click)="onOptionClick(option)">{{ option.text }}</button>
</ng-template>
