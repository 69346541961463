<nav
  class="main-menu d-flex flex-column color-secondary background-backgroundGradient align-items-center justify-content-center"
  [class.justify-content-start]="isLoggedIn"
>
  <div class="logo">
    <svg-icon key="logo-vertical" fontSize="150px"></svg-icon>
  </div>
  <div *ngIf="isLoggedIn" class="actions d-flex w-100 flex-grow-1 flex-column align-items-center justify-content-between mt-3">
    <div class="links w-100">
      <a
        class="link d-flex align-items-center"
        [routerLink]="['/', 'platform']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
      >
        <svg-icon key="feed" fontSize="24px"></svg-icon>
        <span>your feed</span>
      </a>
      <a class="link d-flex align-items-center" [routerLink]="['/', 'platform', 'discussions']" routerLinkActive="active">
        <svg-icon key="discussion" fontSize="24px"></svg-icon>
        <span>discussions</span>
      </a>
      <a class="link d-flex align-items-center" [routerLink]="['/', 'platform', 'collaborations']" routerLinkActive="active">
        <svg-icon key="collaborations" fontSize="24px"></svg-icon>
        <span>collaborations</span>
      </a>
      <a class="link d-flex align-items-center" [routerLink]="['/', RouteSegment.Platform, RouteSegment.Members]" routerLinkActive="active">
        <a class="d-none" [routerLink]="['/', RouteSegment.Platform, RouteSegment.Member]"></a>
        <svg-icon key="members" fontSize="24px"></svg-icon>
        <span>members</span>
      </a>
      <a class="link d-flex align-items-center" [routerLink]="['/', 'platform', 'events']" routerLinkActive="active">
        <svg-icon key="event" fontSize="24px"></svg-icon>
        <span>events</span>
      </a>
      <a class="link d-flex align-items-center" [routerLink]="['/', 'platform', 'profile']" routerLinkActive="active">
        <svg-icon key="member" fontSize="24px"></svg-icon>
        <span>profile</span>
      </a>
    </div>
    <button class="sign-out color-secondary normal" (click)="logout.next()">
      <svg-icon key="member" fontSize="40px"></svg-icon>
      <span>sign out</span>
    </button>
  </div>
</nav>
