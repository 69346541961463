import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Auth } from '@shared/interfaces/auth.interface';
import { ForgotPassword } from '@shared/interfaces/forgot-password.interface';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { ApiError } from 'src/domain/api-error';
import * as AuthActions from './auth.actions';

export const featureKeyAuth = 'auth';

export interface FeatureStateAuth {
  authResult: Loadable<Auth, ApiError>;
  forgotPasswordResult: Loadable<ForgotPassword, ApiError>;
  validateEmailResult: Loadable<unknown, ApiError>;
  setPasswordResult: Loadable<unknown, ApiError>;
}

export interface AppState {
  [featureKeyAuth]: FeatureStateAuth;
}

export const initialStateAuth: FeatureStateAuth = {
  authResult: {
    isLoading: false,
    data: {
      accessToken: '',
      isLoggedIn: false,
      loginRedirectUrl: undefined,
    },
  },
  forgotPasswordResult: { isLoading: false },
  validateEmailResult: { isLoading: false },
  setPasswordResult: { isLoading: false },
};

export const reducerAuth = createReducer(
  initialStateAuth,
  on(
    AuthActions.login,
    (state): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.startLoad(state.authResult),
    })
  ),
  on(
    AuthActions.loginSuccess,
    (state, { auth: { isLoggedIn, accessToken } }): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.loadSuccess({
        ...state.authResult,
        isLoggedIn,
        accessToken,
      }),
    })
  ),
  on(
    AuthActions.loginError,
    (state, { error }): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.loadError({ ...state.authResult }, error),
    })
  ),
  on(
    AuthActions.resetLoginError,
    (state): FeatureStateAuth => ({
      ...state,
      authResult: { data: state.authResult.data, isLoading: false },
    })
  ),
  on(
    AuthActions.logout,
    (state): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.startLoad(state.authResult),
    })
  ),
  on(
    AuthActions.logoutSuccess,
    (state): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.loadSuccess({
        ...state.authResult,
        isLoggedIn: false,
        accessToken: undefined,
        loginRedirectUrl: undefined,
      }),
    })
  ),
  on(
    AuthActions.logoutError,
    (state, { error }): FeatureStateAuth => ({
      ...state,
      authResult: LoadableStateReducerHelper.loadError({ ...state.authResult }, error),
    })
  ),
  on(
    AuthActions.forgotPassword,
    (state): FeatureStateAuth => ({
      ...state,
      forgotPasswordResult: LoadableStateReducerHelper.startLoad(state.forgotPasswordResult),
    })
  ),
  on(
    AuthActions.forgotPasswordSuccess,
    (state, props): FeatureStateAuth => ({
      ...state,
      forgotPasswordResult: LoadableStateReducerHelper.loadSuccess({ ...props }),
    })
  ),
  on(
    AuthActions.forgotPasswordError,
    (state, { error }): FeatureStateAuth => ({
      ...state,
      forgotPasswordResult: LoadableStateReducerHelper.loadError({ ...state.forgotPasswordResult }, error),
    })
  ),
  on(
    AuthActions.validateEmail,
    (state): FeatureStateAuth => ({
      ...state,
      validateEmailResult: LoadableStateReducerHelper.startLoad(state.validateEmailResult),
    })
  ),
  on(
    AuthActions.validateEmailSuccess,
    (state): FeatureStateAuth => ({
      ...state,
      validateEmailResult: LoadableStateReducerHelper.loadSuccess({ ...state.validateEmailResult }),
    })
  ),
  on(
    AuthActions.validateEmailError,
    (state, { error }): FeatureStateAuth => ({
      ...state,
      validateEmailResult: LoadableStateReducerHelper.loadError({ ...state.validateEmailResult }, error),
    })
  ),
  on(
    AuthActions.setPassword,
    (state): FeatureStateAuth => ({
      ...state,
      setPasswordResult: LoadableStateReducerHelper.startLoad(state.setPasswordResult),
    })
  ),
  on(
    AuthActions.setPasswordSuccess,
    (state): FeatureStateAuth => ({
      ...state,
      setPasswordResult: LoadableStateReducerHelper.loadSuccess({ ...state.setPasswordResult }),
    })
  ),
  on(
    AuthActions.setPasswordError,
    (state, { error }): FeatureStateAuth => ({
      ...state,
      setPasswordResult: LoadableStateReducerHelper.loadError({ ...state.setPasswordResult }, error),
    })
  ),
  on(
    AuthActions.resetResetPassword,
    (state): FeatureStateAuth => ({
      ...state,
      setPasswordResult: { isLoading: false },
    })
  )
);
