import { createAction, props } from '@ngrx/store';
import { Auth } from '@shared/interfaces/auth.interface';
import { ApiError } from 'src/domain/api-error';
import { LoginInfo } from '../interfaces/login-info.interface';
import { SetPasswordInfo } from '../interfaces/set-password-info.interface';

const feature = '[Auth]';

export const updateLoginRedirectUrl = createAction(
  `${feature} update login redirect url`,
  props<{
    loginRedirectUrl: string;
  }>()
);

export const login = createAction(
  `${feature} login`,
  props<{
    loginInfo: LoginInfo;
  }>()
);

export const loginSuccess = createAction(`${feature} login success`, props<{ auth: Auth }>());

export const loginError = createAction(`${feature} login error`, props<{ error: ApiError }>());

export const logout = createAction(`${feature} logout`);

export const logoutSuccess = createAction(`${feature} logout success`);

export const logoutError = createAction(`${feature} logout error`, props<{ error: ApiError }>());

export const forgotPassword = createAction(`${feature} forgot password`, props<{ email: string; isResend: boolean }>());

export const forgotPasswordSuccess = createAction(`${feature} forgot password success`, props<{ email: string; isResend: boolean }>());

export const forgotPasswordError = createAction(`${feature} forgot password error`, props<{ error: ApiError }>());

export const validateEmail = createAction(`${feature} validate email`, props<{ validateEmailToken: string }>());

export const validateEmailSuccess = createAction(`${feature} validate email success`);

export const validateEmailError = createAction(`${feature} validate email error`, props<{ error: ApiError }>());

export const setPassword = createAction(
  `${feature} set password`,
  props<{
    setPasswordInfo: SetPasswordInfo;
  }>()
);

export const setPasswordSuccess = createAction(`${feature} set password success`);

export const setPasswordError = createAction(`${feature} set password error`, props<{ error: ApiError }>());

export const resetLoginError = createAction(`${feature} reset login error`);

export const resetResetPassword = createAction(`${feature} reset reset password`);
