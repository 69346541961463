import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLayoutComponent {
  public isDarkFooter = false;

  constructor(public readonly router: Router) {
    // TODO: also check on this
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: (event) => {
        const nav = event as NavigationEnd;
        this.isDarkFooter = nav.url === '/';
      },
    });
  }
}
