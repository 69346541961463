export const appEventIcon = {
    data: `<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5222 4H3.47782C2.12628 4 1 5.30435 1 6.86957V17.1304C1 18.6957 2.12628 20 3.47782 20C10.2258 20 14.0108 20 20.5222 20C21.8737 20 23 18.6957 23 17.1304V6.86957C23 5.30435 21.9488 4 20.5222 4Z" fill="currentColor"/>
        <rect x="6" y="2" width="2" height="4" rx="1" fill="currentColor"/>
        <rect x="22" y="6" width="2" height="20" rx="1" transform="rotate(90 22 6)" fill="#EEF0F7"/>
        <rect x="16" y="2" width="2" height="4" rx="1" fill="currentColor"/>
        <path d="M11.561 10.8049C11.7505 10.4574 12.2495 10.4574 12.439 10.8049L13.1325 12.0765C13.2044 12.2084 13.3318 12.3009 13.4794 12.3286L14.9031 12.5952C15.2921 12.668 15.4463 13.1425 15.1744 13.4301L14.1793 14.4826C14.0761 14.5918 14.0275 14.7415 14.0468 14.8905L14.2332 16.3269C14.2841 16.7194 13.8805 17.0126 13.5229 16.8429L12.2144 16.2218C12.0787 16.1574 11.9213 16.1574 11.7856 16.2218L10.4771 16.8429C10.1195 17.0126 9.7159 16.7194 9.76683 16.3269L9.95322 14.8905C9.97255 14.7415 9.92389 14.5918 9.8207 14.4826L8.82563 13.4301C8.55372 13.1425 8.7079 12.668 9.09692 12.5952L10.5206 12.3286C10.6682 12.3009 10.7956 12.2084 10.8675 12.0765L11.561 10.8049Z" fill="#EEF0F7"/>
      </svg>`,
    name: 'event'
};
