<div class="d-none d-lg-flex flex-column desktop-layout">
  <section class="hero">
    <div class="image"></div>
    <div class="text color-secondary">
      <h1 class="mb-2">{{ 'HIP_Title' | translate }}</h1>
      <p class="mb-7">
        {{ 'HIP_Subtitle' | translate }}
      </p>
      <button class="primary large" [appPushTagOnClick]="{ event: TagEvent.NewsletterFormOpened }" (click)="navigateToWebinar()">
        {{ 'Webinar_Button_Text' | translate }}
      </button>
    </div>
  </section>
  <section>
    <app-news-feed-screen [limitedView]="true"></app-news-feed-screen>
  </section>
  <section class="first-block d-flex align-items-center gap-10 justify-content-center">
    <div class="right color-secondary">
      <div class="illustration-wrapper">
        <app-illustration [type]="illustrationTypes.MessageAlt"></app-illustration>
      </div>
      <div class="text">
        <h2 class="mb-2">
          {{ 'Join_Discussions_Title' | translate }}
        </h2>
        <p class="mb-5">
          {{ 'Join_Discussions_Text' | translate }}
        </p>
      </div>
    </div>
    <div class="card-demo">
      <app-discussion-detail-card [vm]="cardDemo" [disableInteraction]="true"></app-discussion-detail-card>
    </div>
  </section>
  <section class="second-block color-secondary">
    <div class="illustration-wrapper top">
      <app-illustration [type]="illustrationTypes.WelcomeAlt"></app-illustration>
    </div>
    <div class="text-wrapper top">
      <h2 class="mb-2">
        {{ 'Collaborate_Title' | translate }}
      </h2>
      <p>
        {{ 'Collaboration_Text' | translate }}
      </p>
    </div>
    <div class="illustration-wrapper bottom">
      <app-illustration [type]="illustrationTypes.DiscussionAlt"></app-illustration>
    </div>
    <div class="text-wrapper bottom">
      <h2 class="mb-2">
        {{ 'Learn_Title' | translate }}
      </h2>
      <p>
        {{ 'Learn_Text' | translate }}
      </p>
    </div>
  </section>
  <section class="third-block px-5">
    <div class="cta d-flex align-items-center justify-content-between gap-4">
      <p class="text color-white100 title-h2-bold">
        {{ 'Experts_Title' | translate }}
        <br />
        <br />
        {{ 'Experts_Text' | translate }}
      </p>
      <div class="d-flex gap-8 align-items-center">
        <div class="separator vertical"></div>
        <button class="white large" (click)="navigateToWebinar()">{{ 'Webinar_Button_Text' | translate }}</button>
      </div>
    </div>
  </section>
  <section class="fourth-block my-8 d-flex justify-content-center gap-6 align-items-center color-secondary">
    <div class="text ps-8">
      <h2 class="mb-2">{{ 'What_Is_HIP_Title' | translate }}</h2>
      <p>
        {{ 'What_Is_HIP_Text' | translate }}
      </p>
    </div>
    <div class="illustration-wrapper">
      <app-illustration [type]="illustrationTypes.Question"></app-illustration>
    </div>
  </section>
  <section class="fifth-block">
    <h2 class="mb-7 color-secondary w-100 text-center">{{ 'Member_Title' | translate }}</h2>
    <div class="steps-bar d-flex align-items-center">
      <div class="line flex-grow-1"></div>
      <svg width="80" height="86" viewBox="0 0 80 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M46.129 68.6734C53.9556 79.154 65.9122 86 80 86L80 0C65.9122 -1.24122e-06 53.9556 6.86699 46.129 17.3476C35.4653 31.5504 17.7076 41 0 41V45C17.8054 45 35.5632 54.4706 46.129 68.6734Z"
          fill="white"
        />
      </svg>
      <div class="steps-wrapper d-flex align-items-center justify-content-between">
        <div class="step-bubble text-large-bold color-secondary">{{ 'Member_Step_Label' | translate: { step: 1 } }}</div>
        <div class="step-bubble text-large-bold color-secondary">{{ 'Member_Step_Label' | translate: { step: 2 } }}</div>
        <div class="step-bubble text-large-bold color-secondary">{{ 'Member_Step_Label' | translate: { step: 3 } }}</div>
      </div>
      <svg width="80" height="86" viewBox="0 0 80 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.871 68.6734C26.0444 79.154 14.0878 86 7.46008e-06 86L0 0C14.0878 -1.24122e-06 26.0444 6.86699 33.871 17.3476C44.5347 31.5504 62.2924 41 80 41V45C62.1946 45 44.4368 54.4706 33.871 68.6734Z"
          fill="white"
        />
      </svg>
      <div class="line flex-grow-1"></div>
    </div>
    <div class="step-texts-wrapper d-flex align-items-start justify-content-between color-secondary">
      <p class="step-text title-h3-bold text-center">{{ 'Member_Step_Signup' | translate }}</p>
      <p class="step-text title-h3-bold text-center">{{ 'Member_Step_Topics' | translate }}</p>
      <p class="step-text title-h3-bold text-center">{{ 'Member_Step_Participate' | translate }}</p>
    </div>
    <button class="primary large mb-6 mx-auto" (click)="navigateToWebinar()">{{ 'Webinar_Button_Text' | translate }}</button>
    <p class="color-secondary40 w-100 text-center">
      * Our community values real expertise. Your registration will be validated by our community admin.
    </p>
  </section>
</div>

<div class="d-flex flex-column d-lg-none mobile-layout">
  <section class="hero">
    <div class="image"></div>
    <div class="card">
      <div class="card-content">
        <div class="card-text-container">
          <h1 class="card-title">{{ 'HIP_Title' | translate }}</h1>
          <p class="card-text">{{ 'HIP_Subtitle' | translate }}</p>
        </div>
        <button class="primary large card-button" (click)="navigateToWebinar()">{{ 'Webinar_Button_Text' | translate }}</button>
      </div>
    </div>
    <section class="pb-4 pt-6">
      <app-news-feed-screen [limitedView]="true"></app-news-feed-screen>
    </section>
  </section>
  <section class="join-discussions">
    <div class="illustration-wrapper">
      <app-illustration [type]="illustrationTypes.MessageAlt"></app-illustration>
    </div>
    <div class="text-container">
      <h2 class="title">
        {{ 'Join_Discussions_Title' | translate }}
      </h2>
      <p class="sub-text">
        {{ 'Join_Discussions_Text' | translate }}
      </p>
    </div>
    <div class="discussion-wrapper">
      <div class="demo-card card-shadow">
        <div class="demo-card-header d-flex flex-column">
          <h2>Reusability of healthcare data</h2>
          <div class="d-flex justify-content-between">
            <p class="demo-card-description">What are the benefits and downsides of using large clinical databases?</p>
            <div class="avatar-list d-flex">
              <img src="/assets/images/stock-user-1.png" />
              <div class="avatar-placeholder">+12</div>
            </div>
          </div>

          <div class="tags">
            <span>#COVID</span>
            <span>#ONCOLOGY</span>
            <span>#HOT</span>
          </div>
        </div>

        <div class="demo-card-divider"></div>

        <div class="demo-thread d-flex flex-column">
          <div class="demo-thread-header d-flex align-self-stretch justify-content-between">
            <div class="d-flex align-items-center">
              <img class="demo-thread-avatar" src="/assets/images/stock-user-2.png" />
              <span class="demo-thread-author">Peter the Researcher</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="demo-thread-ago">1h</span>
              <div class="demo-thread-header-divider"></div>
              <svg-icon fontSize="24px" key="like" color="#02065B"></svg-icon>
              <svg-icon fontSize="24px" key="flag" color="#02065B"></svg-icon>
            </div>
          </div>

          <p>
            Hey! The European Institute for Innovation through Health Data (i~HD) has set up a comprehensive and coherent health data
            governance ecosystem... What do you think about it?
          </p>

          <div class="thread-reply">reply</div>
        </div>

        <div class="demo-thread indented">
          <div class="demo-thread-header d-flex align-self-stretch justify-content-between">
            <div class="d-flex align-items-center">
              <img class="demo-thread-avatar" src="/assets/images/stock-user-3.png" />
              <span class="demo-thread-author">Jane Doe</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="demo-thread-ago">1h</span>
              <div class="demo-thread-header-divider"></div>
              <svg-icon fontSize="24px" key="like" color="#02065B"></svg-icon>
              <svg-icon fontSize="24px" key="flag" color="#02065B"></svg-icon>
            </div>
          </div>

          <p>
            I find the first pillar really important! Good EHR data quality is imperative for valid and reliable clinical decision-making
            and inference...
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="collaborate">
    <div class="illustration-wrapper">
      <app-illustration [type]="illustrationTypes.WelcomeAlt"></app-illustration>
    </div>
    <div class="text-wrapper">
      <h2 class="title">
        {{ 'Collaborate_Title' | translate }}
      </h2>
      <p class="text">
        {{ 'Collaboration_Text' | translate }}
      </p>
    </div>
    <div class="illustration-wrapper">
      <app-illustration [type]="illustrationTypes.DiscussionAlt"></app-illustration>
    </div>
    <div class="text-wrapper">
      <h2 class="title">
        {{ 'Learn_Title' | translate }}
      </h2>
      <p class="text">
        {{ 'Learn_Text' | translate }}
      </p>
    </div>
  </section>
  <section class="real-expertise">
    <div class="cta-card">
      <p class="card-title">
        {{ 'Experts_Title' | translate }}
        <br />
        <br />
        {{ 'Experts_Text' | translate }}
      </p>
      <div class="separator-wrapper">
        <div class="card-separator-vertical"></div>
      </div>
      <div class="card-button">
        <button class="white large" (click)="navigateToWebinar()">{{ 'Webinar_Button_Text' | translate }}</button>
      </div>
    </div>
  </section>
  <section class="what-is-hip">
    <div class="illustration-wrapper">
      <app-illustration [type]="illustrationTypes.Question"></app-illustration>
    </div>
    <div class="text-wrapper">
      <h2 class="title">{{ 'What_Is_HIP_Title' | translate }}</h2>
      <p class="text">
        {{ 'What_Is_HIP_Text' | translate }}
      </p>
    </div>

    <div class="cta-backdrop">
      <svg width="156" height="218" viewBox="0 0 156 218" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M285.662 6.01318C270.501 0.637356 255.048 8.27669 250.092 21.5748C248.925 24.9701 245.427 27.2336 241.636 27.2336C216.562 27.7994 131.425 29.4971 92.3559 30.0629C81.8596 30.3459 72.8212 24.6871 67.2815 16.199C60.5755 6.01318 48.6214 -0.777335 34.3348 0.0714797C20.0482 0.637356 6.92789 9.97432 2.26288 23.2724C-5.90089 45.3416 8.96883 67.6937 31.7108 70.8061C35.2095 71.3719 38.4167 71.089 41.6239 70.8061C48.6214 69.9573 55.6189 73.3525 59.4093 79.0113L60.8671 81.2748C65.5321 88.6312 64.6574 97.4023 58.8261 103.344C46.872 115.793 39.583 132.487 39.8745 151.444C40.7492 181.718 62.908 208.597 93.2306 215.953C134.633 225.856 174.577 198.694 180.116 158.8C181.574 148.897 180.7 139.277 177.784 130.223C174.285 118.623 178.075 106.173 187.989 99.0999L245.718 57.508C253.882 51.5663 258.255 51.8492 262.046 53.8298C264.961 55.5274 268.46 56.6592 271.959 57.225C289.161 59.4885 304.614 45.9075 302.864 28.0824C301.99 18.1795 295.284 9.40845 285.662 6.01318ZM66.6983 74.4843C62.6164 68.2596 63.1996 60.6203 67.2815 54.6786C73.4043 45.3416 83.3174 38.834 94.6884 38.834L244.552 36.0047C246.884 36.0047 249.217 37.4193 250.092 39.6829C250.966 41.9464 250.092 44.4928 248.051 45.9075L182.74 92.3094C172.827 99.3828 159.415 99.6658 149.211 92.8753C140.464 87.2165 130.551 83.2554 119.471 81.8407C108.975 80.426 99.0618 81.5577 89.4403 84.3871C81.2765 86.9336 72.5296 83.8212 68.1561 76.7478L66.6983 74.4843Z"
          fill="#F8F8FB"
        />
      </svg>
    </div>
  </section>
  <section class="how-to-join">
    <h2 class="title">{{ 'Member_Title' | translate }}</h2>

    <div class="flex-grow-1 d-flex flex-column" *ngFor="let step of signupSteps; let i = index">
      <div class="d-flex align-items-center align-self-stretch flex-grow-1">
        <div class="line flex-grow-1"></div>
        <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.5968 12.8942C40.4667 5.09469 49.4342 0 60 0V64C49.4342 64 40.4667 58.8897 34.5968 51.0901C26.599 40.5207 13.2807 34 0 34V30C13.3541 30 26.6724 23.4637 34.5968 12.8942Z"
            fill="white"
          />
        </svg>

        <div class="step-label-wrapper">
          <div class="step-label">{{ 'Member_Step_Label' | translate: { step: i + 1 } }}</div>
        </div>
        <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.4032 51.1058C19.5333 58.9053 10.5659 64 5.59506e-06 64L0 0C10.5658 -9.23696e-07 19.5333 5.11032 25.4032 12.9099C33.401 23.4793 46.7193 30 60 30V34C46.6459 34 33.3276 40.5363 25.4032 51.1058Z"
            fill="white"
          />
        </svg>

        <div class="line flex-grow-1"></div>
      </div>
      <p class="step-text">{{ step | translate }}</p>
    </div>

    <button class="primary large mb-6 mx-auto" (click)="navigateToWebinar()">{{ 'Webinar_Button_Text' | translate }}</button>
    <p class="color-secondary40 w-100 text-center asterisk">
      * Our community values real expertise. Your registration will be validated by our community admin.
    </p>
  </section>
</div>
