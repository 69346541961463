<ng-container *ngIf="vm$ | async as vm"
  ><div class="container">
    <h1>{{ 'NewsFeed_Title' | translate }}</h1>
    <div class="items d-flex flex-column align-items-center">
      <app-news-feed-item
        *ngFor="let item of vm.newsFeed; newsFeedItems"
        waIntersectionObserver
        appMeasureNewsfeed
        appMeasureNewsfeedItemVisibility
        [article]="item.description"
        [vm]="item"
        (measureNewsFeed)="measureNewsFeed(item)"
        (itemVisibilityMeasured)="measureItemVisibility(item)"
      ></app-news-feed-item>
    </div>
  </div>
  <a *ngIf="limitedView" class="see-posts" [routerLink]="['news-feed']">
    <span class="text-nowrap">{{ 'SeeAllPosts_Button_Text' | translate }}</span>
    <svg-icon key="arrow-right" fontSize="24px"></svg-icon>
  </a>
</ng-container>
