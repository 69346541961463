<h1 class="title-h1 color-secondary mb-5">Sort {{ isEventType() ? 'events' : 'discussions' }} by</h1>
<div class="sort my-3">
  <app-radio *ngIf="!isEventType()" name="sortType" [formControl]="sortTypeControl" [value]="sortType.Activity">Latest activity</app-radio>
  <app-radio name="sortType" [formControl]="sortTypeControl" [value]="sortType.Alphabet">A to Z</app-radio>
  <app-radio *ngIf="!isEventType()" name="sortType" [formControl]="sortTypeControl" [value]="sortType.Creation">Date created</app-radio>
  <app-radio *ngIf="isEventType()" name="sortType" [formControl]="sortTypeControl" [value]="sortType.StartDateTime">Start date</app-radio>
</div>
<div class="separator"></div>
<div class="order my-3">
  <app-radio name="order" [formControl]="orderControl" [value]="order.Ascending">Ascending</app-radio>
  <app-radio name="order" [formControl]="orderControl" [value]="order.Descending">Descending</app-radio>
</div>
<div class="actions d-flex mt-5 gap-2">
  <button class="primary large flex-grow-1" (click)="onSortClose(true)">sort {{ isEventType() ? 'events' : 'discussions' }}</button>
  <button class="secondary large" (click)="onSortClose(false)">cancel</button>
</div>
