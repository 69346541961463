export enum RouteSegment {
  Root = '',
  Platform = 'platform',
  Onboarding = 'onboarding',
  ProfileSetup = 'profile-setup',
  CustomizeInterests = 'customize-interests',
  PrivacyPolicy = 'privacy-policy',
  TermsAndConditions = 'general-terms-and-conditions',
  Login = 'login',
  Signup = 'signup',
  NotFound = '404',
  Error = '500',
  Profile = 'profile',
  Members = 'members',
  Collaborations = 'collaborations',
  Discussions = 'discussions',
  Events = 'events',
  Password = 'password',
  ForgottenPassword = 'forgotten-password',
  ForgottenPasswordSuccess = 'forgotten-password/success',
  Terms = 'terms',
  SignupEmailValidation = 'email-validation',
  ValidateEmail = 'validate-email',
  ResetPassword = 'reset-password',
  Success = 'success',
  DataGeneration = 'data-generation',
  Member = 'member',
  InviteMember = 'invite-member',
  RecentEntries = 'recent-entries',
  AnyOther = '**',
  NewsFeed = 'news-feed',
  Articles = 'articles',
}
