import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG_TOKEN } from '@core/config.token';
import { Config } from '@core/types/config.interface';
import { Configuration } from '@hip/frontend-api';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { login, logout } from 'src/app/stores/auth/auth.actions';

const LOCAL_STORAGE_BASE_KEY = 'pfizer_app';
const ACCESS_TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly accessTokenLocalStorageKey: string;

  constructor(
    private readonly injector: Injector,
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly router: Router,
    private readonly store: Store
  ) {
    this.accessTokenLocalStorageKey = `${LOCAL_STORAGE_BASE_KEY}_${ACCESS_TOKEN_KEY}`;
  }

  public login(loginInfo: { email: string; password: string }): void {
    this.store.dispatch(
      login({
        loginInfo,
      })
    );

    // TODO: adding here login redirect url handling
    // if someone gets here from a page which requires auth
    // then the app should redirect back to that page after login
    /*return this.store.select(selectAuthData).pipe(
      tap((e) => console.log(e)),
      switchMap((authData) => {
        if (authData?.loginRedirectUrl) {
          console.log('redirect', authData?.loginRedirectUrl);

          return of();
        }

        console.log('redirect to platform hello');
        this.router.navigate(['/', RouteSegment.Platform]);

        return of();
      })
    );*/
  }

  public logout(): void {
    this.store.dispatch(logout());

    // TODO: figure out why router.navigate() does not work here
    // this.router.navigate(['/', RouteSegment.Login]);
    window.location.href = RouteSegment.Root;

    this.setAccessToken(null);
  }

  public getAccessToken(): string | undefined {
    return localStorage.getItem(this.accessTokenLocalStorageKey) ?? undefined;
  }

  public setAccessToken(accessToken: string | null): void {
    const apiConfig = this.injector.get(Configuration);

    if (!accessToken) {
      localStorage.removeItem(this.accessTokenLocalStorageKey);
      apiConfig.accessToken = undefined;

      return;
    }

    localStorage.setItem(this.accessTokenLocalStorageKey, accessToken);
    apiConfig.accessToken = accessToken;
  }
}
