import { Inject, Injectable, Injector } from '@angular/core';
import { NO_ANALYTICS_TOKEN } from '@core/tokens/no-analytics.token';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GoogleTag } from '@shared/interfaces/tags/google-tag.type';
import { CONFIG_TOKEN } from '../config.token';
import { Config } from '../types/config.interface';

@Injectable({
  providedIn: 'root',
})
export class TagPusherService {
  private readonly googleTagManagerService?: GoogleTagManagerService;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: Config,
    private readonly injector: Injector,
    @Inject(NO_ANALYTICS_TOKEN) private readonly noAnalytics: boolean
  ) {
    if (this.config.googleAnalyticsId && !this.noAnalytics) {
      this.googleTagManagerService = this.injector.get(GoogleTagManagerService);
      this.googleTagManagerService.addGtmToDom();
    }
  }

  public gtag(...restArgs: unknown[]): void {
    if (!this.googleTagManagerService) {
      return;
    }

    // eslint-disable-next-line prefer-rest-params
    this.googleTagManagerService?.getDataLayer().push(arguments);
  }

  public pushTag(tag: GoogleTag): void {
    if (!this.googleTagManagerService || !tag) {
      return;
    }

    this.googleTagManagerService?.pushTag(tag);
  }
}
