import { DiscussionDetailCardVM } from '@shared/components/discussion-detail-card/discussion-detail-card.vm';

const oneHourAgo = () => {
  const date = new Date();
  date.setTime(Date.now() - 60 * 60 * 1000);
  return date.toString();
};

export const cardDemo: DiscussionDetailCardVM = {
  id: '',
  title: 'Reusability of healthcare data',
  description: 'What are the benefits and barriers to use large clinical databases?',
  discussionRules: [
    {
      id: 'abc-123',
      text: 'random rule for testing',
    },
    {
      id: 'abc-124',
      text: 'and an another one',
    },
  ],
  tags: [
    {
      id: '',
      name: 'covid',
      isSelected: false,
    },
    {
      id: '',
      name: 'oncology',
      isSelected: false,
    },
    {
      id: '',
      name: 'hot',
      isSelected: false,
    },
  ],
  isMember: true,
  lastReply: '2022-05-01',
  memberCount: 15,
  replyCount: 2,
  isCollaboration: false,
  memberImages: [
    '/assets/images/stock-user-1.png',
    '/assets/images/stock-user-2.png',
    '/assets/images/stock-user-3.png',
    '/assets/images/stock-user-1.png',
    '/assets/images/stock-user-2.png',
    '/assets/images/stock-user-3.png',
    '/assets/images/stock-user-1.png',
    '/assets/images/stock-user-2.png',
    '/assets/images/stock-user-3.png',
    '/assets/images/stock-user-1.png',
    '/assets/images/stock-user-2.png',
    '/assets/images/stock-user-3.png',
    '/assets/images/stock-user-1.png',
    '/assets/images/stock-user-2.png',
    '/assets/images/stock-user-3.png',
  ],
  replies: [
    {
      author: {
        id: '123',
        firstName: 'Peter',
        lastName: 'the Researcher',
        image: '/assets/images/stock-user-2.png',
      },
      content: `Hey! The European Institute for Innovation through Health Data (i~HD) has set up a comprehensive
        and coherent health data governance ecosystem... What do you think about it?`,
      id: '',
      isFlagged: false,
      isMasked: false,
      sentAt: oneHourAgo(),
      replies: [
        {
          author: {
            id: '123',
            firstName: 'Anna',
            lastName: 'the Analyst',
            image: '/assets/images/stock-user-1.png',
          },
          isFlagged: false,
          isMasked: false,
          content: `I find the first pillar really important!
            Good EHR data quality is imperative for valid and reliable clinical decision-making and inference...`,
          id: '',
          sentAt: oneHourAgo(),
        },
      ],
    },
  ],
};
