import { animate, state, style, transition, trigger } from '@angular/animations';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ComponentRef, EmbeddedViewRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DIALOG_DATA } from '../../dialog.tokens';
import { DialogData } from '../../interfaces/dialog-data.interface';

@UntilDestroy()
@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('state', [
      state('void, exit', style({ opacity: 0, transform: `translateY(50px)` })),
      transition(':enter', animate('250ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))),
      transition(':leave', animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))),
    ]),
  ],
})
export class DialogContainerComponent extends BasePortalOutlet implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet!: CdkPortalOutlet;

  private animationState = 'visible';

  public constructor(@Inject(DIALOG_DATA) public readonly data: DialogData) {
    super();
  }

  @HostBinding('@state') public get hostAnimation(): string {
    return this.animationState;
  }

  @HostBinding('style.width') public get hostWidth(): string | undefined {
    return this.data.config.width;
  }

  public ngOnInit(): void {
    this.data.dialogRef
      .visible$()
      .pipe(untilDestroyed(this))
      .subscribe((visible) => {
        this.animationState = visible ? 'visible' : 'void';
      });
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  public attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  public onScrollEnd(): void {
    this.data.dialogRef.triggerScrollEnd();
  }
}
