import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CONFIG_TOKEN } from '@core/config.token';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { filter } from 'rxjs';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { VERSION } from './version';
import { Config } from './core/types/config.interface';

declare let gtag: (key: string, measurementId: string, arguments: unknown) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public readonly version?: string = this.config.showVersion ? VERSION : undefined;

  constructor(
    @Inject(CONFIG_TOKEN) public config: Config,
    public router: Router,
    private readonly tagPusherService: TagPusherService
  ) {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        })
      )
      .subscribe((_) => {
        this.tagPusherService.pushTag({
          event: TagEvent.PageView,
        });
      });
  }
}
