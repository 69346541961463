<div class="h-100 d-flex flex-column">
  <div class="member-list background-background50 ps-4 pe-2 py-3 mb-3 flex-grow-1 overflow-auto">
    <div class="inner d-flex flex-column gap-3 pe-2">
      <div *ngFor="let member of vm.members; trackBy: trackById" class="list-elem d-flex align-items-center justify-content-between">
        <div class="text-normal-bold color-secondary">{{ member.firstName }} {{ member.lastName }}</div>
        <div class="action">
          <button *ngIf="action === actionTypes.Invite" class="primary tiny" (click)="onInviteClick(member)" [disabled]="member.isInvited">
            <svg-icon key="add-member" fontSize="16px"></svg-icon>
            <span>invite</span>
          </button>
          <ng-container *ngIf="action === actionTypes.Select">
            <button *ngIf="!member.isSelected; else selectedButtonTemplate" class="primary tiny" (click)="onSelectClick(member)">
              <svg-icon key="check" fontSize="16px"></svg-icon>
              <span>select</span>
            </button>
            <ng-template #selectedButtonTemplate>
              <button class="secondary tiny" (click)="onDeselectClick(member)">
                <svg-icon key="clear" fontSize="16px"></svg-icon>
                <span>deselect</span>
              </button>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="select-buttons d-flex gap-2">
    <ng-container *ngIf="action === actionTypes.Invite">
      <button class="secondary small" (click)="onInviteAllClick()" [disabled]="!hasInvitableMembers(vm.members)">
        <svg-icon key="add-member" fontSize="24px"></svg-icon>
        invite all the members
      </button>
    </ng-container>
    <ng-container *ngIf="action === actionTypes.Select">
      <button class="secondary small" (click)="onSelectAllClick()">
        <svg-icon key="check" fontSize="24px"></svg-icon>
        select all members
      </button>
      <button class="secondary small" (click)="onDeselectAllClick()">
        <svg-icon key="clear" fontSize="24px"></svg-icon>
        clear selection
      </button>
    </ng-container>
  </div>
</div>
