import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorScreenComponent } from '@core/components/error-screen/error-screen.component';
import { GeneralTermsScreenComponent } from '@core/components/general-terms-screen/general-terms-screen.component';
import { LandingScreenComponent } from '@core/components/landing-screen/landing-screen.component';
import { PlatformComponent } from '@core/components/platform/platform.component';
import { PrivacyPolicyScreenComponent } from '@core/components/privacy-policy-screen/privacy-policy-screen.component';
import { PublicLayoutComponent } from '@core/components/public-layout/public-layout.component';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { AppAuthRouteGuard } from './app-auth-route-guard';

const routes: Routes = [
  {
    path: RouteSegment.Root,
    component: PublicLayoutComponent,
    children: [
      {
        path: RouteSegment.Root,
        component: LandingScreenComponent,
      },
      {
        path: RouteSegment.PrivacyPolicy,
        component: PrivacyPolicyScreenComponent,
      },
      {
        path: RouteSegment.TermsAndConditions,
        component: GeneralTermsScreenComponent,
      },
      {
        path: RouteSegment.NewsFeed,
        loadChildren: () => import('./news-feed/news-feed.module').then((m) => m.NewsFeedModule),
      },
      {
        path: RouteSegment.Articles,
        loadChildren: () => import('./articles/articles.module').then((m) => m.ArticlesModule),
      },
      {
        path: RouteSegment.NotFound,
        component: ErrorScreenComponent,
        data: { isPublic: true, title: 'Page not found', text: '' }, // TODO: Get specifications
      },
    ],
  },
  {
    path: RouteSegment.Signup,
    component: PlatformComponent,
    children: [
      {
        path: RouteSegment.Root,
        loadChildren: () =>
          import('./signup/components/signup-personal-info-screen/signup-personal-info-screen.module').then(
            (m) => m.SignupPersonalInfoScreenModule
          ),
      },
    ],
  },
  {
    path: RouteSegment.Login,
    component: PlatformComponent,
    children: [
      {
        path: RouteSegment.Root,
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  {
    path: RouteSegment.ValidateEmail,
    component: PlatformComponent,
    children: [
      {
        path: RouteSegment.Root,
        loadChildren: () => import('./validate-email/validate-email.module').then((m) => m.ValidateEmailModule),
      },
    ],
  },
  {
    path: RouteSegment.ResetPassword,
    component: PlatformComponent,
    children: [
      {
        path: RouteSegment.Root,
        loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
    ],
  },
  {
    path: RouteSegment.Platform,
    component: PlatformComponent,
    children: [
      {
        path: RouteSegment.Root,
        loadChildren: () => import('./feed/feed.module').then((m) => m.FeedModule),
      },
      {
        path: RouteSegment.Discussions,
        loadChildren: () => import('./discussions/discussions.module').then((m) => m.DiscussionsModule),
      },
      {
        path: RouteSegment.Collaborations,
        loadChildren: () => import('./collaborations/collaborations.module').then((m) => m.CollaborationsModule),
      },
      {
        path: RouteSegment.Events,
        loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
      },
      {
        path: RouteSegment.Profile,
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      { path: RouteSegment.Members, loadChildren: () => import('./members/members.module').then((m) => m.MembersModule) },
      {
        path: RouteSegment.Member,
        loadChildren: () => import('./member/member.module').then((m) => m.MemberModule),
      },
      {
        path: RouteSegment.Onboarding,
        loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: RouteSegment.NotFound,
        component: ErrorScreenComponent,
        data: { isPublic: false, title: 'Page not found', text: 'TODO: Get specifications' },
      },
      {
        path: RouteSegment.Error,
        component: ErrorScreenComponent,
        data: {
          isPublic: false,
          title: 'Oh no! Something went wrong :(',
          text: 'Sorry, something went wrong on our end. We are already working on the issue. Thank you for your patience!',
        },
      },
      {
        path: RouteSegment.AnyOther,
        redirectTo: RouteSegment.NotFound,
      },
    ],
    canActivate: [AppAuthRouteGuard],
  },
  // comment out for data generation component
  /*{
    path: RouteSegment.DataGeneration,
    component: DataGeneratorComponent,
  },*/
  {
    path: RouteSegment.AnyOther,
    redirectTo: RouteSegment.NotFound,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
