import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { CollaborationButtonAction } from '@shared/enums/collaboration-button-action.enum';
import { ProfileRecentEntryCardVM } from '@shared/components/profile-recent-entry-card/profile-recent-entry-card.vm';
@Component({
  selector: 'app-profile-recent-entry-card',
  templateUrl: './profile-recent-entry-card.component.html',
  styleUrls: ['./profile-recent-entry-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileRecentEntryCardComponent {
  @Input() public vm!: ProfileRecentEntryCardVM;
  @Output() public buttonClick = new EventEmitter<{ action?: CollaborationButtonAction; collaborationId?: string }>();

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public onButtonClick(clickData: { action?: CollaborationButtonAction; collaborationId?: string }): void {
    const { action, collaborationId } = clickData;

    this.buttonClick.next({ action, collaborationId });
  }
}
