import { Component, ChangeDetectionStrategy, Inject, TrackByFunction } from '@angular/core';
import { DIALOG_DATA } from '@shared/modules/dialog/dialog.tokens';
import { DialogData } from '@shared/modules/dialog/interfaces/dialog-data.interface';
import { DiscussionRule } from 'src/domain/discussion-rule';

@Component({
  selector: 'app-discussion-rules-dialog',
  templateUrl: './discussion-rules-dialog.component.html',
  styleUrls: ['./discussion-rules-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionRulesDialogComponent {
  public discussionRules: Array<DiscussionRule> = [];

  constructor(@Inject(DIALOG_DATA) private readonly dialog: DialogData<Array<DiscussionRule>, void>) {
    this.discussionRules = this.dialog.payload;
  }

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public onCloseClick(): void {
    this.dialog.dialogRef.close();
  }
}
