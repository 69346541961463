import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN } from '@core/config.token';
import { Config } from '@core/types/config.interface';
import { delay, map, Observable, of } from 'rxjs';
import { ApiMockService } from './services/api-mock.service';

const MOCK_RESPONSE_DELAY_MS = 1000;

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  public constructor(@Inject(CONFIG_TOKEN) public readonly config: Config, private readonly apiMockService: ApiMockService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, sonarjs/cognitive-complexity
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.config.apiMocking) {
      return next.handle(request);
    }

    if (!request.url.startsWith(this.config.backendRootUrl)) {
      return next.handle(request);
    }

    const method = request.method;
    const path = request.url.slice(this.config.backendRootUrl.length);

    if (method === 'POST' && path.match('/auth/check-availability')) {
      return this.getResponse$(this.apiMockService.getCheckAvailability(request));
    }

    if (method === 'PUT' && path.match('/auth/logout')) {
      return this.getResponse$(this.apiMockService.logout(request));
    }

    if (method === 'POST' && path.match('auth/signup')) {
      return this.getResponse$(this.apiMockService.signup(request));
    }

    if (method === 'POST' && path.match('auth/resend-validation-email')) {
      return this.getResponse$(this.apiMockService.resendValidationEmail(request));
    }

    if (method === 'POST' && path.match('auth/login')) {
      return this.getResponse$(this.apiMockService.login(request));
    }

    if (method === 'PUT' && path.match('auth/logout')) {
      return this.getResponse$(this.apiMockService.logout(request));
    }

    if (method === 'POST' && path.match('auth/forgot-password')) {
      return this.getResponse$(this.apiMockService.forgotPassword(request));
    }

    if (method === 'POST' && path.match('auth/validate-email')) {
      return this.getResponse$(this.apiMockService.validateEmail(request));
    }

    if (method === 'POST' && path.match('auth/set-password')) {
      return this.getResponse$(this.apiMockService.setPassword(request));
    }

    if (method === 'POST' && path.match('discussions')) {
      return this.getResponse$(this.apiMockService.createDiscussion(request));
    }

    if (method === 'POST' && path.match('tags/recommend')) {
      return this.getResponse$(this.apiMockService.recommendTags(request));
    }

    if (method === 'GET' && path.startsWith('/discussions') && path.endsWith('/rules')) {
      return this.getResponse$(this.apiMockService.getDiscussionRules(request));
    }

    if (method === 'GET' && path.startsWith('/discussions/') && path.endsWith('invitable-members')) {
      return this.getResponse$(this.apiMockService.getInvitableMembers(request));
    }

    if (method === 'GET' && path.startsWith('/discussions/') && path.endsWith('members')) {
      return this.getResponse$(this.apiMockService.getMembers(request));
    }

    if (method === 'GET' && path.startsWith('/discussions/')) {
      return this.getResponse$(this.apiMockService.getDiscussionById(request));
    }

    if (method === 'GET' && path.startsWith('/discussions')) {
      return this.getResponse$(this.apiMockService.getDiscussions(request));
    }

    if (method === 'PUT' && path.startsWith('/discussions') && path.endsWith('/invite/bulk')) {
      return this.getResponse$(this.apiMockService.inviteMembersBulk(request));
    }

    if (method === 'PUT' && path.startsWith('/discussions') && path.endsWith('/invite/all')) {
      return this.getResponse$(this.apiMockService.inviteMembersAll(request));
    }

    if (method === 'GET' && path.match('/tags')) {
      return this.getResponse$(this.apiMockService.getTags(request));
    }

    if (method === 'GET' && path.match('/profile')) {
      return this.getResponse$(this.apiMockService.getProfile(request));
    }

    if (method === 'PUT' && path.match('/profile/tags')) {
      return this.getResponse$(this.apiMockService.updateProfileTags(request));
    }

    if (method === 'PUT' && path.match('/profile/notification-preferences')) {
      return this.getResponse$(this.apiMockService.updateProfileNotifications(request));
    }

    if (method === 'GET' && path.startsWith('/topics/')) {
      return this.getResponse$(this.apiMockService.getReplies(request));
    }

    if (method === 'POST' && path.startsWith('/topics/')) {
      return this.getResponse$(this.apiMockService.postReply(request));
    }

    if (method === 'POST' && path.match('/attachments')) {
      return this.getResponse$(this.apiMockService.postAttachment(request));
    }

    if (method === 'PUT' && path.startsWith('/replies/')) {
      return this.getResponse$(this.apiMockService.flagReply(request));
    }

    if (method === 'PUT' && path.startsWith('/collaborations/') && path.endsWith('/request-access')) {
      return this.getResponse$(this.apiMockService.requestAccess(request));
    }

    if (method === 'GET' && path.startsWith('/collaborations/')) {
      return this.getResponse$(this.apiMockService.getCollaborationById(request));
    }

    if (method === 'GET' && path.startsWith('/collaborations')) {
      return this.getResponse$(this.apiMockService.getCollaborations(request));
    }

    if (method === 'GET' && path.match('feed')) {
      return this.getResponse$(this.apiMockService.getFeed(request));
    }

    if (method === 'POST' && path.match('/polls')) {
      return this.getResponse$(this.apiMockService.getPoll(request));
    }

    if (method === 'GET' && path.startsWith('/polls/')) {
      return this.getResponse$(this.apiMockService.getPoll(request));
    }

    if (method === 'GET' && path.startsWith('/events/')) {
      return this.getResponse$(this.apiMockService.getEventById(request));
    }

    if (method === 'GET' && path.startsWith('/events')) {
      return this.getResponse$(this.apiMockService.getEvents(request));
    }

    if (method === 'PUT' && path.startsWith('/events/') && path.endsWith('/book')) {
      return this.getResponse$(this.apiMockService.bookEvent(request));
    }

    if (method === 'PUT' && path.startsWith('/events/') && path.endsWith('/unbook')) {
      return this.getResponse$(this.apiMockService.unbookEvent(request));
    }

    // eslint-disable-next-line no-console
    console.info(`Request to ${request.url} was NOT mocked.`);

    return next.handle(request);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getResponse$(response: HttpResponse<any>): Observable<HttpEvent<any>> {
    return of(response).pipe(
      delay(MOCK_RESPONSE_DELAY_MS),
      map((httpResponse) => {
        if (httpResponse.status.toString().startsWith('2')) {
          return httpResponse;
        }

        throw new HttpErrorResponse({ status: httpResponse.status, error: httpResponse.body });
      })
    );
  }
}
