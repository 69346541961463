import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  TemplateRef,
  TrackByFunction,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { DialogService } from '@shared/modules/dialog/dialog.service';
import { debounceTime, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { ProfessionFilter } from 'src/app/members/interfaces/profession-filter';
import { Tag } from 'src/domain/tag';
import { DiscussionFilterDialogComponent, FilterDialogInput } from '../discussion-filter-dialog/discussion-filter-dialog.component';
import { SortDialogInput, DiscussionSortDialogComponent } from '../discussion-sort-dialog/discussion-sort-dialog.component';
import { ListFilterBooking } from './list-filter-booking.enum';
import { ListFilterMembers } from './list-filter-members.enum';
import { ListFilterOwnership } from './list-filter-ownership.enum';
import { ListFilter } from './list-filter.interface';
import { ListHeadType } from './list-head-type.enum';
import { ListOrder } from './list-order.enum';
import { ListSortType } from './list-sort-type.enum';
import { ListSort } from './list-sort.interface';

const INPUT_DEBOUNCE_MS = 300;
const INPUT_MIN_LENGTH = 2;

@Component({
  selector: 'app-list-head',
  templateUrl: './list-head.component.html',
  styleUrls: ['./list-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListHeadComponent implements OnInit, OnDestroy {
  @ViewChild('sortModal') public sortModal!: TemplateRef<unknown>;
  @Input() public listCount = 0;
  @Input() public upcomingListCount = 0;
  @Input() public type = ListHeadType.Discussion;
  @Input() public tags: Tag[] = [];
  @Input() public professions: ProfessionFilter[] = [];
  @Input() public isTrendingVisible = false;
  @Input() public filterText = '';
  @Output() public sortChange = new EventEmitter<ListSort>();
  @Output() public filterChange = new EventEmitter<ListFilter>();
  public readonly ownershipType = ListFilterOwnership;
  public readonly bookingType = ListFilterBooking;
  public readonly memberListType = ListFilterMembers;
  public toggleControl = new FormControl(this.setToggleControlValueByType(this.type));
  public searchControl = new FormControl();
  public isTrendingSelected = false;
  private readonly destroy$ = new Subject<void>();
  private previousSortValue: ListSort = {
    sortBy: this.isEventType() ? ListSortType.StartDateTime : ListSortType.Activity,
    order: ListOrder.Ascending,
  };
  private readonly searchTextPlaceholder = {
    [ListHeadType.Discussion]: 'Type here to search for a discussion',
    [ListHeadType.Collaboration]: '',
    [ListHeadType.Members]: 'Type here to search for a member',
    [ListHeadType.Event]: 'Type here to search for materials',
  };

  public constructor(private readonly dialogService: DialogService, private readonly cdr: ChangeDetectorRef) {}

  public get getTagsClone(): Tag[] {
    return this.tags.map((tag) => ({ ...tag }));
  }

  public get getProfessionsClone(): ProfessionFilter[] {
    return this.professions.map((profession) => ({ ...profession }));
  }

  public trackById: TrackByFunction<Tag> = (_, item) => item.id;

  public ngOnInit(): void {
    this.previousSortValue.sortBy = this.isEventType() ? ListSortType.StartDateTime : ListSortType.Activity;

    this.toggleControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((listFilter) =>
      this.filterChange.next({
        searchTerm: this.searchControl.value,
        tags: this.tags,
        professions: this.professions,
        listFilter,
        isOnlyFollowed: this.isMemberType() ? this.mapListFilterValueToMembersFilter(listFilter as ListFilterMembers) : false,
        isOnlyTrending: false, // TODO: use in frontend
      })
    );
    this.searchControl.valueChanges
      .pipe(
        filter((value) => value?.length > INPUT_MIN_LENGTH || value === ''),
        debounceTime(INPUT_DEBOUNCE_MS),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((value) =>
        this.filterChange.next({
          searchTerm: value,
          tags: this.tags,
          professions: this.professions,
          listFilter: this.toggleControl.value,
          isOnlyFollowed: this.isMemberType()
            ? this.mapListFilterValueToMembersFilter(this.toggleControl.value as ListFilterMembers)
            : false,
          isOnlyTrending: false, // TODO: use in frontend
        })
      );
  }

  // @TODO: create a generic component with destroy subject
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getSearchTextPlaceholder(): string {
    return this.searchTextPlaceholder[this.type];
  }

  public isDiscussionType(): boolean {
    return this.type === ListHeadType.Discussion;
  }

  public isCollaborationType(): boolean {
    return this.type === ListHeadType.Collaboration;
  }

  public isEventType(): boolean {
    return this.type === ListHeadType.Event;
  }

  public isMemberType(): boolean {
    return this.type === ListHeadType.Members;
  }

  public setToggleControlValueByType(type: ListHeadType): ListFilterOwnership | ListFilterBooking | ListFilterMembers {
    switch (type) {
      case ListHeadType.Event:
        return this.bookingType.All;
      case ListHeadType.Members:
        return this.memberListType.All;
      default:
        return this.ownershipType.General;
    }
  }

  public setRightSelect(type: ListHeadType): ListFilterOwnership | ListFilterBooking | ListFilterMembers {
    switch (type) {
      case ListHeadType.Event:
        return this.bookingType.MyBookings;
      case ListHeadType.Members:
        return this.memberListType.Following;
      default:
        return this.ownershipType.Joined;
    }
  }

  public onTrendingClick(): void {
    this.isTrendingSelected = !this.isTrendingSelected;

    this.filterChange.next({
      searchTerm: this.searchControl.value,
      tags: [],
      listFilter: this.toggleControl.value,
      isOnlyTrending: this.isTrendingSelected,
    });
  }

  public onTagSelectionChange(tags: Tag[]): void {
    this.isTrendingSelected = false;

    this.filterChange.next({
      searchTerm: this.searchControl.value,
      tags,
      listFilter: this.toggleControl.value,
      isOnlyTrending: this.isTrendingSelected,
    });
  }

  public onProfessionSelectionChange(professions: ProfessionFilter[]): void {
    this.filterChange.next({
      searchTerm: this.searchControl.value,
      tags: this.tags,
      professions,
      isOnlyFollowed: this.isMemberType() ? this.mapListFilterValueToMembersFilter(this.toggleControl.value as ListFilterMembers) : false,
      listFilter: this.toggleControl.value,
      isOnlyTrending: this.isTrendingSelected,
    });
  }

  public onSortClick(): void {
    this.dialogService
      .open<SortDialogInput, ListSort>(
        DiscussionSortDialogComponent,
        { type: this.type, listSort: this.previousSortValue },
        {
          closeable: false,
          width: '575px',
        }
      )
      .afterClosed$()
      .subscribe({
        next: (closeResult) => {
          if (!closeResult) {
            return;
          }

          this.sortChange.next(closeResult);
          this.previousSortValue = closeResult;
        },
      });
  }

  public onFilterClick(): void {
    this.dialogService
      .open<FilterDialogInput, Omit<ListFilter, 'searchTerm'>>(
        DiscussionFilterDialogComponent,
        {
          filter: {
            tags: this.tags.map((tag) => ({ ...tag })),
            professions: this.professions.map((profession) => ({ ...profession })),
            listFilter: this.toggleControl.value,
            isOnlyTrending: false, // TODO: use in frontend
          },
          isEventType: this.isEventType(),
          isMemberType: this.isMemberType(),
        },
        { closeable: false, width: '730px' }
      )
      .afterClosed$()
      .subscribe({
        next: (closeResult) => {
          if (!closeResult) {
            return;
          }

          this.tags = closeResult.tags;
          this.professions = closeResult.professions ?? [];
          this.cdr.detectChanges();
          this.toggleControl.patchValue(closeResult.listFilter, { emitEvent: false });
          this.filterChange.next({
            searchTerm: this.searchControl.value,
            tags: this.tags,
            professions: this.professions,
            listFilter: this.toggleControl.value,
            isOnlyFollowed: this.isMemberType()
              ? this.mapListFilterValueToMembersFilter(this.toggleControl.value as ListFilterMembers)
              : false,
            isOnlyTrending: false, // TODO: use in frontend
          });
        },
      });
  }

  private mapListFilterValueToMembersFilter(memberFilter: ListFilterMembers): boolean {
    switch (memberFilter) {
      case this.memberListType.All:
        return false;
      case this.memberListType.Following:
        return true;
      default:
        return false;
    }
  }
}
