export const appExperimentingIcon = {
  data: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2222_9343)">
    <rect x="15.5" y="2" width="5" height="5" rx="2.5" transform="rotate(30 15.5 2)" fill="#02065B"/>
    <rect x="14.7676" width="3.53588" height="3.53588" rx="1.76794" transform="rotate(30 14.7676 0)" fill="#02065B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4643 10.5352L10.5361 6.53516L4.53613 16.9275C3.43156 18.8406 4.08706 21.287 6.00023 22.3916C7.9134 23.4961 10.3598 22.8406 11.4643 20.9275L17.4643 10.5352ZM13.7323 9.99927C12.7757 9.44698 11.5525 9.77473 11.0002 10.7313C10.448 11.6879 10.7757 12.9111 11.7323 13.4634C12.6889 14.0157 13.9121 13.6879 14.4643 12.7313C15.0166 11.7747 14.6889 10.5516 13.7323 9.99927ZM7.75024 14.2983C8.16446 13.5809 9.08184 13.3351 9.79928 13.7493C10.5167 14.1635 10.7625 15.0809 10.3483 15.7983C9.93411 16.5157 9.01672 16.7616 8.29928 16.3473C7.58184 15.9331 7.33603 15.0157 7.75024 14.2983ZM9.36626 18.5614C8.88797 18.2853 8.27638 18.4492 8.00023 18.9275C7.72409 19.4058 7.88797 20.0173 8.36626 20.2935C8.84455 20.5696 9.45614 20.4058 9.73229 19.9275C10.0084 19.4492 9.84455 18.8376 9.36626 18.5614Z" fill="#02065B"/>
    </g>
    <defs>
    <clipPath id="clip0_2222_9343">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  name: 'experimenting',
};
