<label
  class="radio d-flex"
  [class.disabled]="control?.disabled$ | async"
  [class.error]="control?.hasError"
  (click)="onRadioSelected($event.target)"
>
  <input type="radio" [name]="name" [value]="value" [checked]="control?.value === value" hidden />
  <span class="radio-input d-flex justify-content-center align-items-center">
    <span class="radio-mark d-flex justify-content-center align-items-center"></span>
  </span>
  <span class="radio-label text-small ms-1 w-100"><ng-content></ng-content></span>
</label>
