<div class="d-flex align-items-center gap-2">
  <ng-container *ngIf="!vm.isMasked; else maskedTitle">
    <a [routerLink]="['/', RouteSegment.Platform, RouteSegment.Member, vm.author.id]">
      <div *ngIf="vm.author.image; else defaultImage" class="author-image" [style.background-image]="'url(' + vm.author.image + ')'"></div>
      <ng-template #defaultImage>
        <svg-icon key="member" class="author-image"></svg-icon>
      </ng-template>
    </a>
    <a [routerLink]="['/', RouteSegment.Platform, RouteSegment.Member, vm.author.id]" class="title-h3 mb-0 color-secondary"
      >{{ vm.author.firstName }} {{ vm.author.lastName }}</a
    >
  </ng-container>
  <ng-template #maskedTitle>
    <h3 class="title-h3-italic mb-0">[ deleted message ]</h3>
  </ng-template>
</div>
