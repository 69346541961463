import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() public isDark = false;
  public year = new Date().getFullYear().toString();

  public onScrollTopClick(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
