<div class="list d-flex flex-wrap gap-2">
  <span
    role="button"
    *ngFor="let tag of tags.slice(0, showCount || tags.length); trackBy: trackById"
    class="d-flex tag normal"
    [class.pressed]="tag.isSelected"
    (click)="onTagClick(tag)"
  >
    {{ hasHashtag ? '#' : '' }}{{ tag.name }}
  </span>
</div>
