import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';

const feature = '[Signup Email Validation]';

export const signupResendValidationEmail = createAction(`${feature} signup resend validation email`, props<{ email: string }>());

export const signupResendValidationEmailSuccess = createAction(`${feature} signup resend validation email success`);

export const signupResendValidationEmailError = createAction(
  `${feature} signup resend validation email error`,
  props<{ error: ApiError }>()
);
