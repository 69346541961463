import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TrackByFunction } from '@angular/core';
import { getClosingInText } from '@shared/helpers/closing-time-text.helper';
import { getPollIdListFromReplies } from '@shared/helpers/get-poll-id-list-from-replies.helper';
import { isPollClosed } from '@shared/helpers/is-poll-closed.helper';
import { FlagToggle } from 'src/app/stores/thread/flag-toggle';
import { QuickPoll } from 'src/domain/quick-poll';
import { DiscussionMessage } from '../discussion-input/discussion-message.interface';
import { IllustrationType } from '../illustration/illustration-type.enum';
import { MessageThreadVm } from './message-thread.vm';

@Component({
  selector: 'app-message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageThreadComponent {
  @Input() public disableInteraction = false;
  @Input() public vm?: MessageThreadVm;
  @Output() public messageReport = new EventEmitter<{ messageId: string; toggle: FlagToggle }>();
  @Output() public messageSend = new EventEmitter<DiscussionMessage>();
  @Output() public replySend = new EventEmitter<{ message: DiscussionMessage; parentId: string }>();
  @Output() public addCustomAnswer = new EventEmitter<{ quickPollId: string; text: string }>();
  @Output() public answerPoll = new EventEmitter<{ quickPollId: string; answerIds: Array<string> }>();
  @Output() public loadQuickPollList = new EventEmitter<{ quickPollIds: Array<string> }>();

  public readonly illustrationTypes = IllustrationType;
  public isPollListVisible = false;

  public trackById: TrackByFunction<{ id: string }> = (_, item) => item.id;

  public getOpenPollsList = (polls: Array<QuickPoll>): Array<QuickPoll> => polls.filter((poll: QuickPoll) => !isPollClosed(poll));

  public onViewPolls(): void {
    if (this.vm?.quickPollIds) {
      this.isPollListVisible = !this.isPollListVisible;

      if (this.isPollListVisible && this.vm?.replies) {
        this.loadQuickPollList.next({ quickPollIds: getPollIdListFromReplies(this.vm?.replies) });
      }
    }
  }

  public onQuickPollLink(selectedQuickPoll: QuickPoll): void {
    const quickPollElement = document.querySelector(`#quick-poll-${selectedQuickPoll.id}`);

    quickPollElement?.scrollIntoView();
  }

  public getClosingInText(quickPoll: QuickPoll): string {
    return quickPoll.closingAt ? getClosingInText(quickPoll.closingAt, new Date()) : '';
  }

  public onMessageSend(message: DiscussionMessage): void {
    this.messageSend.next(message);
  }

  public onReplySend(event: { message: DiscussionMessage; parentId: string }): void {
    const { message, parentId } = event;

    this.replySend.next({ message, parentId });
  }

  public onMessageReport(event: { messageId: string; toggle: FlagToggle }): void {
    const { messageId, toggle } = event;

    this.messageReport.next({ messageId, toggle });
  }

  public onAddCustomAnswer(event: { quickPollId: string; text: string }): void {
    this.addCustomAnswer.next({ quickPollId: event.quickPollId, text: event.text });
  }

  public onAnswerPoll(event: { quickPollId: string; answerIds: Array<string> }): void {
    this.answerPoll.next({ quickPollId: event.quickPollId, answerIds: event.answerIds });
  }
}
