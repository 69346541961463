<div class="answer-selector-wrapper" [class.error]="hasError">
  <form [formGroup]="answersFormGroup">
    <app-input
      *ngFor="let answer of answers; trackBy: trackById"
      type="text"
      [class]="'mb-4 ' + 'answer_' + answer.id"
      [placeholder]="answer.placeholder"
      [formControlName]="answer.id"
    >
    </app-input>
    <app-input
      type="text"
      class="mb-2"
      placeholder="Add another answer +"
      formControlName="addAnotherAnswer"
      (focusin)="addAnotherAnswer()"
    ></app-input>
    <div
      *ngIf="hasError"
      class="hint text-tiny px-3 color-error mb-4"
      [class.color-textDisabled]="control.disabled"
      [innerHTML]="error"
    ></div>
  </form>
</div>
