import { differenceInHours, differenceInMinutes } from 'date-fns';

export const getClosingInText = (closingAt: Date | string, currentDate: Date | string): string => {
  const hourDifference = differenceInHours(new Date(closingAt), new Date(currentDate));
  const minutesDifference = differenceInMinutes(new Date(closingAt), new Date(currentDate));

  if (hourDifference === 0) {
    return `Poll closes in: ${minutesDifference} m`;
  }

  return `Poll closes in: ${hourDifference} h`;
};
