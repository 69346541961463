<app-list-card>
  <ng-container>
    <div class="d-flex flex-column align-items-start mt-1 mb-1 gap-4 justify-content-around w-100">
      <div class="title-wrapper flex-grow-1">
        <a class="color-secondary text-small" [routerLink]="['/', 'platform', 'discussions', vm.discussion.id]">
          Replied in&nbsp;
          <div class="text-small-bold">{{ vm.discussion.title }}</div>
        </a>
      </div>
      <div class="flex-grow-1">"{{ vm.text }}"</div>
    </div>
  </ng-container>
</app-list-card>
