<div class="line background-background50"></div>
<div class="injection-left">
  <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.5968 12.8942C40.4667 5.09469 49.4342 0 60 0V64C49.4342 64 40.4667 58.8897 34.5968 51.0901C26.599 40.5207 13.2807 34 0 34V30C13.3541 30 26.6724 23.4637 34.5968 12.8942Z"
      fill="#EEF0F7"
    />
  </svg>
</div>
<div
  class="info-wrapper d-flex align-items-center color-secondary text-small-italic background-background50"
  [class.right-padding]="!isButtonVisible()"
>
  <div *ngIf="!vm.isCollaboration; else notClickableMembersInfo" class="info">
    <a [routerLink]="['/', 'platform', 'discussions', vm.id, 'members']" class="color-secondary text-small-bold">
      {{ vm.memberCount }} {{ vm.memberCount > 1 ? 'members' : 'member' }}
    </a>
  </div>
  <ng-template #notClickableMembersInfo>
    <div class="info">{{ vm.memberCount }} {{ vm.memberCount > 1 ? 'members' : 'member' }}</div>
  </ng-template>
  <div class="info">{{ vm.replyCount }} {{ vm.replyCount > 1 ? 'replies' : 'reply' }}</div>
  <div class="info">{{ vm.lastReply | dateAgo }}</div>
  <button *ngIf="isButtonVisible()" class="primary large" [disabled]="vm.isAccessRequested" (click)="onButtonClick()">
    <div *ngIf="isRequestedButtonVisible()" class="row">
      <div class="col-12 d-flex justify-content-center">
        <span class="text-title-h3-bold">requested</span>
      </div>
    </div>

    <div *ngIf="isViewButtonVisible()" class="row">
      <div class="col-12 d-flex justify-content-center">
        <span class="text-title-h3-bold">view</span>
      </div>
    </div>

    <div *ngIf="isRequestAccessButtonVisible()" class="row request-access">
      <div class="col-12 d-flex justify-content-center">
        <span class="text-large-bold">request access</span>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <span class="text-tiny">Request to join collab</span>
      </div>
    </div>
  </button>
</div>
