export const appCameraAddIcon = {
  data: `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<title>Camera Add</title>
<path d="M67 6L67 22" stroke="#02065B" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59 14H75" stroke="#02065B" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 40.6471C7 35.1242 11.4772 30.6471 17 30.6471H23.6382C25.9857 30.6471 28.2144 29.6144 29.7321 27.8235V27.8235C31.2499 26.0326 33.4786 25 35.8261 25H40.5H45.1739C47.5214 25 49.7501 26.0326 51.2679 27.8235V27.8235C52.7856 29.6144 55.0143 30.6471 57.3618 30.6471H64C69.5229 30.6471 74 35.1242 74 40.6471V63C74 68.5228 69.5228 73 64 73H17C11.4772 73 7 68.5228 7 63V40.6471Z" fill="#02065B"/>
<circle cx="41" cy="51" r="12" fill="#EEF0F7"/>
</svg>
`,
  name: 'camera-add',
};
